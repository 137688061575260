import React, { Component } from "react";
import firebase from "../../firebase";
import Button from "./Controls/Button";
import ImageButton from "./Controls/ImageButton";
import Input from "./Controls/Input";
import Spinner from "../Common/Spinner";
import Window from "../2/Controls/Window";
import * as constatnts from "../../Constatnts";
import ImportData from "../ImportData";
import ImportLog from "../ImportLog";
import * as actionTypes from "../../actionTypes";
import AddEditProductCategory from "../Catalogue/AddEditProductCategory";
import AddEditProductBrand from "../Catalogue/AddEditProductBrand";
import AddEditModifier from "../Catalogue/AddEditModifier";
import AddEditProductTag from "../Catalogue/AddEditProductTag";
import AddEditProductTax from "../Catalogue/AddEditProductTax";
import AddAttendance from "../Attendance/AddAttendance";
import AddEditModifierGroup from "../Catalogue/AddEditModifierGroup";
import AddTransaction from "../Account/AddTransaction";
import AddEditEmployee from "../Settings/Employee/AddEditEmployee";
import Expense from "../Expenses/Expense";
import AddEditExpenseCategory from "../Settings/AddEditExpenseCategory";
import { isMobileApp } from "../../actions/Util";
import RecordNotFound from "../Common/RecordNotFound";
import AddEditAccount from "../Account/AddEditAccount";
import EmployeeAccess from "../Settings/Employee/EmployeeAccess";
import EmployeeServiceMapping from "../Settings/Employee/EmployeeServiceMapping";
import NewMembership from "../CRM/NewMembership";
import AddCampaign from "../CRM/AddCampaign";
import AddEditPaymentTypes from "../Settings/AddEditPaymentTypes";
import AddEditCustomRoles from "../Settings/AddEditCustomRoles";
import AddEditTransactiontype from "../Settings/AddEditTransactionType";
import SMS from "../Settings/SMS/SMS";
import AddEditOffer from "../Catalogue/AddEditOffer";
import AddEditBOM from "../Catalogue/AddEditBOM";
import "./ListPage.css";
import Menu from "./Menu";
import ActionButton from "./Controls/ActionButton";
import TabStrip from "./Controls/TabStrip";
import Form from "./Controls/Form";
import OmniPageEdit from "../Omni/OmniPageEdit";
import OmniSlider from "../Omni/OmniSlider";
import { getCustomerData } from "../../services/api/CustomerAPI";
import ProductQuickAdd from "../Catalogue/ProductQuickAdd";
import PayBill from "../Pincode/PayBill";
import PincodeIssue from "../Pincode/PincodeIssue";
import NewSalesChanel from "../Settings/NewSalesChannel";
import {
  deleteSalesChannel,
  getSalesChannel,
} from "../../services/api/SalesChannelAPI";
import ImportProduct from "../Import/ImportProduct";

class ListPage extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
      searchText: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      loading: false,
      paginationStatus: "No records to display",
      showFilter: false,
      showOptions: false,
      listPageSettings: null,
      selectedRow: null,
      deleting: false,
      stores: [],
      cashRegisters: [],
      rowButtonWidth: "60",
      showSelectionOption: false,
      inventoryDrafts: [],
      salesTransactionTypes: [],
      inventoryTransactionTypes: [],
      accountList: [],
    };
    this.onHeaderButtonClicked = this.onHeaderButtonClicked.bind(this);
    this.getData = this.getData.bind(this);
    this.onRowButtonClicked = this.onRowButtonClicked.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.onCloseFilter = this.onCloseFilter.bind(this);
    this.onFilterValueChange = this.onFilterValueChange.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderCellData = this.renderCellData.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderColumnValue = this.renderColumnValue.bind(this);
    this.loadListPage = this.loadListPage.bind(this);
    this.renderFilterControl = this.renderFilterControl.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.cancelRecord = this.cancelRecord.bind(this);
    this.renderRowButtons = this.renderRowButtons.bind(this);
    this.renderListSearch = this.renderListSearch.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onOptionsButtonClick = this.onOptionsButtonClick.bind(this);
    this.onCloseImport = this.onCloseImport.bind(this);
    this.onPostPopupSave = this.onPostPopupSave.bind(this);
    this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    this.getElasticData = this.getElasticData.bind(this);
    this.renderPopupWindow = this.renderPopupWindow.bind(this);
    this.checkboxSelected = this.checkboxSelected.bind(this);
    this.renderSelectionOptions = this.renderSelectionOptions.bind(this);
    this.loadCashRegister = this.loadCashRegister.bind(this);
    this.getInventoryParkedTransactions = this.getInventoryParkedTransactions.bind(
      this
    );
    this.onRecordsPerPageCountChange = this.onRecordsPerPageCountChange.bind(
      this
    );
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.renderMobileRowOptions = this.renderMobileRowOptions.bind(this);
    this.renderPopupControls = this.renderPopupControls.bind(this);
    this.getSelectedTabValue = this.getSelectedTabValue.bind(this);
  }

  async componentDidMount() {
    let listPageSettings = this.getListPageSetting();
    this.setState({ listPageSettings: listPageSettings, loading: true });
    // let stores = await this.props.getUserStores(
    //   this.props.user.user.RegistrationID,
    //   ""
    // );
    let stores = this.props.config.UserStores;
    console.log("stores", stores);
    this.loadCashRegister(this.props.user.store.key);
    this.loadAccounts();
    this.setState({ stores: stores });
    let itemsCountPerPage = 20;
    if (typeof Storage !== "undefined") {
      if (localStorage.listPageRecordsPerPage) {
        itemsCountPerPage = Number(localStorage.listPageRecordsPerPage);
      }
    }
    this.loadListPage(itemsCountPerPage, listPageSettings);

    let inventoryTransactionTypes = [];
    let salesTransactionTypes = [];

    this.props.config.TransactionTypes.filter(
      (e) => e.Module === "sales" && !e.IsDeleted
    ).map((e) => {
      if (this.showTransactionType(e.TransactionType)) {
        salesTransactionTypes.push(e);
      }
    });

    this.props.config.TransactionTypes.filter(
      (e) =>
        e.Module === "inventory" &&
        !e.IsDeleted &&
        e.TransactionType.toLowerCase() !== "transfer in"
    ).map((e) => {
      if (this.showTransactionType(e.TransactionType)) {
        inventoryTransactionTypes.push(e);
      }
    });

    this.setState({
      inventoryTransactionTypes: inventoryTransactionTypes,
      salesTransactionTypes: salesTransactionTypes,
    });
  }

  async loadAccounts() {
    let accounts = await this.props.getAccounts(
      this.props.user.user.RegistrationID
    );
    // .then((accounts) => {
    let finalAccounts = [];
    if (accounts && accounts.length > 0) {
      for (let index = 0; index < accounts.length; index++) {
        const account = accounts[index];
        if (!account.IsDeleted) {
          if (account.AllStores) {
            finalAccounts.push(account);
          } else {
            for (let j = 0; j < account.Stores.length; j++) {
              const store = account.Stores[j];
              if (store.value === this.props.user.store.key) {
                finalAccounts.push(account);
              }
            }
          }
        }
      }
    }
    this.setState({ accountList: finalAccounts });
  }

  showTransactionType(transactionType) {
    let showModule = false;
    if (
      this.props.user.user.Role === "Power User" ||
      this.props.user.user.Role === "Store Owner" ||
      this.props.user.user.Role === "Store Manager" ||
      this.props.user.user.Role === "Store Cashier"
    ) {
      showModule = true;
    } else {
      if (
        this.props.user.acl.filter(
          (e) => e.id === transactionType && e.perms.read
        ).length > 0
      ) {
        // console.log('i.TransactionType', i.TransactionType);
        // console.log('perms', this.props.user.acl.filter(e=>e.id === i.TransactionType && e.perms.read));
        showModule = true;
      }
    }
    return showModule;
  }

  async loadCashRegister(storeID) {
    let cashRegisters = await this.props.getCashRegisters(storeID);
    this.setState({ cashRegisters: cashRegisters });
  }

  async getInventoryParkedTransactions() {
    let inventoryDrafts = await this.props.getDraftTransactions("Inventory");
    // console.log('inventoryDrafts', inventoryDrafts);
    this.setState({ inventoryDrafts: inventoryDrafts });
  }

  getListPageSetting() {
    let listPageSettings = {};
    if (this.props.type == "complaints") {
      listPageSettings = {
        Type: "complaints",
        Title: "Customer Complaints",
        API: "apiGetPincodeIssues",
        SearchPlaceHolder:
          "Search by issue id, oder id, cutomer name or mobile no",
        HeaderButtons: [
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "CreatedOn", Type: "datetime" }],
          },
          {
            Name: "Complaint ID",
            Type: "field",
            Columns: [{ Column: "IssueID", Type: "text" }],
          },
          {
            Name: "Order ID",
            Type: "field",
            Columns: [{ Column: "OrderID", Type: "text" }],
          },
          {
            Name: "Customer",
            Type: "field",
            Columns: [
              { Column: "CustomerName", Type: "text" },
              // { Column: "CustomerPhone", Type: "text" },
              // { Column: "CustomerEmail", Type: "text" },
            ],
          },
          {
            Name: "Issue",
            Type: "field",
            Columns: [
              { Column: "IssueShortDescription", Type: "text" },
              { Column: "IssueLongDescription", Type: "text" },
            ],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "IssueStatus", Type: "text" }],
          },
          {
            Name: "Store",
            Type: "field",
            Columns: [{ Column: "StoreName", Type: "text" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              {
                Column: "IssueID",
                Type: "text",
                Label: "Complaint No",
              },
              {
                Column: "OrderID",
                Type: "text",
                Label: "Order No",
              },
              {
                Column: "CreatedOn",
                Type: "date",
              },
              {
                Column: "CustomerName",
                Type: "text",
              },
              {
                Column: "IssueShortDescription",
                Type: "text",
              },
              {
                Column: "IssueStatus",
                Type: "text",
                Badge: "green",
              },
              // {
              //   Column: "StoreName",
              //   Type: "text",
              // },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "popup",
            PopupType: "complaints",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "StoreID.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          {
            Name: "Date",
            Column: "CreatedOn",
            Type: "daterange",
            Value: {
              FromDate: "",
              ToDate: "",
              // FromDate: new Date().toISOString().split("T")[0],
              // ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Complaint ID",
            Column: "IssueID",
            Type: "text",
            GeneralSearch: true,
            // QueryType: "match_phrase",
          },
          {
            Name: "Order ID",
            Column: "OrderID",
            Type: "text",
            GeneralSearch: true,
            // QueryType: "match_phrase",
          },
          {
            Name: "Customer Name",
            Column: "CustomerName",
            Type: "text",
            GeneralSearch: true,
            // QueryType: "match_phrase",
          },
          // {
          //   Name: "Customer Phone",
          //   Column: "CustomerPhone",
          //   Type: "text",
          //   GeneralSearch: true,
          //   // QueryType: "match_phrase",
          // },
          // {
          //   Name: "Customer Email",
          //   Column: "CustomerEmail",
          //   Type: "text",
          //   GeneralSearch: true,
          //   // QueryType: "match_phrase",
          // },
        ],
        Options: [
          // {
          //   Text: "Export",
          //   Type: "export",
          //   ImportType: "onlinepaymenttransactions",
          // },
        ],
        ExportColumns: [
          { Name: "Date", Type: "field", Column: "CreatedOn" },
          { Name: "Order ID", Type: "field", Column: "OrderID" },
          { Name: "Amount", Type: "field", Column: "Price" },
          { Name: "Status", Type: "field", Column: "State" },
          { Name: "Store", Type: "field", Column: "StoreName" },
        ],
        Sort: [{ Column: "CreatedOn", Order: "desc" }],
      };
      this.getInventoryParkedTransactions();
    }
    if (this.props.type == "paybill") {
      listPageSettings = {
        Type: "paybill",
        Title: "Pay Bill",
        API: "getPayBill",
        SearchPlaceHolder: "Search by order id",
        HeaderButtons: [
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "CreatedOn", Type: "datetime" }],
          },
          {
            Name: "Order ID",
            Type: "field",
            Columns: [{ Column: "OrderID", Type: "text" }],
          },
          {
            Name: "Customer",
            Type: "field",
            Columns: [
              { Column: "CustomerName", Type: "text" },
              { Column: "CustomerPhone", Type: "text" },
            ],
          },
          {
            Name: "Bill Amount",
            Type: "field",
            Columns: [{ Column: "Price", Type: "number" }],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "State", Type: "text" }],
          },
          {
            Name: "Store",
            Type: "field",
            Columns: [{ Column: "StoreName", Type: "text" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              {
                Column: "OrderID",
                Type: "text",
                Label: "Order No",
              },
              {
                Column: "Price",
                Type: "number",
                Label: "Amount",
              },
              {
                Column: "State",
                Type: "text",
              },
              {
                Column: "StoreName",
                Type: "text",
              },
              {
                Column: "CreatedOn",
                Type: "datetime",
              },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "popup",
            PopupType: "paybill",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "StoreID.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          {
            Name: "Date",
            Column: "CreatedOn",
            Type: "daterange",
            Value: {
              FromDate: "",
              ToDate: "",
              // FromDate: new Date().toISOString().split("T")[0],
              // ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Order ID",
            Column: "OrderID",
            Type: "text",
            GeneralSearch: true,
            // QueryType: "match_phrase",
          },
        ],
        Options: [
          {
            Text: "Export",
            Type: "export",
            ImportType: "onlinepaymenttransactions",
          },
        ],
        ExportColumns: [
          { Name: "Date", Type: "field", Column: "CreatedOn" },
          { Name: "Order ID", Type: "field", Column: "OrderID" },
          { Name: "Amount", Type: "field", Column: "Price" },
          { Name: "Status", Type: "field", Column: "State" },
          { Name: "Store", Type: "field", Column: "StoreName" },
        ],
        Sort: [{ Column: "CreatedOn", Order: "desc" }],
      };
      this.getInventoryParkedTransactions();
    }
    if (this.props.type == "onlinepaymenttransactions") {
      listPageSettings = {
        Type: "onlinepaymenttransactions",
        Title: "Payment Gateway Transactions",
        API: "getThirdPartyPaymentTransactions",
        SearchPlaceHolder: "Search by cutomer name, email id or phone no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popupwindow",
            PopupType: "newinventory",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "CreatedOn", Type: "datetime" }],
          },
          { Name: "Status", Type: "field", Columns: [{ Column: "Status" }] },
          {
            Name: "Customer",
            Type: "field",
            Columns: [
              { Column: "CustomerName" },
              { Column: "CustomerEmailID" },
              { Column: "CustomerNo" },
            ],
          },
          // {Name:'Status', Type:'field', Columns:[{Column:'Status'}]},
          {
            Name: "Payment Gateway",
            Type: "field",
            Columns: [
              { Column: "PaymentProvider", Label: "PG" },
              { Column: "PosibleTransactionID", Label: "Posible Trnx ID" },
              { Column: "AtomResponse", Label: "PG Response" },
            ],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              {
                Name: "Date",
                Type: "field",
                Columns: [{ Column: "CreatedOn", Type: "date" }],
              },
              {
                Name: "Status",
                Type: "field",
                Columns: [{ Column: "Status" }],
              },
              {
                Name: "Customer",
                Type: "field",
                Columns: [{ Column: "CustomerName" }],
              },
              {
                Name: "Email ID",
                Type: "field",
                Columns: [{ Column: "CustomerEmailID" }],
              },
              {
                Name: "Phone No",
                Type: "field",
                Columns: [{ Column: "CustomerNo" }],
              },
              {
                Name: "Payment Gateway",
                Type: "field",
                Columns: [{ Column: "PaymentProvider" }],
              },
            ],
          },
        ],
        RowButtons: [
          // {ButtonType:"ImageButton",Type:"view", Text:"View", Icon:"view", Action:"redirect", URL:"/home/inventory/viewinventory/",URLQueryString:"key", URLState:{type:"intransit"}},
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Date",
            Column: "CreatedOn",
            Type: "daterange",
            Value: {
              FromDate: new Date().toISOString().split("T")[0],
              ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Posible Trnx ID",
            Column: "PosibleTransactionID",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Customer",
            Column: "CustomerName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Email ID",
            Column: "CustomerEmailID",
            Type: "text",
            GeneralSearch: true,
            QueryType: "match_phrase",
          },
          {
            Name: "Phone No",
            Column: "CustomerNo",
            Type: "text",
            GeneralSearch: true,
            QueryType: "match_phrase",
          },
          {
            Name: "Status",
            Column: "Status",
            Type: "text",
            GeneralSearch: false,
          },
        ],
        Options: [
          {
            Text: "Export",
            Type: "export",
            ImportType: "onlinepaymenttransactions",
          },
        ],
        ExportColumns: [
          { Name: "Date", Type: "field", Column: "CreatedOn" },
          { Name: "Status", Type: "field", Column: "Status" },
          { Name: "Customer", Type: "field", Column: "CustomerName" },
          { Name: "Email ID", Type: "field", Column: "CustomerEmailID" },
          { Name: "Phone No", Type: "field", Column: "CustomerNo" },
          { Name: "Payment Gateway", Type: "field", Column: "PaymentProvider" },
        ],
        Sort: [{ Column: "CreatedOn", Order: "desc" }],
      };
      this.getInventoryParkedTransactions();
    }
    if (this.props.type === "omnisliders") {
      listPageSettings = {
        Type: "omnisliders",
        Title: "Online Store - Slider",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "omnislider",
          },
          ,
        ],
        Columns: [
          {
            Name: "Title",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "omnislider",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "omnipages") {
      listPageSettings = {
        Type: "omnipages",
        Title: "Online Store - Pages",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "omnipage",
          },
          ,
        ],
        Columns: [
          { Name: "Title", Type: "field", Columns: [{ Column: "Title" }] },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "omnipage",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "omnihomesections") {
      listPageSettings = {
        Type: "omnihomesections",
        Title: "Online Store - Home Sections",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/omni/homesections/new/",
          },
          ,
        ],
        Columns: [
          { Name: "Title", Type: "field", Columns: [{ Column: "Title" }] },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/omni/homesections/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "omnimenus") {
      listPageSettings = {
        Type: "omnimenus",
        Title: "Online Store - Menus",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/omni/menu/new",
          },
          ,
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          // {Name:'Request Count', Type:'field', Columns:[{Column:'RequestCount'}]},
          // {Name:'Response Count', Type:'field', Columns:[{Column:'ResponseCount'}]},
          // {Name:'Name',  Type:'field',ShowInMobile:true, Columns:[
          //     {Column:'Name', CSS:"bold"},
          //     {Column:'RequestCount', Label:"Request count"},
          //     {Column:'ResponseCount', Label:"Response count"},
          // ]}
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/omni/menu/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "vendor") {
      listPageSettings = {
        Type: "vendor",
        Title: "Vendors",
        API: "getVendors",
        SearchPlaceHolder: "Search by vendor name, email id, phone no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/settings/vendors/newvendor",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          { Name: "Code", Type: "field", Columns: [{ Column: "VendorCode" }] },
          {
            Name: "Name",
            Type: "field",
            Columns: [{ Column: "VendorFirstName" }],
          },
          { Name: "Email ID", Type: "field", Columns: [{ Column: "EmailID" }] },
          { Name: "Phone No", Type: "field", Columns: [{ Column: "PhoneNo" }] },
          {
            Name: "Address",
            Type: "multiple",
            Columns: [
              { Column: "Address1" },
              { Column: "Address2" },
              { Column: "City" },
              { Column: "State" },
              { Column: "Country" },
            ],
          },
          { Name: "Store", Type: "field", Columns: [] },
          { Name: "Additional Info", Type: "multiple", Columns: [] },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "VendorFirstName" },
              { Column: "EmailID" },
              { Column: "PhoneNo" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/settings/vendors/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Name",
            Column: "VendorFirstName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Code",
            Column: "VendorCode",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Phone No",
            Column: "PhoneNo",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Email ID",
            Column: "EmailID",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "City", Column: "City", Type: "text" },
          { Name: "State", Column: "State", Type: "text" },
          { Name: "PostalCode", Column: "PostalCode", Type: "text" },
          { Name: "Country", Column: "Country", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [
          { Text: "Export", Type: "export" },
          { Text: "Import", Type: "import", ImportType: "vendor" },
          { Text: "Import Log", Type: "log", LogType: "vendor" },
        ],
        ExportColumns: [
          { Name: "Code", Column: "VendorCode", Type: "text" },
          { Name: "Name", Column: "VendorFirstName", Type: "text" },
          { Name: "ContactPersonName", Column: "VendorLastName", Type: "text" },
          { Name: "EmailID", Column: "EmailID", Type: "text" },
          { Name: "PhoneNo", Column: "PhoneNo", Type: "text" },
          { Name: "LineAddress1", Column: "Address1", Type: "text" },
          { Name: "LineAddress2", Column: "Address2", Type: "text" },
          { Name: "City", Column: "City", Type: "text" },
          { Name: "StateCode", Column: "StateCode", Type: "text" },
          { Name: "State", Column: "State", Type: "text" },
          { Name: "CustomField1", Column: "CustomField1", Type: "text" },
          { Name: "CustomField2", Column: "CustomField2", Type: "text" },
          { Name: "CustomField3", Column: "CustomField3", Type: "text" },
          { Name: "CustomField4", Column: "CustomField4", Type: "text" },
          { Name: "CustomField5", Column: "CustomField5", Type: "text" },
          { Name: "CustomField6", Column: "CustomField6", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      this.props.config.CustomFields.map((customField) => {
        if (customField.ModuleName === "Vendor") {
          if (customField.CustomField1Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField1",
              Label: customField.CustomField1Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField1Name.trim(),
              Column: "CustomField1",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField1Name.trim() , Column:"CustomField1", Type:"text"})
          }
          if (customField.CustomField2Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField2",
              Label: customField.CustomField2Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField2Name.trim(),
              Column: "CustomField2",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField2Name.trim() , Column:"CustomField2", Type:"text"})
          }
          if (customField.CustomField3Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField3",
              Label: customField.CustomField3Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField3Name.trim(),
              Column: "CustomField3",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField2Name.trim() , Column:"CustomField2", Type:"text"});
          }
          if (customField.CustomField4Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField4",
              Label: customField.CustomField4Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField4Name.trim(),
              Column: "CustomField4",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField4Name.trim() , Column:"CustomField4", Type:"text"})
          }
          if (customField.CustomField5Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField5",
              Label: customField.CustomField5Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField5Name.trim(),
              Column: "CustomField5",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField5Name.trim() , Column:"CustomField5", Type:"text"})
          }
          if (customField.CustomField6Name.trim() !== "") {
            listPageSettings.Columns[6].Columns.push({
              Column: "CustomField6",
              Label: customField.CustomField6Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField6Name.trim(),
              Column: "CustomField6",
              Type: "text",
            });
            // listPageSettings.ExportColumns.push( {Name:customField.CustomField6Name.trim() , Column:"CustomField6", Type:"text"});
          }
        }
      });
    }
    if (this.props.type === "inventoryintransit") {
      listPageSettings = {
        Type: "inventoryintransit",
        Title: "Inventory In Transit",
        API: "getInventory",
        SearchPlaceHolder:
          "Search by transaction no, vendor name, email id or phone no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popupwindow",
            PopupType: "newinventory",
          },
        ],
        Columns: [
          {
            Name: "#",
            Type: "multiple",
            Columns: [
              { Column: "TransactionNo" },
              {
                Column: "CancellationReasonDisplay",
                Type: "badge",
                CSS: "red",
              },
            ],
          },
          {
            Name: "Transaction Type",
            Type: "field",
            Columns: [
              { Column: "TransactionType.TransactionType", Type: "text" },
              { Column: "ParentTransactionNo", Type: "badge", CSS: "green" },
            ],
          },
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "TransactionDate", Type: "date" }],
          },
          {
            Name: "Store",
            Type: "field",
            Columns: [{ Column: "Store.StoreName" }],
          },
          {
            Name: "Store",
            Type: "multiple",
            Columns: [
              { Column: "Vendor.VendorFirstName", Type: "text" },
              { Column: "Vendor.VendorLastName", Type: "text" },
              { Column: "Vendor.VendorCode", Type: "text" },
              { Column: "FromStore", Type: "text" },
              { Column: "ToStore", Type: "text" },
            ],
          },
          {
            Name: "Ref No",
            Type: "field",
            Columns: [{ Column: "RefNo", Type: "text" }],
          },
          {
            Name: "Notes",
            Type: "field",
            Columns: [{ Column: "Notes", Type: "text" }],
          },
          {
            Name: "Qty",
            Type: "field",
            Columns: [{ Column: "BillQty", Type: "number" }],
          },
          {
            Name: "Total Tax",
            Type: "field",
            Columns: [{ Column: "BillTaxAmount", Type: "number" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "BillDiscount", Type: "number" }],
          },
          {
            Name: "Additional Discount",
            Type: "field",
            Columns: [{ Column: "BillAdditionalDiscount", Type: "number" }],
          },
          {
            Name: "Additional Charges",
            Type: "field",
            Columns: [{ Column: "BillAdditionalCharges", Type: "number" }],
          },
          {
            Name: "Round Off",
            Type: "field",
            Columns: [{ Column: "BillRoundOff", Type: "number" }],
          },
          {
            Name: "Amount",
            Type: "field",
            Columns: [{ Column: "BillAmount", Type: "number" }],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "Status", Type: "text" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionNo", CSS: "bold" },
              { Column: "TransactionType.TransactionType" },
              { Column: "TransactionDate", Type: "date" },
              { Column: "Store.StoreName" },
              { Column: "BillQty", Type: "text", Label: "Qty" },
              { Column: "BillAmount", Type: "text", Label: "Amount" },
              { Column: "Status", Type: "badge" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/inventory/viewinventory/",
            URLQueryString: "key",
            URLState: { type: "intransit" },
          },
        ],
        Filters: [
          {
            Name: "Status",
            Column: "Status",
            Value: "In Transit",
            QueryType: "must",
          },
          {
            Name: "Storekey",
            Column: "Store.key",
            Value: this.props.user.store.key,
          },
          // {Name:"Transaction No", Column:"TransactionNo", Type:"text", GeneralSearch: false, QueryType:"match_phrase"},
          // {Name:"Transaction Type", Column:"TransactionType.key", Type:"inventorytransactiontype", Value:""},
          // {Name:"Reference No", Column:"RefNo", Type:"text", GeneralSearch: false},
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      this.getInventoryParkedTransactions();
    }
    if (this.props.type === "inventorylist") {
      listPageSettings = {
        Type: "inventorylist",
        Title: "Inventory List",
        API: "getInventory",
        SearchPlaceHolder:
          "Search by transaction no, vendor name, email id or phone no",
        HeaderButtons: [],
        Columns: [
          { Name: "", Type: "checkbox" },
          {
            Name: "#",
            Type: "multiple",
            Columns: [
              { Column: "TransactionNo" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              {
                Column: "ParentTransactions",
                Type: "list",
                Columns: [
                  {
                    Column: "ParentTransactionNo",
                    Type: "badge",
                    CSS: "green",
                  },
                ],
              },
              {
                Column: "ChildTransactions",
                Type: "list",
                Columns: [
                  { Column: "ChildTransactionNo", Type: "badge", CSS: "blue" },
                ],
              },
              { Column: "ParentTransactionNo", Type: "badge", CSS: "green" },
              { Column: "ChildTransactionNo", Type: "badge", CSS: "blue" },
            ],
          },
          {
            Name: "Transaction Type",
            Type: "field",
            Columns: [
              { Column: "TransactionType.TransactionType", Type: "text" },
            ],
          },
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "TransactionDate", Type: "date" }],
          },
          {
            Name: "Store",
            Type: "field",
            Columns: [{ Column: "Store.StoreName" }],
          },
          {
            Name: "Vendor/Store",
            Type: "multiple",
            Columns: [
              { Column: "Vendor.VendorFirstName", Type: "text" },
              { Column: "Vendor.VendorLastName", Type: "text" },
              { Column: "Vendor.VendorCode", Type: "text" },
              { Column: "FromStore", Type: "text" },
              { Column: "ToStore", Type: "text" },
            ],
          },
          {
            Name: "Ref No",
            Type: "field",
            Columns: [{ Column: "RefNo", Type: "text" }],
          },
          {
            Name: "Notes",
            Type: "field",
            Columns: [{ Column: "Notes", Type: "text" }],
          },
          {
            Name: "Qty",
            Type: "field",
            Columns: [{ Column: "BillQty", Type: "number" }],
          },
          {
            Name: "Total Tax",
            Type: "field",
            Columns: [{ Column: "BillTaxAmount", Type: "number" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "BillDiscount", Type: "number" }],
          },
          {
            Name: "Additional Discount",
            Type: "field",
            Columns: [{ Column: "BillAdditionalDiscount", Type: "number" }],
          },
          {
            Name: "Additional Charges",
            Type: "field",
            Columns: [{ Column: "BillAdditionalCharges", Type: "number" }],
          },
          {
            Name: "Round Off",
            Type: "field",
            Columns: [{ Column: "BillRoundOff", Type: "number" }],
          },
          {
            Name: "Amount",
            Type: "field",
            Columns: [{ Column: "BillAmount", Type: "number" }],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "Status", Type: "text" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionNo", CSS: "bold" },
              { Column: "TransactionType.TransactionType" },
              { Column: "TransactionDate", Type: "date" },
              { Column: "Store.StoreName" },
              { Column: "BillQty", Type: "text", Label: "Qty" },
              { Column: "BillAmount", Type: "text", Label: "Amount" },
              { Column: "Status", Type: "badge" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/inventory/viewinventory/",
            URLQueryString: "key",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "Store.key.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          {
            Name: "Transaction No",
            Column: "TransactionNo",
            Type: "text",
            GeneralSearch: false,
            QueryType: "match_phrase",
          },
          {
            Name: "Transaction Type",
            Column: "TransactionType.key",
            Type: "inventorytransactiontype",
            Value: "",
          },
          {
            Name: "Date",
            Column: "TransactionDate",
            Type: "daterange",
            Value: {
              FromDate: new Date().toISOString().split("T")[0],
              ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Vendor Name",
            Column: "Vendor.VendorFirstName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Vendor Contact No",
            Column: "Vendor.PhoneNo",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Vendor Email ID",
            Column: "Vendor.EmailID",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Vendor Code",
            Column: "Vendor.VendorCode",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Reference No",
            Column: "RefNo",
            Type: "text",
            GeneralSearch: false,
          },
        ],
        Options: [],
        ExportColumns: [
          { Name: "Store Name", Column: "Store.StoreName", Type: "text" },
          { Name: "Store Location", Column: "StoreLocation", Type: "text" },
          { Name: "From Store", Column: "FromStore", Type: "text" },
          { Name: "To Store", Column: "ToStore", Type: "text" },
          { Name: "Vendor Code", Column: "Vendor.VendorCode", Type: "text" },
          {
            Name: "Vendor Name",
            Column: "Vendor.VendorFirstName",
            Type: "text",
          },
          { Name: "Vendor Phone No.", Column: "Vendor.PhoneNo", Type: "text" },
          { Name: "Transaction No", Column: "TransactionNo", Type: "text" },
          { Name: "Ref No", Column: "RefNo", Type: "text" },
          {
            Name: "Transaction Type",
            Column: "TransactionType.TransactionType",
            Type: "text",
          },
          { Name: "Transaction Date", Column: "TransactionDate", Type: "date" },
          { Name: "Qty", Column: "BillQty", Type: "number" },
          { Name: "Total Tax", Column: "BillTaxAmount", Type: "number" },
          { Name: "Discount", Column: "BillDiscount", Type: "number" },
          {
            Name: "Additional Discount",
            Column: "BillAdditionalDiscount",
            Type: "number",
          },
          {
            Name: "Additional Charges",
            Column: "BillAdditionalCharges",
            Type: "number",
          },
          { Name: "Round Off", Column: "BillRoundOff", Type: "number" },
          { Name: "Amount", Column: "BillAmount", Type: "number" },
          { Name: "Cancelled", Column: "Cancelled", Type: "yesno" },
          { Name: "Status", Column: "Status", Type: "text" },
          { Name: "Notes", Column: "Notes", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      let exportAllowed = true;
      if (
        this.props.user.user.hasOwnProperty("RoleKey") &&
        this.props.user.user.RoleKey !== "" &&
        this.props.getVisibility("Inventory", "export") === "none"
      ) {
        exportAllowed = false;
      }
      if (exportAllowed) {
        listPageSettings.Options.push({
          Text: "Export",
          Type: "export",
          ImportType: "inventorylist",
        });
      }

      if (
        this.props.registration.registration.hasOwnProperty(
          "EnableInventoryApproval"
        ) &&
        this.props.registration.registration.EnableInventoryApproval
      ) {
        listPageSettings.Filters.push({
          Name: "Status",
          Column: "Status.keyword",
          Type: "approvalstatus",
          Value: "all",
        });
      }
      if (isMobileApp()) {
        listPageSettings.HeaderButtons.push({
          ButtonType: "Button",
          Type: "primary",
          Text: "New",
          Icon: "add",
          Action: "popupwindow",
          PopupType: "newinventory",
        });
        listPageSettings.HeaderButtons.push({
          ButtonType: "ImageButton",
          Type: "filter",
          Text: "Filter",
          Icon: "filter",
          Action: "filter",
        });
        listPageSettings.HeaderButtons.push({
          ButtonType: "ImageButton",
          Type: "option",
          Text: "Options",
          Icon: "option",
          Action: "options",
        });
        listPageSettings.Options.push({
          ButtonType: "Button",
          Type: "secondary",
          Text: "Parked",
          Icon: "",
          Action: "popup",
          PopupType: "parkedinventory",
        });
      } else {
        listPageSettings.HeaderButtons.push({
          ButtonType: "Button",
          Type: "primary",
          Text: "New",
          Icon: "add",
          Action: "popupwindow",
          PopupType: "newinventory",
        });
        listPageSettings.HeaderButtons.push({
          ButtonType: "Button",
          Type: "secondary",
          Text: "Parked",
          Icon: "",
          Action: "popup",
          PopupType: "parkedinventory",
        });
        listPageSettings.HeaderButtons.push({
          ButtonType: "ImageButton",
          Type: "filter",
          Text: "Filter",
          Icon: "filter",
          Action: "filter",
        });
        listPageSettings.HeaderButtons.push({
          ButtonType: "ImageButton",
          Type: "option",
          Text: "Options",
          Icon: "option",
          Action: "options",
        });
      }
      // console.log('inventory listPageSettings', listPageSettings)
      this.getInventoryParkedTransactions();
    }
    if (this.props.type === "pendingapprovals") {
      listPageSettings = {
        Type: "pendingapprovals",
        Title: "Pending Approval(s)",
        API: "getInventory",
        SearchPlaceHolder:
          "Search by transaction no, vendor name, email id or phone no",
        HeaderButtons: [
          // {ButtonType:"Button",Type:"primary", Text:"New", Icon:"add", Action:"popupwindow",PopupType:"newinventory"},
          // {ButtonType:"Button",Type:"secondary", Text:"Parked", Icon:"", Action:"popup",PopupType:"parkedinventory"},
          // {ButtonType:"ImageButton",Type:"filter", Text:"Filter", Icon:"filter", Action:"filter"},
          // {ButtonType:"ImageButton",Type:"option", Text:"Options", Icon:"option", Action:"options"},
        ],
        Columns: [
          // {Name:"", Type:"checkbox"},
          {
            Name: "#",
            Type: "multiple",
            Columns: [
              { Column: "TransactionNo" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              {
                Column: "ParentTransactions",
                Type: "list",
                Columns: [
                  {
                    Column: "ParentTransactionNo",
                    Type: "badge",
                    CSS: "green",
                  },
                ],
              },
              {
                Column: "ChildTransactions",
                Type: "list",
                Columns: [
                  { Column: "ChildTransactionNo", Type: "badge", CSS: "green" },
                ],
              },
            ],
          },
          {
            Name: "Transaction Type",
            Type: "field",
            Columns: [
              { Column: "TransactionType.TransactionType", Type: "text" },
            ],
          },
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "TransactionDate", Type: "date" }],
          },
          {
            Name: "Store",
            Type: "field",
            Columns: [{ Column: "Store.StoreName" }],
          },
          {
            Name: "Vendor/Store",
            Type: "multiple",
            Columns: [
              { Column: "Vendor.VendorFirstName", Type: "text" },
              { Column: "Vendor.VendorLastName", Type: "text" },
              { Column: "Vendor.VendorCode", Type: "text" },
              { Column: "FromStore", Type: "text" },
              { Column: "ToStore", Type: "text" },
            ],
          },
          {
            Name: "Ref No",
            Type: "field",
            Columns: [{ Column: "RefNo", Type: "text" }],
          },
          {
            Name: "Notes",
            Type: "field",
            Columns: [{ Column: "Notes", Type: "text" }],
          },
          {
            Name: "Qty",
            Type: "field",
            Columns: [{ Column: "BillQty", Type: "number" }],
          },
          {
            Name: "Total Tax",
            Type: "field",
            Columns: [{ Column: "BillTaxAmount", Type: "number" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "BillDiscount", Type: "number" }],
          },
          {
            Name: "Additional Discount",
            Type: "field",
            Columns: [{ Column: "BillAdditionalDiscount", Type: "number" }],
          },
          {
            Name: "Additional Charges",
            Type: "field",
            Columns: [{ Column: "BillAdditionalCharges", Type: "number" }],
          },
          {
            Name: "Round Off",
            Type: "field",
            Columns: [{ Column: "BillRoundOff", Type: "number" }],
          },
          {
            Name: "Amount",
            Type: "field",
            Columns: [{ Column: "BillAmount", Type: "number" }],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "Status", Type: "text" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionNo", CSS: "bold" },
              { Column: "TransactionType.TransactionType" },
              { Column: "TransactionDate", Type: "date" },
              { Column: "Store.StoreName" },
              { Column: "BillQty", Type: "text", Label: "Qty" },
              { Column: "BillAmount", Type: "text", Label: "Amount" },
              { Column: "Status", Type: "badge" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/inventory/viewinventory/",
            URLQueryString: "key",
            URLState: { type: "pendingapprovals" },
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          // {Name:"Transaction No", Column:"TransactionNo", Type:"text", GeneralSearch: false, QueryType:"match_phrase"},
          // {Name:"Transaction Type", Column:"TransactionType.key", Type:"inventorytransactiontype", Value:""},
          // {Name:"Vendor Name", Column:"Vendor.VendorFirstName", Type:"text", GeneralSearch: true},
          // {Name:"Vendor Contact No", Column:"Vendor.PhoneNo", Type:"text", GeneralSearch: true},
          // {Name:"Vendor Email ID", Column:"Vendor.EmailID", Type:"text", GeneralSearch: true},
          // {Name:"Vendor Code", Column:"Vendor.VendorCode", Type:"text", GeneralSearch: true},
          // {Name:"Reference No", Column:"RefNo", Type:"text", GeneralSearch: false},
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "asc" }],
      };
      let selectedStatus = "";
      this.props.config.TransactionTypes.map((m) => {
        if (!m.IsDeleted && m.EnableApproval) {
          if (m.FirstApproverDisplay === this.props.user.user.Role) {
            selectedStatus = "Pending First Approval";
          } else if (m.SecondApproverDisplay === this.props.user.user.Role) {
            selectedStatus = "Pending Second Approval";
          } else if (m.FinalApproverDisplay === this.props.user.user.Role) {
            selectedStatus = "Pending Final Approval";
          }
        }
      });
      // console.log('selectedStatus',selectedStatus)
      if (selectedStatus !== "") {
        listPageSettings.Filters.push({
          Name: "Status",
          Column: "Status.keyword",
          Type: "approvalstatus",
          Value: selectedStatus,
        });
      }
    }
    if (this.props.type === "packagelist") {
      listPageSettings = {
        Type: "packagelist",
        Title: "Package List",
        API: "getPackages",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "filterbuttons",
            FilterColumn: "PackageStatus",
            Text: "",
            Icon: "",
            Action: "",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Transaction No",
            Type: "multiple",
            Columns: [{ Column: "TransactionNo" }],
          },
          {
            Name: "Package Name",
            Type: "multiple",
            Columns: [{ Column: "PackageName" }],
          },
          {
            Name: "Customer Details",
            Type: "multiple",
            Columns: [
              { Column: "CustomerName" },
              { Column: "CustomerCode" },
              { Column: "CustomerEmailID" },
              { Column: "CustomerPhoneNo" },
            ],
          },
          {
            Name: "Package Start Date",
            Type: "multiple",
            Columns: [{ Column: "CreatedOn", Type: "date" }],
          },
          {
            Name: "Package End Date",
            Type: "multiple",
            Columns: [{ Column: "PackageEndDate", Type: "date" }],
          },
          {
            Name: "Status",
            Type: "multiple",
            Columns: [{ Column: "PackageStatus" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionNo", CSS: "bold" },
              { Column: "PackageName" },
              { Column: "CustomerName" },
              { Column: "CustomerCode" },
              { Column: "CustomerEmailID" },
              { Column: "CustomerPhoneNo" },
              { Column: "CreatedOn", Type: "date", Label: "Start Date" },
              { Column: "PackageEndDate", Type: "date", Label: "End Date" },
              { Column: "PackageStatus", Label: "Status" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/package/edit/",
            URLQueryString: "key",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "", Column: "StoreID", Value: this.props.user.store.key },
          {
            Name: "Package Status",
            Column: "PackageStatus",
            Type: "dropdown",
            Value: "pending",
            Options: [
              { Value: "pending", Text: "Pending" },
              { Value: "partial", Text: "Partial" },
              { Value: "completed", Text: "Completed" },
              { Value: "expired", Text: "Expired" },
              { Value: "cancelled", Text: "Cancelled" },
              { Value: "", Text: "All" },
            ],
          },
          { Name: "Customer Name", Column: "CustomerName", Type: "text" },
          {
            Name: "Customer Contact No",
            Column: "CustomerPhoneNo",
            Type: "text",
          },
          {
            Name: "Customer Email ID",
            Column: "CustomerEmailID",
            Type: "text",
          },
          { Name: "Customer Code", Column: "CustomerCode", Type: "text" },
          {
            Name: "Transaction No",
            Column: "TransactionNo",
            Type: "text",
            QueryType: "match_phrase",
          },
          { Name: "Package", Column: "PackageName", Type: "text" },
        ],
        Options: [
          { Text: "Export", Type: "export", ImportType: "packagelist" },
        ],
        ExportColumns: [
          { Name: "Package Code", Column: "PackageCode", Type: "text" },
          { Name: "Package Name", Column: "PackageName", Type: "text" },
          { Name: "Start Date", Column: "CreatedOn", Type: "date" },
          { Name: "End Date", Column: "PackageEndDate", Type: "date" },
          { Name: "Customer Code", Column: "CustomerCode", Type: "text" },
          { Name: "Customer Name", Column: "CustomerName", Type: "text" },
          {
            Name: "Customer Mobile No.",
            Column: "CustomerPhoneNo",
            Type: "text",
          },
          {
            Name: "Customer Email Id",
            Column: "CustomerEmailID",
            Type: "text",
          },
          { Name: "Transaction No", Column: "TransactionNo", Type: "text" },
          { Name: "Package Cost", Column: "PackageCost", Type: "number" },
          { Name: "Package Status", Column: "PackageStatus", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "deliverylist") {
      listPageSettings = {
        Type: "deliverylist",
        Title: "Delivery List",
        API: "getSales",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "filterbuttons",
            FilterColumn: "DeliveryStatus",
            Text: "",
            Icon: "",
            Action: "",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          { Name: "", Type: "checkbox" },
          {
            Name: "Delivery Date",
            Type: "multiple",
            Columns: [
              { Column: "DeliveryDate" },
              { Column: "DeliveryTimeDisplay" },
              { Column: "DeliveryNotes", Label: "Notes" },
              { Column: "Store.StoreName" },
              { Column: "RequestSource" },
            ],
          },
          {
            Name: "Recepient Details",
            Type: "multiple",
            Columns: [
              { Column: "DeliveryCustomer.FirstName" },
              { Column: "DeliveryCustomer.PhoneNo" },
            ],
          },
          {
            Name: "Delivery Address",
            Type: "multiple",
            Columns: [
              { Column: "DeliveryCustomerAddress.AddressType" },
              { Column: "DeliveryCustomerAddress.Address1" },
              { Column: "DeliveryCustomerAddress.Address2" },
              { Column: "DeliveryCustomerAddress.City" },
              { Column: "DeliveryCustomerAddress.State" },
              { Column: "DeliveryCustomerAddress.Country" },
              { Column: "DeliveryCustomerAddress.PostalCode" },
            ],
          },
          {
            Name: "Transaction Details",
            Type: "multiple",
            Columns: [
              { Column: "TransactionNo" },
              { Column: "TransactionType.TransactionType" },
              { Column: "", Type: "salelineitems" },
              { Column: "BillQty", Label: "Total Qty" },
              { Column: "BillAmount", Label: "Amount" },
            ],
          },
          {
            Name: "Delivery Status",
            Type: "multiple",
            Columns: [{ Column: "DeliveryStatus" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "options",
            Text: "options",
            Icon: "options",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Delivery Status",
            Column: "DeliveryStatus",
            Type: "dropdown",
            Value: "pending",
            Options: [
              { Value: "pending", Text: "Pending" },
              { Value: "confirmed", Text: "Confirmed" },
              { Value: "outfordelivery", Text: "Out For Delivery" },
              { Value: "readyforpickup", Text: "Ready For Pickup" },
              { Value: "delivered", Text: "Delivered" },
            ],
          },
          {
            Name: "Store",
            Column: "Store.key.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          { Name: "Customer Name", Column: "Customer.FirstName", Type: "text" },
          {
            Name: "Customer Contact No",
            Column: "Customer.PhoneNo",
            Type: "text",
          },
          {
            Name: "Customer Email ID",
            Column: "Customer.EmailID",
            Type: "text",
          },
          {
            Name: "Date",
            Column: "DeliveryDate",
            Type: "daterange",
            Value: {
              FromDate: new Date().toISOString().split("T")[0],
              ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Transaction No",
            Column: "TransactionNo",
            Type: "text",
            QueryType: "match_phrase",
          },
          {
            Name: "Transaction Type",
            Column: "TransactionType.key",
            Type: "salestransactiontype",
            Value: "",
          },
        ],
        Options: [],
        ExportColumns: [
          // {Name:"Store Name", Column:"Store.StoreName", Type:"text"},
          // {Name:"Cash Register", Column:"CashRegister.CashRegisterName", Type:"text"},
          // {Name:"Customer Code", Column:"Customer.CustomerCode", Type:"text"},
          // {Name:"Customer First Name", Column:"Customer.FirstName", Type:"text"},
          // {Name:"Customer Last Name", Column:"Customer.LastName", Type:"text"},
          // {Name:"Customer Mobile No.", Column:"Customer.PhoneNo", Type:"text"},
          // {Name:"Transaction No", Column:"TransactionNo", Type:"text"},
          // {Name:"Transaction Type", Column:"TransactionType.TransactionType", Type:"text"},
          // {Name:"Transaction Date", Column:"TransactionDate", Type:"date"},
          // {Name:"Qty", Column:"BillQty", Type:"number"},
          // {Name:"Sub Total", Column:"BillSubTotal", Type:"number"},
          // {Name:"Total Tax", Column:"BillTaxAmount", Type:"number"},
          // {Name:"Discount", Column:"BillDiscount", Type:"number"},
          // {Name:"Total", Column:"BillAmount", Type:"number"},
          // {Name:"Paid", Column:"BillPaidAmount", Type:"number"},
          // {Name:"Balance", Column:"BalanceAmount", Type:"number"},
          // {Name:"Cancelled", Column:"Cancelled", Type:"text"},
          // {Name:"CancellationReason", Column:"CancellationReason", Type:"text"},
          // {Name:"SalesReturnTransactionNo", Column:"SalesReturnTransactionNo", Type:"text"},
          // {Name:"customField1", Column:"Customer.CustomField1", Type:"text"},
          // {Name:"customField2", Column:"Customer.CustomField2", Type:"text"},
          // {Name:"customField3", Column:"Customer.CustomField3", Type:"text"},
          // {Name:"customField4", Column:"Customer.CustomField4", Type:"text"},
          // {Name:"customField5", Column:"Customer.CustomField5", Type:"text"},
          // {Name:"customField6", Column:"Customer.CustomField6", Type:"text"},
          // {Name:"Payments", Column:"Payments", Type:"payments"},
          // {Name:"TransactionDateTime", Column:"TransactionDate", Type:"datetime"},
          // {Name:"", Column:"ReceivedPaymentTransactions"}
        ],
        Sort: [{ Column: "TransactionDate", Order: "desc" }],
      };
    }
    if (this.props.type === "saleshistory") {
      let customField1 = "";
      let customField2 = "";
      let customField3 = "";
      let customField4 = "";
      let customField5 = "";
      let customField6 = "";

      this.props.config.CustomFields.map((customField) => {
        if (customField.ModuleName === "Customer") {
          customField1 =
            customField.CustomField1Name.trim() === ""
              ? "Custom Field 1"
              : customField.CustomField1Name.trim();
          customField2 =
            customField.CustomField2Name.trim() === ""
              ? "Custom Field 2"
              : customField.CustomField2Name.trim();
          customField3 =
            customField.CustomField3Name.trim() === ""
              ? "Custom Field 3"
              : customField.CustomField3Name.trim();
          customField4 =
            customField.CustomField4Name.trim() === ""
              ? "Custom Field 4"
              : customField.CustomField4Name.trim();
          customField5 =
            customField.CustomField5Name.trim() === ""
              ? "Custom Field 5"
              : customField.CustomField5Name.trim();
          customField6 =
            customField.CustomField6Name.trim() === ""
              ? "Custom Field 6"
              : customField.CustomField6Name.trim();
        }
      });
      listPageSettings = {
        Type: "saleshistory",
        Title: "Sales History",
        API: "getSales",
        SearchPlaceHolder:
          "Search by transaction no, customer name, email id or phone no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popupwindow",
            PopupType: "newsales",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          { Name: "", Type: "checkbox" },
          {
            Name: "#",
            Type: "multiple",
            Columns: [
              { Column: "TransactionNo" },
              { Column: "TransactionType.TransactionType" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              { Column: "DeliveryStatus", Type: "badge", CSS: "green" },
              { Column: "ParentTransactionNo", Type: "badge", CSS: "green" },
              {
                Column: "SalesReturnTransactionNo",
                Label: "Sales return done via ",
                Type: "badge",
                CSS: "orange-red",
              },
              { Column: "ChildTransactionNo", Type: "badge", CSS: "orange" },
              // {Column:'ReceivedPaymentTransactions', Type:'badge', CSS:'green'}
            ],
          },
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "TransactionDate", Type: "date" }],
          },
          {
            Name: "Store",
            Type: "multiple",
            Columns: [
              { Column: "Store.StoreName" },
              { Column: "CashRegister.CashRegisterName", Label: "CR" },
            ],
          },
          {
            Name: "Customer",
            Type: "multiple",
            Columns: [
              { Column: "CustomerName" },
              { Column: "Customer.EmailID" },
              { Column: "Customer.PhoneNo" },
              { Column: "CustomField1", Label: customField1 },
              { Column: "CustomField2", Label: customField2 },
              { Column: "CustomField3", Label: customField3 },
              { Column: "CustomField4", Label: customField4 },
              { Column: "CustomField5", Label: customField5 },
              { Column: "CustomField6", Label: customField6 },
              {
                Column: "BillingAddress.AddressType",
                Type: "text",
                Label: "Address",
              },
              { Column: "BillingAddress.Address1", Type: "text" },
              { Column: "BillingAddress.Address2", Type: "text" },
              { Column: "BillingAddress.City", Type: "text" },
              { Column: "BillingAddress.State", Type: "text" },
              { Column: "BillingAddress.Country", Type: "text" },
              { Column: "BillingAddress.PostalCode", Type: "text" },
            ],
          },
          {
            Name: "Qty",
            Type: "field",
            Columns: [{ Column: "BillQty", Type: "number" }],
          },
          {
            Name: "Sub Total",
            Type: "field",
            Columns: [{ Column: "BillSubTotal", Type: "number" }],
          },
          {
            Name: "Tax",
            Type: "field",
            Columns: [{ Column: "BillTaxAmount", Type: "number" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "BillDiscount", Type: "number" }],
          },
          {
            Name: "Total",
            Type: "field",
            Columns: [{ Column: "BillAmount", Type: "number" }],
          },
          {
            Name: "Paid",
            Type: "field",
            Columns: [{ Column: "BillPaidAmount", Type: "number" }],
          },
          {
            Name: "Balance",
            Type: "field",
            Columns: [{ Column: "BalanceAmount", Type: "number" }],
          },
          {
            Name: "Payment Type",
            Type: "payments",
            Columns: [{ Column: "Payments", Type: "payments" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionNo", CSS: "bold" },
              { Column: "TransactionType.TransactionType" },
              { Column: "TransactionDate", Type: "date" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              { Column: "DeliveryStatus", Type: "badge", CSS: "green" },
              { Column: "CustomerName" },
              { Column: "Customer.EmailID" },
              { Column: "Customer.PhoneNo" },
              { Column: "BillQty", Type: "text", Label: "Qty" },
              { Column: "BillAmount", Type: "text", Label: "Amount" },
              { Column: "Status", Type: "badge" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/sales/viewsales/",
            URLQueryString: "key",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "Store.key.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          {
            Name: "Cashregister",
            Column: "CashRegister.key",
            Type: "cashregister",
            Value: "",
          },
          {
            Name: "Transaction Type",
            Column: "TransactionType.key",
            Type: "salestransactiontype",
            Value: "",
          },
          {
            Name: "Date",
            Column: "TransactionDate",
            Type: "daterange",
            Value: {
              FromDate: new Date().toISOString().split("T")[0],
              ToDate: new Date().toISOString().split("T")[0],
            },
          },
          {
            Name: "Transaction No",
            Column: "TransactionNo",
            Type: "text",
            GeneralSearch: true,
            QueryType: "match_phrase",
          },
          {
            Name: "Customer Name",
            Column: "Customer.FirstName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Customer Contact No",
            Column: "Customer.PhoneNo",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Customer Email ID",
            Column: "Customer.EmailID",
            Type: "text",
            GeneralSearch: true,
          },
        ],
        Options: [],
        ExportColumns: [
          { Name: "Store Name", Column: "Store.StoreName", Type: "text" },
          {
            Name: "Cash Register",
            Column: "CashRegister.CashRegisterName",
            Type: "text",
          },
          {
            Name: "Customer Code",
            Column: "Customer.CustomerCode",
            Type: "text",
          },
          {
            Name: "Customer First Name",
            Column: "Customer.FirstName",
            Type: "text",
          },
          {
            Name: "Customer Last Name",
            Column: "Customer.LastName",
            Type: "text",
          },
          {
            Name: "Customer Mobile No.",
            Column: "Customer.PhoneNo",
            Type: "text",
          },
          { Name: "Transaction No", Column: "TransactionNo", Type: "text" },
          {
            Name: "Transaction Type",
            Column: "TransactionType.TransactionType",
            Type: "text",
          },
          { Name: "Transaction Date", Column: "TransactionDate", Type: "date" },
          { Name: "Qty", Column: "BillQty", Type: "number" },
          { Name: "Sub Total", Column: "BillSubTotal", Type: "number" },
          { Name: "Total Tax", Column: "BillTaxAmount", Type: "number" },
          { Name: "Discount", Column: "BillDiscount", Type: "number" },
          { Name: "Total", Column: "BillAmount", Type: "number" },
          { Name: "Paid", Column: "BillPaidAmount", Type: "number" },
          { Name: "Balance", Column: "BalanceAmount", Type: "number" },
          { Name: "RoundOff", Column: "RoundOff", Type: "number" },
          { Name: "Cancelled", Column: "Cancelled", Type: "text" },
          {
            Name: "CancellationReason",
            Column: "CancellationReason",
            Type: "text",
          },
          { Name: "Notes", Column: "Notes", Type: "text" },
          {
            Name: "SalesReturnTransactionNo",
            Column: "SalesReturnTransactionNo",
            Type: "text",
          },
          {
            Name: "customField1",
            Column: "Customer.CustomField1",
            Type: "text",
          },
          {
            Name: "customField2",
            Column: "Customer.CustomField2",
            Type: "text",
          },
          {
            Name: "customField3",
            Column: "Customer.CustomField3",
            Type: "text",
          },
          {
            Name: "customField4",
            Column: "Customer.CustomField4",
            Type: "text",
          },
          {
            Name: "customField5",
            Column: "Customer.CustomField5",
            Type: "text",
          },
          {
            Name: "customField6",
            Column: "Customer.CustomField6",
            Type: "text",
          },
          {
            Name: "BillingAddressType",
            Column: "BillingAddress.AddressType",
            Type: "text",
          },
          {
            Name: "BillingAddressLine1",
            Column: "BillingAddress.Address1",
            Type: "text",
          },
          {
            Name: "BillingAddressLine2",
            Column: "BillingAddress.Address2",
            Type: "text",
          },
          {
            Name: "BillingAddressCity",
            Column: "BillingAddress.City",
            Type: "text",
          },
          {
            Name: "BillingAddressState",
            Column: "BillingAddress.State",
            Type: "text",
          },
          {
            Name: "BillingAddressCountry",
            Column: "BillingAddress.Country",
            Type: "text",
          },
          {
            Name: "BillingAddressPostalCode",
            Column: "BillingAddress.PostalCode",
            Type: "text",
          },
          {
            Name: "DeliveryAddressType",
            Column: "DeliveryCustomerAddress.AddressType",
            Type: "text",
          },
          {
            Name: "DeliveryAddressLine1",
            Column: "DeliveryCustomerAddress.Address1",
            Type: "text",
          },
          {
            Name: "DeliveryAddressLine2",
            Column: "DeliveryCustomerAddress.Address2",
            Type: "text",
          },
          {
            Name: "DeliveryAddressCity",
            Column: "DeliveryCustomerAddress.City",
            Type: "text",
          },
          {
            Name: "DeliveryAddressState",
            Column: "DeliveryCustomerAddress.State",
            Type: "text",
          },
          {
            Name: "DeliveryAddressCountry",
            Column: "DeliveryCustomerAddress.Country",
            Type: "text",
          },
          {
            Name: "DeliveryAddressPostalCode",
            Column: "DeliveryCustomerAddress.PostalCode",
            Type: "text",
          },
          { Name: "Change Due", Column: "BillChangeDue", Type: "text" },
          { Name: "Payments", Column: "Payments", Type: "payments" },
          {
            Name: "TransactionDateTime",
            Column: "TransactionDate",
            Type: "datetime",
          },
          { Name: "", Column: "ReceivedPaymentTransactions" },
        ],
        Sort: [{ Column: "TransactionDate", Order: "desc" }],
      };
      let exportAllowed = true;
      if (this.props.config.WhiteLabel.Code === "WRX") {
        listPageSettings.ExportColumns.push({
          Name: "SalesChannelName",
          Column: "SalesChannelName",
          Type: "text",
        });
      }
      // console.log('this.props.getVisibility("Sales","export")', this.props.getVisibility("Sales","export"))
      if (
        this.props.user.user.hasOwnProperty("RoleKey") &&
        this.props.user.user.RoleKey !== "" &&
        this.props.getVisibility("Sales", "export") === "none"
      ) {
        exportAllowed = false;
      }
      // console.log('exportAllowed',exportAllowed)
      if (exportAllowed) {
        listPageSettings.Options.push({
          Text: "Export",
          Type: "export",
          ImportType: "saleshistory",
        });
      }

      // for (let index = 0; index < this.props.config.PaymentTypes.length; index++) {
      //     if (this.props.config.PaymentTypes[index].ShowInSales)
      //         listPageSettings.ExportColumns.push({Name:this.props.config.PaymentTypes[index].PaymentType, Column:this.props.config.PaymentTypes[index].PaymentType, Type:"number"})
      //         // invoice[this.props.config.PaymentTypes[index].PaymentType] = ""
      // }
    }
    if (this.props.type === "store") {
      let customField1 = "";
      let customField2 = "";
      let customField3 = "";
      let customField4 = "";
      let customField5 = "";
      let customField6 = "";

      this.props.config.CustomFields.map((customField) => {
        if (customField.ModuleName === "Store") {
          customField1 =
            customField.CustomField1Name.trim() === ""
              ? "Custom Field 1"
              : customField.CustomField1Name.trim();
          customField2 =
            customField.CustomField2Name.trim() === ""
              ? "Custom Field 2"
              : customField.CustomField2Name.trim();
          customField3 =
            customField.CustomField3Name.trim() === ""
              ? "Custom Field 3"
              : customField.CustomField3Name.trim();
          customField4 =
            customField.CustomField4Name.trim() === ""
              ? "Custom Field 4"
              : customField.CustomField4Name.trim();
          customField5 =
            customField.CustomField5Name.trim() === ""
              ? "Custom Field 5"
              : customField.CustomField5Name.trim();
          customField6 =
            customField.CustomField6Name.trim() === ""
              ? "Custom Field 6"
              : customField.CustomField6Name.trim();
        }
      });
      listPageSettings = {
        Type: "store",
        Title: "Stores",
        API: "getStores",
        SearchPlaceHolder: "Search by store code, name, email id or phone no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/settings/stores/newstore",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
          ,
        ],
        Columns: [
          {
            Name: "Store Code",
            Type: "field",
            Columns: [{ Column: "StoreCode" }],
          },
          {
            Name: "Store Name",
            Type: "field",
            Columns: [{ Column: "StoreName" }],
          },
          { Name: "EmailID", Type: "field", Columns: [{ Column: "EmailID" }] },
          { Name: "Phone No", Type: "field", Columns: [{ Column: "PhoneNo" }] },
          {
            Name: "Address",
            Type: "multiple",
            Columns: [
              { Column: "Address1" },
              { Column: "Address2" },
              { Column: "Locality" },
              { Column: "City" },
              { Column: "State" },
              { Column: "PostalCode" },
              { Column: "Country" },
            ],
          },
          {
            Name: "SMS Count",
            Type: "multiple",
            Columns: [
              { Column: "NoOfPromotionalSMS", Label: "Promotional SMS" },
              { Column: "NoOfTransactionalSMS", Label: "Transactional SMS" },
            ],
          },
          {
            Name: "Additonal Info",
            Type: "multiple",
            Columns: [
              { Column: "CustomField1", Label: customField1 },
              { Column: "CustomField2", Label: customField2 },
              { Column: "CustomField3", Label: customField3 },
              { Column: "CustomField4", Label: customField4 },
              { Column: "CustomField5", Label: customField5 },
              { Column: "CustomField6", Label: customField6 },
            ],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "StoreName", CSS: "bold" },
              { Column: "EmailID" },
              { Column: "PhoneNo" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/settings/stores/viewstoredetails/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/settings/stores/editstore/",
            URLQueryString: "key",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },

          // {
          //   Name: "Store Code",
          //   Column: "StoreCode",
          //   Type: "text",
          //   GeneralSearch: true,
          // },
          // {
          //   Name: "Store Name",
          //   Column: "StoreName",
          //   Type: "text",
          //   GeneralSearch: true,
          // },
          {
            Name: "Email ID",
            Column: "EmailID",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Phone No",
            Column: "PhoneNo",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "City", Column: "City", Type: "text" },
          { Name: "State", Column: "State", Type: "text" },
          { Name: "Pincode", Column: "PostalCode", Type: "text" },
          { Name: "Country", Column: "Country", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [
          { Text: "Export", Type: "export" },
          { Text: "Import", Type: "import", ImportType: "store" },
          { Text: "Import Log", Type: "log", LogType: "store" },
        ],
        ExportColumns: [
          { Name: "StoreCode", Column: "StoreCode", Type: "text" },
          { Name: "StoreName", Column: "StoreName", Type: "text" },
          { Name: "EmailID", Column: "EmailID", Type: "text" },
          { Name: "PhoneNo", Column: "PhoneNo", Type: "text" },
          { Name: "LineAddress1", Column: "Address1", Type: "text" },
          { Name: "LineAddress2", Column: "Address2", Type: "text" },
          { Name: "City", Column: "City", Type: "text" },
          { Name: "StateCode", Column: "StateCode", Type: "text" },
          { Name: "State", Column: "State", Type: "text" },
          { Name: "PostalCode", Column: "PostalCode", Type: "text" },
          { Name: "Country", Column: "Country", Type: "text" },
          { Name: "Country", Column: "Country", Type: "text" },
          { Name: "Latitude", Column: "Location.lat", Type: "text" },
          { Name: "Longitude", Column: "Location.lon", Type: "text" },
          {
            Name: "EnableCashRegisterClosing",
            Column: "CashRegisterClosing",
            Type: "yesno",
          },
          {
            Name: "NegativeProductQtyNotAllowedForBilling",
            Column: "NegativeProductQtyNotAllowedForBilling",
            Type: "yesno",
          },
          {
            Name: "ChangeQtyOnPriceUpdate",
            Column: "ChangeQtyOnPriceUpdate",
            Type: "yesno",
          },
          { Name: "CustomField1", Column: "CustomField1", Type: "text" },
          { Name: "CustomField2", Column: "CustomField2", Type: "text" },
          { Name: "CustomField3", Column: "CustomField3", Type: "text" },
          { Name: "CustomField4", Column: "CustomField4", Type: "text" },
          { Name: "CustomField5", Column: "CustomField5", Type: "text" },
          { Name: "CustomField6", Column: "CustomField6", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      if (this.props.config.WhiteLabel.Code !== "PINCODE") {
        listPageSettings.RowButtons.push({
          ButtonType: "ImageButton",
          Type: "delete",
          Text: "Delete",
          Icon: "delete",
          Action: "delete",
        });
      }
      if (this.props.user.user.Role !== "Power User") {
        listPageSettings.Filters.unshift({
          Name: "Stores",
          Column: "_id",
          Type: "stores",
          Value: this.props.user.store.key,
        });
      }
    }
    if (this.props.type === "survey") {
      listPageSettings = {
        Type: "survey",
        Title: "Survey",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/survey/new",
          },
          ,
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Request Count",
            Type: "field",
            Columns: [{ Column: "RequestCount" }],
          },
          {
            Name: "Response Count",
            Type: "field",
            Columns: [{ Column: "ResponseCount" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "bold" },
              { Column: "RequestCount", Label: "Request count" },
              { Column: "ResponseCount", Label: "Response count" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/survey/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "customer") {
      listPageSettings = {
        Type: "customer",
        Title: "Customers",
        API: "getCustomers",
        Collection: "customers",
        SearchPlaceHolder: "Search customer by name, mobile no or email id",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/customer/newcustomer",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
          ,
        ],
        Columns: [
          // {Name:'Name',Type:'concatenate', Columns:[{Column:'FirstName'},{Column:'LastName'}]},
          {
            Name: "Customer Details",
            Type: "multiple",
            Columns: [
              {
                Type: "concatenate",
                Columns: [{ Column: "FirstName" }, { Column: "LastName" }],
              },
              { Column: "CustomerCode" },
              { Column: "Gender" },
            ],
          },
          {
            Name: "Contact Details",
            Type: "multiple",
            Columns: [{ Column: "PhoneNo" }, { Column: "EmailID" }],
          },
          { Name: "Address", Type: "address" },
          {
            Name: "Additional Details",
            Type: "multiple",
            Columns: [
              { Label: "Amount Spent", Column: "AmountSpent" },
              { Label: "No Of Visits", Column: "NoOfVisits" },
              { Column: "LastVisitedOn", Label: "Last Visited", Type: "date" },
              {
                Column: "WalletLastCreditAmount",
                Label: "Last Recharged Amount",
                Type: "number",
              },
              {
                Column: "WalletBalance",
                Label: "Wallet Balance Amount",
                Type: "number",
              },
              { Column: "Membership.Name", Label: "Membership" },
            ],
          },
          { Name: "Notes", Type: "field", Columns: [{ Column: "Notes" }] },
          {
            Name: "",
            Type: "multiple",
            ShowInMobile: true,
            Columns: [
              {
                Type: "concatenate",
                Columns: [{ Column: "FirstName" }, { Column: "LastName" }],
              },
              { Column: "CustomerCode" },
              { Column: "PhoneNo" },
              { Column: "EmailID" },
              {
                Column: "WalletBalance",
                Label: "Wallet Amount",
                Type: "number",
              },
              { Column: "Membership.Name", Label: "Membership" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/customer/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "view",
            Text: "View",
            Icon: "view",
            Action: "redirect",
            URL: "/home/customer/customerdashboard/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Name",
            Column: "CustomerDetails",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Mobile No",
            Column: "PhoneNo",
            Type: "number",
            GeneralSearch: true,
          },
          {
            Name: "Email ID",
            Column: "EmailID",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "City", Column: "Addresses.City", Type: "text" },
          { Name: "State", Column: "Addresses.State", Type: "text" },
          { Name: "Pincode", Column: "Addresses.PostalCode", Type: "text" },
          { Name: "Country", Column: "Addresses.Country", Type: "text" },
          { Name: "Membership", Column: "Membership.Name", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          // {Name:"", Column:"", Type:"storeID"},
        ],
        Options: [],
        ExportColumns: [
          { Name: "Customer ID", Column: "key", Type: "text" },
          { Name: "Customer Code", Column: "CustomerCode", Type: "text" },
          { Name: "First Name", Column: "FirstName", Type: "text" },
          { Name: "Last Name", Column: "LastName", Type: "text" },
          { Name: "Gender", Column: "Gender", Type: "text" },
          { Name: "PhoneNo", Column: "PhoneNo", Type: "text" },
          { Name: "EmailID", Column: "EmailID", Type: "text" },
          { Name: "Notes", Column: "Notes", Type: "text" },
          { Name: "Date of Birth", Column: "DOBNew", Type: "date_formatted" },
          {
            Name: "Aniversary",
            Column: "AnniversaryNew",
            Type: "date_formatted",
          },
          { Name: "Addresses", Column: "Addresses", Type: "address" },
          { Name: "Membership", Column: "Membership.Name", Type: "text" },
          {
            Name: "MembershipEnrolledBy",
            Column: "Membership.MembershipEnrolledBy",
            Type: "text",
          },
          {
            Name: "MembershipAssignmentDate",
            Column: "Membership.AssignmentDate",
            Type: "date",
          },
          {
            Name: "Opening Balance",
            Column: "Membership.Name",
            Type: "number",
          },
          {
            Name: "Outstanding Amount",
            Column: "OutStandingAmount",
            Type: "number",
          },
          { Name: "Wallet Balance", Column: "WalletBalance", Type: "text" },
          {
            Name: "Last Recharged Amount",
            Column: "WalletLastCreditAmount",
            Type: "text",
          },
          { Name: "Last Visited On", Column: "LastVisitedOn", Type: "date" },
          { Name: "Amount Spent", Column: "AmountSpent", Type: "number" },
          { Name: "No Of Visits", Column: "NoOfVisits", Type: "number" },
          { Name: "Store", Column: "Store", Type: "text" },
          { Name: "StoreID", Column: "StoreID", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };

      if (
        this.props.user.user.Role === "Power User" ||
        this.props.user.user.Role === "Store Owner"
      ) {
        if (
          this.props.user.store &&
          this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
          this.props.user.store.NoOfDaysToExpire > 15
        ) {
          listPageSettings.Options.push({ Text: "Export", Type: "export" });
        }
        listPageSettings.Options.push({
          Text: "Customer Import",
          Type: "import",
          ImportType: "customer",
        });
        listPageSettings.Options.push({
          Text: "Customer Import Log",
          Type: "log",
          LogType: "customer",
        });
        if (
          this.props.registration.registration.hasOwnProperty(
            "LoyaltyEnabled"
          ) &&
          this.props.registration.registration.LoyaltyEnabled
        ) {
          listPageSettings.Options.push({
            Text: "Customer Loyalty Import",
            Type: "import",
            ImportType: "loyaltyCustomer",
          });
          listPageSettings.Options.push({
            Text: "Customer Loyalty Import Log",
            Type: "log",
            LogType: "loyaltyCustomer",
          });
        }
      }

      //check if customer custom fields are defined
      this.props.config.CustomFields.map((customField) => {
        if (customField.ModuleName === "Customer") {
          if (customField.CustomField1Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField1",
              Label: customField.CustomField1Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField1Name.trim(),
              Column: "CustomField1",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField1Name.trim(),
              Column: "CustomField1",
              Type: "text",
            });
          }
          if (customField.CustomField2Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField2",
              Label: customField.CustomField2Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField2Name.trim(),
              Column: "CustomField2",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField2Name.trim(),
              Column: "CustomField2",
              Type: "text",
            });
          }
          if (customField.CustomField3Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField3",
              Label: customField.CustomField3Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField3Name.trim(),
              Column: "CustomField3",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField2Name.trim(),
              Column: "CustomField2",
              Type: "text",
            });
          }
          if (customField.CustomField4Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField4",
              Label: customField.CustomField4Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField4Name.trim(),
              Column: "CustomField4",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField4Name.trim(),
              Column: "CustomField4",
              Type: "text",
            });
          }
          if (customField.CustomField5Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField5",
              Label: customField.CustomField5Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField5Name.trim(),
              Column: "CustomField5",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField5Name.trim(),
              Column: "CustomField5",
              Type: "text",
            });
          }
          if (customField.CustomField6Name.trim() !== "") {
            listPageSettings.Columns[3].Columns.push({
              Column: "CustomField6",
              Label: customField.CustomField6Name.trim(),
            });
            listPageSettings.Filters.push({
              Name: customField.CustomField6Name.trim(),
              Column: "CustomField6",
              Type: "text",
            });
            listPageSettings.ExportColumns.push({
              Name: customField.CustomField6Name.trim(),
              Column: "CustomField6",
              Type: "text",
            });
          }
        }
      });
    }
    if (this.props.type === "itemnotfoundinmastercatalogue") {
      listPageSettings = {
        Type: "itemnotfoundinmastercatalogue",
        Title: "Item not found in Master Catalogue",
        API: "getProducts",
        Collection: "products",
        SearchPlaceHolder: "Search product by SKU, barcode or name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "product",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Code",
            Type: "multiple",
            Columns: [{ Column: "BarCode" }],
          },
          {
            Name: "Name",
            Type: "multiple",
            Columns: [{ Column: "Name" }, { Column: "Description" }],
          },
          {
            Name: "Category",
            Type: "field",
            Columns: [{ Column: "Category" }],
          },
          {
            Name: "Sub Category",
            Type: "field",
            Columns: [{ Column: "SubCategory" }],
          },
          {
            Name: "MRP",
            Type: "field",
            Columns: [{ Column: "MRP", Type: "number" }],
          },
          {
            Name: "Retail Price",
            Type: "field",
            Columns: [{ Column: "RetailPrice", Type: "number" }],
          },
          {
            Name: "Show on Pincode App",
            Type: "field",
            Columns: [{ Column: "OMNIEnabled", Type: "yesno" }],
          },
          {
            Name: "Show Out of Stock",
            Type: "field",
            Columns: [{ Column: "OutOfStock", Type: "yesno" }],
          },
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "main" },
              { Column: "SKU" },
              { Column: "BarCode" },
              { Column: "RetailPrice", Label: "Retail price" },
              { Column: "MRP", Label: "MRP" },
              {
                Column: "OMNIEnabled",
                Type: "yesno",
                Label: "Show on Pincode App",
              },
              {
                Column: "OutOfStock",
                Type: "yesno",
                Label: "Show Out of Stock",
              },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "product",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "StoreSpecificPrices.StoreID.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          {
            Name: "SKU",
            Column: "SKU",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Barcode",
            Column: "BarCode",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Category",
            Column: "Category",
            Type: "text",
            QueryType: "match_phrase",
          },
          {
            Name: "Sub Category",
            Column: "SubCategory",
            Type: "text",
            QueryType: "match_phrase",
          },
          {
            Name: "Brand",
            Column: "Brand",
            Type: "text",
            QueryType: "match_phrase",
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          {
            Name: "",
            Column: "ParentProductID",
            QueryType: "must_not",
            Value: { exists: { field: "ParentProductID" } },
          },
          {
            Name: "",
            Column: "CustomField2.keyword", //
            QueryType: "match",
            Value: "not in master catalog",
          },
        ],
        Options: [],
        ExportColumns: [
          { Name: "SKU", Column: "SKU", Type: "text" },
          { Name: "BarCode", Column: "BarCode", Type: "text" },
          { Name: "Name", Column: "Name", Type: "text" },
          { Name: "Brand", Column: "Brand", Type: "text" },
          { Name: "Category", Column: "Category", Type: "text" },
          { Name: "SubCategory", Column: "SubCategory", Type: "text" },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "MRP", Column: "MRP", Type: "number" },
          { Name: "RetailPrice", Column: "RetailPrice", Type: "number" },
          { Name: "ShowOnline", Column: "OMNIEnabled", Type: "yesno" },
          { Name: "OutOfStock", Column: "OutOfStock", Type: "yesno" },
          { Name: "PackingQty", Column: "PincodePackingQty", Type: "text" },
          { Name: "PackingUOM", Column: "PincodePackingUOM", Type: "text" },
          {
            Name: "ProductType",
            Column: "PincodeProductProductType",
            Type: "text",
          },
          { Name: "Model", Column: "PincodeModel", Type: "text" },
          { Name: "Packaging", Column: "PincodePackaging", Type: "text" },
          { Name: "Bundling", Column: "PincodeBundling", Type: "text" },
          { Name: "Cancellable", Column: "PincodeCancellable", Type: "yesno" },
          { Name: "Returnable", Column: "PincodeReturnable", Type: "yesno" },
          { Name: "Manufacturer", Column: "PincodeManufacturer", Type: "text" },
          { Name: "ProductID", Column: "key", Type: "key" },
          { Name: "", Column: "StoreSpecificPrices", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      if (this.props.registration.registration.EnablePincodeSKU) {
        listPageSettings.Columns[1].Columns.push({ Column: "SKU" });
      }
      if (
        this.props.user.store
          .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount
      ) {
        listPageSettings.Columns.push({
          Name: "In Stock Qty",
          Type: "field",
          Columns: [{ Column: "InStockQty", Type: "number" }],
        });
        listPageSettings.ExportColumns.push({
          Name: "InStock",
          Column: "InStockQty",
          Type: "number",
        });
      }
      if (
        this.props.user.user.Role === "Power User" ||
        this.props.user.user.Role === "Store Owner"
      ) {
        if (
          this.props.user.store &&
          this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
          this.props.user.store.NoOfDaysToExpire > 15
        ) {
          listPageSettings.Options.push({ Text: "Export", Type: "export" });
        }
        listPageSettings.Options.push({
          Text: "Product Import",
          Type: "import",
          ImportType: "product",
        });
        listPageSettings.Options.push({
          Text: "Product Import Log",
          Type: "log",
          LogType: "product",
        });
      }
      if (this.props.user.user.Role === "Power User") {
        listPageSettings.RowButtons.push({
          ButtonType: "ImageButton",
          Type: "delete",
          Text: "Delete",
          Icon: "delete",
          Action: "delete",
        });
      }
    }
    if (this.props.type === "products") {
      // let stores = await this.props.getUserStores(
      //   this.props.user.user.RegistrationID,
      //   ""
      // );

      listPageSettings = {
        Type: "products",
        Title: "Products",
        API: "getProducts",
        Collection: "products",
        SearchPlaceHolder: "Search product by SKU, barcode or name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/product/newproduct",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Code",
            Type: "multiple",
            Columns: [{ Column: "BarCode" }],
          },
          {
            Name: "Name",
            Type: "multiple",
            Columns: [{ Column: "Name" }, { Column: "Description" }],
          },
          {
            Name: "Category",
            Type: "field",
            Columns: [{ Column: "Category" }],
          },
          {
            Name: "Sub Category",
            Type: "field",
            Columns: [{ Column: "SubCategory" }],
          },
          {
            Name: "MRP",
            Type: "field",
            Columns: [{ Column: "MRP", Type: "number" }],
          },
          {
            Name: "Retail Price",
            Type: "field",
            Columns: [{ Column: "RetailPrice", Type: "number" }],
          },
          {
            Name: "Show on Pincode App",
            Type: "field",
            Columns: [{ Column: "OMNIEnabled", Type: "yesno" }],
          },
          {
            Name: "Show Out of Stock",
            Type: "field",
            Columns: [{ Column: "OutOfStock", Type: "yesno" }],
          },
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "main" },
              { Column: "SKU" },
              { Column: "BarCode" },
              { Column: "RetailPrice", Label: "Retail price" },
              { Column: "MRP", Label: "MRP" },
              {
                Column: "OMNIEnabled",
                Type: "yesno",
                Label: "Show on Pincode App",
              },
              {
                Column: "OutOfStock",
                Type: "yesno",
                Label: "Show Out of Stock",
              },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/product/edit/",
            URLQueryString: "key",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "SKU", Column: "SKU", Type: "text", GeneralSearch: true },
          {
            Name: "Barcode",
            Column: "BarCode",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Category",
            Column: "Category",
            Type: "text",
          },
          {
            Name: "Sub Category",
            Column: "SubCategory",
            Type: "text",
          },
          {
            Name: "Brand",
            Column: "Brand",
            Type: "text",
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          {
            Name: "",
            Column: "ParentProductID",
            QueryType: "must_not",
            Value: { exists: { field: "ParentProductID" } },
          },
          {
            Name: "",
            Column: "CustomField2.keyword",
            QueryType: "match",
            Value: "",
          },
        ],
        Options: [],
        ExportColumns: [
          { Name: "SKU", Column: "SKU", Type: "text" },
          { Name: "BarCode", Column: "BarCode", Type: "text" },
          { Name: "Name", Column: "Name", Type: "text" },
          { Name: "Brand", Column: "Brand", Type: "text" },
          { Name: "Category", Column: "Category", Type: "text" },
          { Name: "SubCategory", Column: "SubCategory", Type: "text" },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "MRP", Column: "MRP", Type: "number" },
          { Name: "RetailPrice", Column: "RetailPrice", Type: "number" },
          { Name: "ShowOnline", Column: "OMNIEnabled", Type: "yesno" },
          { Name: "OutOfStock", Column: "OutOfStock", Type: "yesno" },
          { Name: "PackingQty", Column: "PincodePackingQty", Type: "text" },
          { Name: "PackingUOM", Column: "PincodePackingUOM", Type: "text" },
          {
            Name: "ProductType",
            Column: "PincodeProductProductType",
            Type: "text",
          },
          { Name: "Model", Column: "PincodeModel", Type: "text" },
          { Name: "Packaging", Column: "PincodePackaging", Type: "text" },
          { Name: "Bundling", Column: "PincodeBundling", Type: "text" },
          { Name: "Cancellable", Column: "PincodeCancellable", Type: "yesno" },
          { Name: "Returnable", Column: "PincodeReturnable", Type: "yesno" },
          { Name: "Manufacturer", Column: "PincodeManufacturer", Type: "text" },
          { Name: "ProductID", Column: "key", Type: "key" },
          { Name: "", Column: "StoreSpecificPrices", Type: "text" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
      if (this.props.registration.registration.EnablePincodeSKU) {
        listPageSettings.Columns[1].Columns.push({ Column: "SKU" });
      }
      if (
        this.props.user.store
          .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount
      ) {
        listPageSettings.Columns.push({
          Name: "In Stock Qty",
          Type: "field",
          Columns: [{ Column: "InStockQty", Type: "number" }],
        });
        listPageSettings.ExportColumns.push({
          Name: "InStock",
          Column: "InStockQty",
          Type: "number",
        });
      }
      if (
        this.props.user.user.Role === "Power User" ||
        this.props.user.user.Role === "Store Owner"
      ) {
        if (
          this.props.user.store &&
          this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
          this.props.user.store.NoOfDaysToExpire > 15
        ) {
          listPageSettings.Options.push({ Text: "Export", Type: "export" });
        }
        listPageSettings.Options.push({
          Text: "Product Import",
          Type: "import",
          ImportType: "product",
        });
        listPageSettings.Options.push({
          Text: "Product Import Log",
          Type: "log",
          LogType: "product",
        });
      }
      if (this.props.user.user.Role === "Power User") {
        listPageSettings.RowButtons.push({
          ButtonType: "ImageButton",
          Type: "delete",
          Text: "Delete",
          Icon: "delete",
          Action: "delete",
        });
      }
    }
    if (this.props.type === "product") {
      let customField1DisplayName = "";
      let customField2DisplayName = "";
      let customField3DisplayName = "";
      let customField4DisplayName = "";
      let customField5DisplayName = "";
      let customField6DisplayName = "";

      this.props.config.CustomFields.map((customField) => {
        if (customField.ModuleName === "Product") {
          customField1DisplayName =
            customField.CustomField1Name.trim() === ""
              ? "Custom Field 1"
              : customField.CustomField1Name.trim();
          customField2DisplayName =
            customField.CustomField2Name.trim() === ""
              ? "Custom Field 2"
              : customField.CustomField2Name.trim();
          customField3DisplayName =
            customField.CustomField3Name.trim() === ""
              ? "Custom Field 3"
              : customField.CustomField3Name.trim();
          customField4DisplayName =
            customField.CustomField4Name.trim() === ""
              ? "Custom Field 4"
              : customField.CustomField4Name.trim();
          customField5DisplayName =
            customField.CustomField5Name.trim() === ""
              ? "Custom Field 5"
              : customField.CustomField5Name.trim();
          customField6DisplayName =
            customField.CustomField6Name.trim() === ""
              ? "Custom Field 6"
              : customField.CustomField6Name.trim();
        }
      });
      listPageSettings = {
        Type: "product",
        Title: "Products",
        API: "getProducts",
        Collection: "products",
        SearchPlaceHolder: "Search product by SKU, barcode or name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/product/newproduct",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Type",
            Type: "multiple",
            Columns: [{ Column: "ProductType" }, { Column: "InventoryType" }],
          },
          {
            Name: "SKU",
            Type: "multiple",
            Columns: [{ Column: "SKU" }, { Column: "BarCode" }],
          },
          {
            Name: "Name",
            Type: "multiple",
            Columns: [{ Column: "Name" }, { Column: "Description" }],
          },
          {
            Name: "Category",
            Type: "field",
            Columns: [{ Column: "Category" }],
          },
          {
            Name: "Sub Category",
            Type: "field",
            Columns: [{ Column: "SubCategory" }],
          },
          {
            Name: "Supply Price",
            Type: "field",
            Columns: [{ Column: "SupplyPrice", Type: "number" }],
          },
          {
            Name: "Retail Price",
            Type: "field",
            Columns: [{ Column: "RetailPrice", Type: "number" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "DiscountAmount", Type: "number" }],
          },
          {
            Name: "MRP",
            Type: "field",
            Columns: [{ Column: "MRP", Type: "number" }],
          },
          {
            Name: "Show Online",
            Type: "field",
            Columns: [{ Column: "OMNIEnabled", Type: "yesno" }],
          },
          // {Name:'',  Type:'field',ShowInMobile:true, Columns:[{Column:'Image', Type:'image'}]},
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "main" },
              { Column: "SKU" },
              { Column: "BarCode" },
              { Column: "ProductType" },
              { Column: "RetailPrice", Label: "Retail price" },
              { Column: "OMNIEnabled", Type: "yesno", Label: "Show online" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/product/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Product Type",
            Column: "ProductType",
            Type: "producttype",
            // QueryType: "term",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "SKU", Column: "SKU", Type: "text", GeneralSearch: true },
          {
            Name: "Barcode",
            Column: "BarCode",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Category",
            Column: "Category",
            Type: "text",
            // QueryType: "match_phrase",
          },
          {
            Name: "Sub Category",
            Column: "SubCategory",
            Type: "text",
            // QueryType: "match_phrase",
          },
          {
            Name: "Brand",
            Column: "Brand",
            Type: "text",
            // QueryType: "match_phrase",
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          {
            Name: "",
            Column: "ParentProductID",
            QueryType: "must_not",
            Value: { exists: { field: "ParentProductID" } },
          },
        ],
        Options: [],
        ExportColumns: [
          { Name: "ProductType", Column: "ProductType", Type: "text" },
          { Name: "SKU", Column: "SKU", Type: "text" },
          { Name: "BarCode", Column: "BarCode", Type: "text" },
          { Name: "Brand", Column: "Brand", Type: "text" },
          { Name: "Category", Column: "Category", Type: "text" },
          { Name: "SubCategory", Column: "SubCategory", Type: "text" },
          { Name: "Name", Column: "Name", Type: "text" },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "HSNSACCode", Column: "HSNSACCode", Type: "text" },
          {
            Name: "ChargeTaxOnProduct",
            Column: "ChargeTaxOnProduct",
            Type: "yesno",
          },
          { Name: "MRP", Column: "MRP", Type: "number" },
          { Name: "SupplyPrice", Column: "SupplyPrice", Type: "number" },
          { Name: "RetailPrice", Column: "RetailPrice", Type: "number" },
          { Name: "Markup", Column: "Markup", Type: "number" },
          {
            Name: "DiscountPercentage",
            Column: "DiscountPercentage",
            Type: "number",
          },
          { Name: "DiscountAmount", Column: "DiscountAmount", Type: "number" },
          { Name: "TaxInclusive", Column: "TaxInclusive", Type: "yesno" },
          {
            Name: "TaxInclusivePurchase",
            Column: "TaxInclusivePurchase",
            Type: "yesno",
          },
          { Name: "TaxGroup", Column: "TaxGroup", Type: "text" },
          { Name: "PriceBasedTax", Column: "PriceBasedTax", Type: "yesno" },
          {
            Name: "PriceRangeFrom1",
            Column: "PriceRangeFrom1",
            Type: "number",
          },
          { Name: "PriceRangeTo1", Column: "PriceRangeTo1", Type: "number" },
          { Name: "TaxGroup1", Column: "TaxGroup1", Type: "text" },
          {
            Name: "PriceRangeFrom2",
            Column: "PriceRangeFrom2",
            Type: "number",
          },
          { Name: "PriceRangeTo2", Column: "PriceRangeTo2", Type: "number" },
          { Name: "TaxGroup2", Column: "TaxGroup2", Type: "text" },
          { Name: "InventoryType", Column: "InventoryType", Type: "text" },
          { Name: "RepackProduce", Column: "RepackProduce", Type: "yesno" },
          { Name: "MinQty", Column: "MinQty", Type: "number" },
          { Name: "MaxQty", Column: "MaxQty", Type: "number" },
          { Name: "ReorderQty", Column: "ReorderQty", Type: "number" },
          { Name: "UniqueNoLabel", Column: "UniqueNoLabel", Type: "text" },
          {
            Name: "NoOfUniqueNoPerItem",
            Column: "NoOfUniqueNoPerItem",
            Type: "text",
          },
          { Name: "AllowDiscount", Column: "AllowDiscount", Type: "yesno" },
          { Name: "AllowPriceEdit", Column: "AllowPriceEdit", Type: "yesno" },
          { Name: "AllowTaxChange", Column: "AllowTaxChange", Type: "yesno" },
          {
            Name: "WalletTopUpAmount",
            Column: "WalletTopUpAmount",
            Type: "number",
          },
          {
            Name: "WalletTopUpSameAsRetailPrice",
            Column: "WalletTopUpSameAsRetailPrice",
            Type: "yesno",
          },
          {
            Name: "AllowUserToSpecifyRechargeAmountAtTimeOfBilling",
            Column: "AllowUserToSpecifyRechargeAmountAtTimeOfBilling",
            Type: "yesno",
          },
          {
            Name: customField1DisplayName,
            Column: "CustomField1",
            Type: "text",
          },
          {
            Name: customField2DisplayName,
            Column: "CustomField2",
            Type: "text",
          },
          {
            Name: customField3DisplayName,
            Column: "CustomField3",
            Type: "text",
          },
          {
            Name: customField4DisplayName,
            Column: "CustomField4",
            Type: "text",
          },
          {
            Name: customField5DisplayName,
            Column: "CustomField5",
            Type: "text",
          },
          {
            Name: customField6DisplayName,
            Column: "CustomField6",
            Type: "text",
          },
          { Name: "UOM", Column: "UOM", Type: "text" },
          { Name: "SecondaryUOM", Column: "SecondaryUOM", Type: "text" },
          {
            Name: "UOMToSecondaryUOMConversion",
            Column: "UOMToSecondaryUOMConversion",
            Type: "text",
          },
          { Name: "SalesUOM", Column: "SalesUOM", Type: "text" },
          { Name: "PurchaseUOM", Column: "PurchaseUOM", Type: "text" },
          { Name: "PromptForQty", Column: "PromptForQty", Type: "yesno" },
          { Name: "Favourite", Column: "Favourite", Type: "yesno" },
          { Name: "ShowOnline", Column: "OMNIEnabled", Type: "yesno" },
          { Name: "BOM", Column: "BOM", Type: "yesno" },
          {
            Name: "BOMManual",
            Column: "ManuallySpecifyProductConsumption",
            Type: "yesno",
          },
          {
            Name: "CanBeUsedAsConsumable",
            Column: "CanBeUsedAsConsumable",
            Type: "yesno",
          },
          {
            Name: "AssociateMembership",
            Column: "AssociateMembership",
            Type: "yesno",
          },
          { Name: "Membership", Column: "Membership", Type: "text" },
          {
            Name: "BatchTrackingBatchNoRequired",
            Column: "BatchTrackingBatchNoRequired",
            Type: "yesno",
          },
          {
            Name: "BatchTrackingMRPRequired",
            Column: "BatchTrackingMRPRequired",
            Type: "yesno",
          },
          {
            Name: "BatchTrackingMfgDateRequired",
            Column: "BatchTrackingMfgDateRequired",
            Type: "yesno",
          },
          {
            Name: "BatchTrackingExpDateRequired",
            Column: "BatchTrackingExpDateRequired",
            Type: "yesno",
          },
          {
            Name: "BatchTrackingDateFormat",
            Column: "BatchTrackingDateFormat",
            Type: "text",
          },
          { Name: "ParentSKU", Column: "ParentSKU", Type: "text" },
          {
            Name: "VariantOptionOneName",
            Column: "VariantOptionOneName",
            Type: "text",
          },
          {
            Name: "VariantOptionOneValue",
            Column: "VariantOptionOneValue",
            Type: "text",
          },
          {
            Name: "VariantOptionTwoName",
            Column: "VariantOptionTwoName",
            Type: "text",
          },
          {
            Name: "VariantOptionTwoValue",
            Column: "VariantOptionTwoValue",
            Type: "text",
          },
          {
            Name: "VariantOptionThreeName",
            Column: "VariantOptionThreeName",
            Type: "text",
          },
          {
            Name: "VariantOptionThreeValue",
            Column: "VariantOptionThreeValue",
            Type: "text",
          },
          { Name: "ProductID", Column: "key", Type: "key" },
          { Name: "", Column: "SecondaryUOMs", Type: "" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };

      if (
        this.props.user.user.Role === "Power User" ||
        this.props.user.user.Role === "Store Owner"
      ) {
        if (
          this.props.user.store &&
          this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
          this.props.user.store.NoOfDaysToExpire > 15
        ) {
          listPageSettings.Options.push({ Text: "Export", Type: "export" });
        }
        listPageSettings.Options.push({
          Text: "Product Import",
          Type: "import",
          ImportType: "product",
        });
        listPageSettings.Options.push({
          Text: "Product Import Log",
          Type: "log",
          LogType: "product",
        });
        listPageSettings.Options.push({
          Text: "Import Product Images",
          Type: "redirect",
          URL: "/home/imageupload/",
        });
        listPageSettings.Options.push({
          Text: "Quick Edit",
          Type: "redirect",
          URL: "/home/product/quickedit/",
        });
        listPageSettings.Options.push({
          Text: "Print Barcode",
          Type: "redirect",
          URL: "/home/barcodeprint/",
          State: { data: null, transactionType: "master" },
        });
      }
    }
    if (this.props.type === "cashregisterclosinglist") {
      listPageSettings = {
        Type: "cashregisterclosinglist",
        Title: "Cash Register Closing List",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          {
            Name: "Cash Register",
            Type: "multiple",
            Columns: [
              { Column: "CashRegisterCode" },
              { Column: "CashRegisterName" },
            ],
          },
          {
            Name: "Opening Details",
            Type: "multiple",
            Columns: [
              {
                Column: "OpeningTimeDisplay",
                Label: "Opening Time",
                Type: "text",
              },
              {
                Column: "OpeningBalance",
                Label: "Opening Balance",
                Type: "number",
              },
              {
                Column: "OpeningByUserName",
                Label: "Opening By",
                Type: "text",
              },
            ],
          },
          {
            Name: "Closing Details",
            Type: "multiple",
            Columns: [
              { Column: "ClosingTimeDisplay", Label: "Closing Time" },
              { Column: "ClosingRemarks", Label: "Closing Remarks" },
              { Column: "ClosingBy", Label: "Closing By" },
              {
                Column: "OpeningBalanceChangeReason",
                Label: "Opening Balance Change Reason",
              },
            ],
          },
          {
            Name: "Payment Details",
            Type: "",
            Columns: [
              { Column: "", Type: "cashregisterclosingpaymentdetails" },
            ],
          },
          {
            Name: "Cash Hand-over Details",
            Type: "multiple",
            Columns: [
              {
                Column: "AmountDeposited",
                Type: "number",
                Label: "Amount Deposited",
              },
              {
                Column: "DepositedToAccount",
                Type: "number",
                Label: "Account Deposited",
              },
            ],
          },
          {
            Name: "Status",
            Type: "",
            Columns: [{ Column: "Status", Type: "text" }],
          },
          {
            Name: "Revision History",
            Type: "multiple",
            Columns: [
              { Column: "Action", Label: "Action", Type: "text" },
              { Column: "ActionBy", Label: "Action By", Type: "text" },
              { Column: "ActionOnDisplay", Label: "Action Date", Type: "date" },
            ],
          },
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "CashRegisterName", CSS: "main" },
              {
                Column: "OpeningTimeDisplay",
                Label: "Opening Time",
                Type: "text",
              },
              {
                Column: "OpeningBalance",
                Label: "Opening Balance",
                Type: "number",
              },
              {
                Column: "OpeningByUserName",
                Label: "Opening By",
                Type: "text",
              },
              { Column: "", Type: "cashregisterclosingpaymentdetails" },
              { Column: "ClosingTimeDisplay", Label: "Closing Time" },
              { Column: "ClosingRemarks", Label: "Closing Remarks" },
              { Column: "ClosingBy", Label: "Closing By" },
              {
                Column: "OpeningBalanceChangeReason",
                Label: "Opening Balance Change Reason",
              },
              {
                Column: "AmountDeposited",
                Type: "number",
                Label: "Amount Deposited",
              },
              {
                Column: "DepositedToAccount",
                Type: "number",
                Label: "Account Deposited",
              },
              { Column: "Status", Type: "text" },
            ],
          },
        ],
        RowButtons: [
          // {ButtonType:"ImageButton",Type:"delete", Text:"Delete", Icon:"delete", Action:"delete"},
        ],
        Filters: [
          {
            Name: "Status",
            Column: "",
            Type: "dropdown",
            Value: "Open",
            Options: [
              { Value: "Open", Text: "Open" },
              { Value: "Closed", Text: "Closed" },
            ],
          },
          { Name: "Cashregister", Column: "", Type: "cashregister", Value: "" },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    if (this.props.type === "offers") {
      listPageSettings = {
        Type: "offers",
        Title: "Offers",
        API: "",
        SearchPlaceHolder: "Search by offer name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "offers",
          },
        ],
        Columns: [
          {
            Name: "Name",
            Type: "multiple",
            Columns: [{ Column: "Name" }, { Column: "Description" }],
          },
          {
            Name: "Details",
            Type: "multiple",
            Columns: [
              { Column: "ValidityFrom", Label: "Validity From", Type: "date" },
              { Column: "ValidityTo", Label: "Validity To", Type: "date" },
            ],
          },
          {
            Name: "Description",
            Type: "multiple",
            Columns: [
              { Column: "OfferType", Label: "Offer Type" },
              { Column: "PurchaseGroup", Label: "Purchase Group" },
              { Column: "PurchaseQty", Label: "Purchase Qty" },
              { Column: "FreeGroup", Label: "Free Group" },
              { Column: "FreeQty", Label: "Free Qty" },
            ],
          },
          {
            Name: "",
            Type: "multiple",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "main" },
              { Column: "OfferType", Label: "Offer Type" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "offers",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          {
            Name: "Description",
            Column: "Description",
            Type: "text",
            GeneralSearch: true,
          },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "coupons") {
      listPageSettings = {
        Type: "coupons",
        Title: "Coupons",
        API: "",
        SearchPlaceHolder: "Search by coupon name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "coupons",
          },
        ],
        Columns: [
          {
            Name: "Name",
            Type: "multiple",
            Columns: [{ Column: "Name" }, { Column: "Description" }],
          },
          {
            Name: "Details",
            Type: "multiple",
            Columns: [
              { Column: "ValidityFrom", Label: "Validity From", Type: "date" },
              { Column: "ValidityTo", Label: "Validity To", Type: "date" },
            ],
          },
          {
            Name: "Description",
            Type: "multiple",
            Columns: [
              { Column: "OfferType", Label: "Offer Type" },
              { Column: "PurchaseGroup", Label: "Purchase Group" },
              { Column: "PurchaseQty", Label: "Purchase Qty" },
              { Column: "FreeGroup", Label: "Free Group" },
              { Column: "FreeQty", Label: "Free Qty" },
            ],
          },
          {
            Name: "",
            Type: "multiple",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "main" },
              { Column: "OfferType", Label: "Offer Type" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "coupons",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          {
            Name: "Description",
            Column: "Description",
            Type: "text",
            GeneralSearch: true,
          },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "bom") {
      listPageSettings = {
        Type: "bom",
        Title: "BOM",
        API: "getBOMs",
        SearchPlaceHolder: "Search by BOM name or product name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "bom",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          // {Name:'Name',Type:'field', Columns:[{Column:'BOMName'}]},
          {
            Name: "Product SKU",
            Type: "field",
            Columns: [{ Column: "Product.SKU" }],
          },
          {
            Name: "Product Name",
            Type: "field",
            Columns: [{ Column: "Product.Name" }],
          },
          {
            Name: "",
            Type: "multiple",
            ShowInMobile: true,
            Columns: [
              // {Column:'BOMName' , CSS:"main"},
              { Column: "Product.SKU" },
              { Column: "Product.Name" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "bom",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          // {Name:"Name", Column:"Name", Type:"text", GeneralSearch: true},
          {
            Name: "Product",
            Column: "Product.Name",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "SKU",
            Column: "Product.SKU",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [
          { Text: "Export", Type: "export" },
          { Text: "Import", Type: "import", ImportType: "bom" },
          { Text: "Import Log", Type: "log", LogType: "bom" },
        ],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "category") {
      listPageSettings = {
        Type: "category",
        Title: "Category",
        API: "getProductCategories",
        SearchPlaceHolder: "Search by category name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "category",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Sequence",
            Type: "field",
            Columns: [{ Column: "Sequence" }],
          },
          {
            Name: "Color",
            Type: "field",
            Columns: [{ Column: "Color", Type: "color" }],
          },
          {
            Name: "Image",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Online",
            Type: "field",
            Columns: [{ Column: "OMNIEnabled", Type: "yesno" }],
          },
          {
            Name: "Image",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "bold" },
              { Column: "Sequence", Lable: "Sequence no" },
              { Column: "OMNIEnabled", Type: "yesno", Label: "Show online" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "category",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          {
            Name: "",
            Column: "",
            QueryType: "must",
            Value: { term: { "ParentID.keyword": "" } },
          },
        ],
        Options: [{ Text: "Export", Type: "export" }],
        ExportColumns: [
          { Name: "Category", Column: "Name", Type: "text" },
          { Name: "Sequence", Column: "Sequence", Type: "text" },
          { Name: "Online", Column: "Online", Type: "yesno" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "subcategory") {
      listPageSettings = {
        Type: "subcategory",
        Title: "Sub Category",
        API: "getProductCategories",
        SearchPlaceHolder: "Search by sub category name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "subcategory",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          { Name: "Category", Type: "field", Columns: [{ Column: "Parent" }] },
          {
            Name: "Sequence",
            Type: "field",
            Columns: [{ Column: "Sequence" }],
          },
          {
            Name: "Color",
            Type: "field",
            Columns: [{ Column: "Color", Type: "color" }],
          },
          {
            Name: "Image",
            Type: "field",
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Online",
            Type: "field",
            Columns: [{ Column: "OMNIEnabled", Type: "yesno" }],
          },
          {
            Name: "Image",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Image", Type: "image" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "bold" },
              { Column: "Parent", Label: "Category" },
              { Column: "Sequence", Lable: "Sequence no" },
              { Column: "OMNIEnabled", Type: "yesno", Label: "Show online" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "subcategory",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          {
            Name: "Category",
            Column: "ParentID",
            Type: "category",
            GeneralSearch: true,
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
          {
            Name: "",
            Column: "",
            QueryType: "must_not",
            Value: { term: { "ParentID.keyword": "" } },
          },
        ],
        Options: [{ Text: "Export", Type: "export" }],
        ExportColumns: [
          { Name: "Sub Category", Column: "Name", Type: "text" },
          { Name: "Category", Type: "field", Column: "Parent" },
          { Name: "Sequence", Column: "Sequence", Type: "text" },
          { Name: "Online", Column: "Online", Type: "yesno" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "brand") {
      listPageSettings = {
        Type: "brand",
        Title: "Brand",
        API: "getProductBrands",
        SearchPlaceHolder: "Search by brand",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "brand",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          { Name: "Brand", Type: "field", Columns: [{ Column: "Brand" }] },
          {
            Name: "Brand",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Brand" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "brand",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Brand", Column: "Brand", Type: "text", GeneralSearch: true },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "tax") {
      listPageSettings = {
        Type: "tax",
        Title: "Taxes",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "tax",
          },
        ],
        Columns: [
          {
            Name: "Tax Group",
            Type: "field",
            Columns: [{ Column: "TaxGroup" }],
          },
          { Name: "Taxes", Type: "taxes" },
          {
            Name: "Tax Group",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "TaxGroup" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "tax",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "modifier") {
      listPageSettings = {
        Type: "modifier",
        Title: "Modifiers",
        API: "getModifiers",
        SearchPlaceHolder: "Search by modifier name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "modifier",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Name",
            Type: "field",
            Columns: [{ Column: "ModifierName" }],
          },
          {
            Name: "Group",
            Type: "field",
            Columns: [{ Column: "ModifierGroup" }],
          },
          {
            Name: "Price",
            Type: "field",
            Columns: [{ Column: "ModifierPrice" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "ModifierName", CSS: "bold" },
              { Column: "ModifierGroup", Label: "Modifier group" },
              { Column: "ModifierPrice", Label: "Price" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "modifier",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Name",
            Column: "ModifierName",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "Group", Column: "ModifierGroup", Type: "text" },
          { Name: "Price", Column: "ModifierPrice", Type: "number" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [
          {
            Text: "Modifier Groups",
            Type: "popup",
            PopupType: "modifiergroup",
          },
        ],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "productgroup") {
      listPageSettings = {
        Type: "productgroup",
        Title: "Product Group",
        API: "getProductTags",
        SearchPlaceHolder: "Search by product group name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "productgroup",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Description",
            Type: "field",
            Columns: [{ Column: "Description" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name", CSS: "bold" },
              { Column: "Description" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "productgroup",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "campaign") {
      listPageSettings = {
        Type: "campaign",
        Title: "Campaigns",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "campaign",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Type",
            Type: "multiple",
            Columns: [
              { Column: "Type" },
              { Column: "StartDate", Type: "date" },
              { Column: "SlotDisplay" },
            ],
          },
          {
            Name: "Stores",
            Type: "field",
            Columns: [{ Column: "StoresDisplay" }],
          },
          {
            Name: "Last Run",
            Type: "multiple",
            Columns: [
              { Column: "Message" },
              { Column: "Status", Label: "Status" },
              { Column: "NoOfCustomers", Label: "Customer Count" },
              { Column: "MessageLength", Label: "Message Length" },
              { Column: "NoOfSMSSent", Label: "SMS Sent" },
            ],
          },
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name" },
              { Column: "Type" },
              { Column: "StartDate", Type: "date" },
              { Column: "Status", Label: "Status" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "campaign",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "membership") {
      listPageSettings = {
        Type: "membership",
        Title: "Membership",
        API: "getMemberships",
        SearchPlaceHolder: "Search by membership name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "membership",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Description",
            Type: "field",
            Columns: [{ Column: "Description" }],
          },
          {
            Name: "Discount",
            Type: "field",
            Columns: [{ Column: "Discount" }],
          },
          {
            Name: "Validity",
            Type: "field",
            Columns: [{ Column: "Validity" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name" },
              { Column: "Description" },
              { Column: "Discount" },
              { Column: "Validity" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "membership",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "saleschannel") {
      listPageSettings = {
        Type: "saleschannel",
        Title: "Sales Channel",
        API: "",
        SearchPlaceHolder: "Search by name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "saleschannel",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Name" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "saleschannel",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "Name", Column: "Name", Type: "text", GeneralSearch: true },
          { Name: "Description", Column: "Description", Type: "text" },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [],
        ExportColumns: [],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "loyaltytype") {
      listPageSettings = {
        Type: "loyaltytype",
        Title: "Loyalty Type",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/loyalty/new",
          },
          // {ButtonType:"ImageButton",Type:"filter", Text:"Filter", Icon:"filter", Action:"filter"},
        ],
        Columns: [
          {
            Name: "Loyalty Type",
            Type: "field",
            Columns: [{ Column: "LoyaltyType" }],
          },
          {
            Name: "Details",
            Type: "multiple",
            Columns: [
              { Column: "RangeFrom", Label: "Range From" },
              { Column: "RangeTo", Label: "Range To" },
              { Column: "PurchaseAmount", Label: "Purchase Amount" },
              {
                Column: "PurchasePointValidity",
                Label: "Purchase Point Validity",
              },
              { Column: "MinPurchaseValue", Label: "Min Purchase Value" },
              {
                Column: "PurchasePointMax",
                Label: "Purchase Point Max Points",
              },
              { Column: "BirthdayPoint", Label: "Birthday Points" },
              { Column: "Validity", Label: "Birthday Points Validity" },
              { Column: "AnniversaryPoint", Label: "Anniversary Points" },
              {
                Column: "AnniversaryPointValidity",
                Label: "Anniversary Points Validity",
              },
            ],
          },
          {
            Name: "Details",
            ShowInMobile: true,
            Type: "multiple",
            Columns: [
              { Column: "LoyaltyType" },
              { Column: "RangeFrom", Label: "Range From" },
              { Column: "RangeTo", Label: "Range To" },
              { Column: "PurchaseAmount", Label: "Purchase Amount" },
              {
                Column: "PurchasePointValidity",
                Label: "Purchase Point Validity",
              },
              { Column: "MinPurchaseValue", Label: "Min Purchase Value" },
              {
                Column: "PurchasePointMax",
                Label: "Purchase Point Max Points",
              },
              { Column: "BirthdayPoint", Label: "Birthday Points" },
              { Column: "Validity", Label: "Birthday Points Validity" },
              { Column: "AnniversaryPoint", Label: "Anniversary Points" },
              {
                Column: "AnniversaryPointValidity",
                Label: "Anniversary Points Validity",
              },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/loyalty/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "smstemplate") {
      listPageSettings = {
        Type: "smstemplate",
        Title: "SMS Template",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "redirect",
            URL: "/home/settings/sms/new/",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
        ],
        Columns: [
          { Name: "Name", Type: "field", Columns: [{ Column: "Name" }] },
          { Name: "SMS Type", Type: "field", Columns: [{ Column: "SMSType" }] },
          { Name: "Module", Type: "field", Columns: [{ Column: "Module" }] },
          {
            Name: "TransactionType",
            Type: "field",
            Columns: [{ Column: "TransactionType" }],
          },
          { Name: "Status", Type: "field", Columns: [{ Column: "Status" }] },
          {
            Name: "Template",
            Type: "field",
            Columns: [{ Column: "SMSTemplate" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Name" },
              { Column: "SMSType" },
              { Column: "Module" },
              { Column: "TransactionType" },
              { Column: "Status" },
              { Column: "SMSTemplate" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "redirect",
            URL: "/home/settings/sms/edit/",
            URLQueryString: "key",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "employeelist") {
      listPageSettings = {
        Type: "employeelist",
        Title: "Staff",
        API: "getEmployees",
        SearchPlaceHolder:
          "Search by employee name, code, email id, contact no",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "employee",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Employee Code",
            Type: "field",
            Columns: [{ Column: "EmployeeCode" }],
          },
          {
            Name: "Name",
            Type: "concatenate",
            Columns: [
              { Column: "EmployeeFirstName" },
              { Column: "EmployeeLastName" },
            ],
          },
          { Name: "Email ID", Type: "field", Columns: [{ Column: "EmailID" }] },
          { Name: "Phone No", Type: "field", Columns: [{ Column: "PhoneNo" }] },
          {
            Name: "Address",
            Type: "multiple",
            Columns: [
              { Column: "Address1" },
              { Column: "Address2" },
              { Column: "City" },
              { Column: "State" },
              { Column: "PostalCode" },
              { Column: "Country" },
            ],
          },
          // {Name:'Biometric ID',  Type:'field',Columns:[{Column:'BiometricID'}]},
          {
            Name: "System Access",
            Type: "multiple",
            Columns: [{ Column: "Role" }, { Column: "StoresDisplay" }],
          },
          {
            Name: "Employee Code",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "EmployeeCode" },
              { Column: "EmployeeFirstName" },
              { Column: "EmailID" },
              { Column: "PhoneNo" },
              { Column: "Role", Label: "Role" },
              { Column: "StoresDisplay", Label: "Store" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "employee",
          },
          {
            ButtonType: "ImageButton",
            Type: "lock",
            Text: "System Access",
            Icon: "lock",
            Action: "popup",
            PopupType: "employeesystemaccess",
          },
          {
            ButtonType: "ImageButton",
            Type: "list",
            Text: "Service Mapping",
            Icon: "list",
            Action: "popup",
            PopupType: "employeeservicemapping",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          {
            Name: "Store",
            Column: "StoreID.keyword",
            Type: "stores",
            Value: this.props.user.store.key,
          },
          {
            Name: "First Name",
            Column: "EmployeeFirstName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Last Name",
            Column: "EmployeeLastName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "EmailID",
            Column: "EmailID",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Phone No",
            Column: "PhoneNo",
            Type: "text",
            GeneralSearch: true,
          },
          { Name: "Status", Column: "Status", Type: "status", Value: "active" },
        ],
        Options: [{ Text: "Export", Type: "export", ImportType: "staff" }],
        ExportColumns: [
          { Name: "Employee Code", Type: "text", Column: "EmployeeCode" },
          { Name: "First Name", Type: "text", Column: "EmployeeFirstName" },
          { Name: "Last Name", Type: "text", Column: "EmployeeLastName" },
          { Name: "Email ID", Type: "text", Column: "EmailID" },
          { Name: "Phone No", Type: "text", Column: "PhoneNo" },
          { Name: "Role", Type: "text", Column: "Role" },
          { Name: "Store", Type: "text", Column: "StoresDisplay" },
          { Name: "UID", Type: "text", Column: "UID" },
        ],
      };
    }
    if (this.props.type === "attendance") {
      listPageSettings = {
        Type: "attendance",
        Title: "Attendance",
        API: "getAttendance",
        SearchPlaceHolder: "Search by employee name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "Capture Attendance",
            Icon: "add",
            Action: "popup",
            PopupType: "captureattendance",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Employee",
            Type: "field",
            Columns: [{ Column: "EmployeeDisplay" }],
          },
          {
            Name: "Date",
            Type: "field",
            Columns: [{ Column: "Date", Type: "date" }],
          },
          {
            Name: "Status",
            Type: "field",
            Columns: [{ Column: "AttendanceType" }],
          },
          {
            Name: "In Time",
            Type: "field",
            Columns: [{ Column: "InTimeDisplay" }],
          },
          {
            Name: "Out Time",
            Type: "field",
            Columns: [{ Column: "OutTimeDisplay" }],
          },
          { Name: "Remarks", Type: "field", Columns: [{ Column: "Remarks" }] },
          {
            Name: "Employee",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "EmployeeDisplay" },
              { Column: "date", Type: "date" },
              { Column: "AttendanceType" },
              { Column: "InTimeDisplay", Label: "In Time" },
              { Column: "OutTimeDisplay", Label: "Out Time" },
              { Column: "Remarks" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "captureattendance",
          },
          // {ButtonType:"ImageButton",Type:"delete", Text:"Delete", Icon:"delete", Action:"delete"},
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "StoreID", Column: "StoreID", Type: "storeID" },
          {
            Name: "Employee",
            Column: "Employee.label",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "Date",
            Column: "Date",
            Type: "daterange",
            Value: {
              // FromDate:new Date().toISOString().split('T')[0],
              // ToDate:new Date().toISOString().split('T')[0]
              FromDate: "",
              ToDate: "",
            },
          },
          {
            Name: "Status",
            Column: "AttendanceType",
            Type: "attendanceType",
            Value: "",
          },
        ],
        Options: [{ Text: "Export", Type: "export", ImportType: "attendance" }],
        ExportColumns: [
          { Name: "Employee", Column: "EmployeeDisplay", Type: "text" },
          { Name: "Date", Type: "text", Column: "Date" },
          { Name: "Status", Type: "text", Column: "AttendanceType" },
          { Name: "In Time", Type: "text", Column: "InTimeDisplay" },
          { Name: "Out Time", Type: "text", Column: "OutTimeDisplay" },
          { Name: "Remarks", Type: "text", Column: "Remarks" },
          { Name: "", Type: "", Column: "InTime" },
          { Name: "", Type: "", Column: "OutTime" },
          { Name: "", Type: "", Column: "Employee" },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "expense") {
      listPageSettings = {
        Type: "expense",
        Title: "Expenses",
        API: "getExpenses",
        SearchPlaceHolder: "Search by party name",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "expense",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Expense No",
            Type: "multiple",
            Columns: [
              { Column: "ExpenseNo" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              { Column: "StatusDisplay", Type: "badge", CSS: "green" },
            ],
          },
          {
            Name: "Expense Date",
            Type: "field",
            Columns: [{ Column: "ExpenseDate", Type: "date" }],
          },
          {
            Name: "Party",
            Type: "concatenate",
            Columns: [
              { Column: "Party.PartyFirstName" },
              { Column: "Party.PartyLastName" },
            ],
          },
          {
            Name: "Expense Category",
            Type: "field",
            Columns: [{ Column: "ExpenseCategory" }],
          },
          {
            Name: "Reference Number",
            Type: "field",
            Columns: [{ Column: "RefNo" }],
          },
          { Name: "Notes", Type: "field", Columns: [{ Column: "Note" }] },
          {
            Name: "Payment Mode",
            Type: "field",
            Columns: [{ Column: "PaymentType" }],
          },
          {
            Name: "Payment Remarks",
            Type: "field",
            Columns: [{ Column: "PaymentRemark" }],
          },
          {
            Name: "Amount",
            Type: "field",
            Columns: [{ Column: "Amount", Type: "number" }],
          },
          {
            Name: "Expense No",
            ShowInMobile: true,
            Type: "multiple",
            Columns: [
              { Column: "ExpenseNo" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              { Column: "StatusDisplay", Type: "badge", CSS: "green" },
              { Column: "Party.PartyFirstName" },
              { Column: "Party.PartyLastName" },
              { Column: "ExpenseCategory" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "expense",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Cancel",
            Icon: "delete",
            Action: "cancel",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "StoreID", Column: "StoreID", Type: "storeID" },
          {
            Name: "Party",
            Column: "Party.PartyName",
            Type: "text",
            GeneralSearch: true,
          },
          {
            Name: "ExpenseDate",
            Column: "ExpenseDate",
            Type: "daterange",
            Value: {
              // FromDate:new Date().toISOString().split('T')[0],
              // ToDate:new Date().toISOString().split('T')[0]
              FromDate: "",
              ToDate: "",
            },
          },
          {
            Name: "Expense Category",
            Column: "ExpenseCategory",
            Type: "text",
            Value: "",
          },
          { Name: "Expense No", Column: "ExpenseNo", Type: "text", Value: "" },
          {
            Name: "Reference Number",
            Column: "RefNo",
            Type: "text",
            Value: "",
          },
          {
            Name: "Status",
            Column: "Status",
            Type: "expensestatus",
            Value: "",
          },
        ],
        Options: [{ Text: "Export", Type: "export", ImportType: "expense" }],
        ExportColumns: [
          { Name: "Expense No", Column: "ExpenseNo", Type: "text" },
          { Name: "Expense Date", Column: "ExpenseDate", Type: "date" },
          { Name: "Party Name", Column: "Party.PartyFirstName", Type: "text" },
          {
            Name: "Contact Person Name",
            Column: "Party.PartyLastName",
            Type: "text",
          },
          { Name: "Expense Category", Type: "text", Column: "ExpenseCategory" },
          { Name: "Reference Number", Type: "text", Column: "RefNo" },
          { Name: "Notes", Type: "text", Column: "Note" },
          { Name: "Payment Mode", Type: "text", Column: "PaymentType" },
          { Name: "Payment Remarks", Type: "text", Column: "PaymentRemark" },
          { Name: "Amount", Type: "number", Column: "Amount" },
          { Name: "Status", Type: "text", Column: "Status" },
          {
            Name: "CancellationReason",
            Type: "text",
            Column: "CancellationReason",
          },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "expensecategory") {
      // getExpenseCategories
      listPageSettings = {
        Type: "expensecategory",
        Title: "Expense Category",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "expensecategory",
          },
        ],
        Columns: [
          {
            Name: "Expense Category",
            Type: "field",
            Columns: [{ Column: "ExpenseCategory" }],
          },
          {
            Name: "Expense Category",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "ExpenseCategory" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "expensecategory",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "account") {
      // getExpenseCategories
      listPageSettings = {
        Type: "account",
        Title: "Accounts",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "account",
          },
        ],
        Columns: [
          { Name: "Account", Type: "field", Columns: [{ Column: "Name" }] },
          {
            Name: "Account",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Name" }],
          },

          // {Name:'Stores',  Type:'field',Columns:[{Column:'StoresDisplay'}]},
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "account",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
      };
    }
    if (this.props.type === "accountmanagement") {
      listPageSettings = {
        Type: "accountmanagement",
        Title: "Account Management",
        API: "getAccountManagement",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "accountmanagement",
          },
          {
            ButtonType: "ImageButton",
            Type: "filter",
            Text: "Filter",
            Icon: "filter",
            Action: "filter",
          },
          {
            ButtonType: "ImageButton",
            Type: "option",
            Text: "Options",
            Icon: "option",
            Action: "options",
          },
        ],
        Columns: [
          {
            Name: "Date",
            Type: "multiple",
            Columns: [
              { Column: "TransactionDate", Type: "date" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
            ],
          },
          {
            Name: "From Account",
            Type: "field",
            Columns: [{ Column: "FromAccount" }],
          },
          {
            Name: "To Account",
            Type: "field",
            Columns: [{ Column: "ToAccount" }],
          },
          {
            Name: "Payment Mode",
            Type: "field",
            Columns: [{ Column: "PaymentType" }],
          },
          {
            Name: "Amount",
            Type: "field",
            Columns: [{ Column: "Amount", type: "number" }],
          },
          {
            Name: "Remark",
            Type: "field",
            Columns: [{ Column: "PaymentRemark" }],
          },
          {
            Name: "Date",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "TransactionDate", Type: "date" },
              { Column: "CancellationReason", Type: "badge", CSS: "red" },
              { Column: "FromAccount", Label: "From" },
              { Column: "ToAccount", Label: "To" },
              { Column: "PaymentType" },
              { Column: "Amount", Label: "Amount" },
            ],
          },
        ],
        RowButtons: [
          // {ButtonType:"ImageButton",Type:"edit", Text:"Edit", Icon:"edit", Action:"popup", PopupType:"accountmanagement"},
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Cancel",
            Icon: "delete",
            Action: "cancel",
          },
        ],
        Filters: [
          {
            Name: "RegistrationID",
            Column: "RegistrationID",
            Type: "registrationid",
          },
          { Name: "StoreID", Column: "StoreID", Type: "storeID" },
          {
            Name: "Date",
            Column: "TransactionDate",
            Type: "daterange",
            Value: {
              FromDate: "",
              ToDate: "",
            },
          },
          {
            Name: "Payment Mode",
            Column: "PaymentTypeID",
            Type: "paymenttypeexpense",
            Value: "",
          },
          {
            Name: "From Account",
            Column: "FromAccountID",
            Type: "account",
            Value: "",
          },
          {
            Name: "To Account",
            Column: "ToAccountID",
            Type: "account",
            Value: "",
          },
        ],
        Options: [{ Text: "Export", Type: "export", ImportType: "expense" }],
        ExportColumns: [
          { Name: "Date", Column: "TransactionDate", Type: "date" },
          { Name: "From Account", Column: "FromAccount", Type: "text" },
          { Name: "To Account", Column: "ToAccount", Type: "text" },
          { Name: "Payment Mode", Column: "PaymentType", Type: "text" },
          { Name: "Amount", Type: "number", Column: "Amount" },
          { Name: "Remark", Type: "text", Column: "Remark" },
          {
            Name: "Cancellation Reason",
            Type: "text",
            Column: "CancellationReason",
          },
        ],
        Sort: [{ Column: "ActionOn", Order: "desc" }],
      };
    }
    if (this.props.type === "paymenttypes") {
      listPageSettings = {
        Type: "paymenttypes",
        Title: "Payment Types",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "paymenttypes",
          },
        ],
        Columns: [
          {
            Name: "Payment Type",
            Type: "field",
            Columns: [{ Column: "PaymentType" }],
          },
          {
            Name: "Sequence",
            Type: "field",
            Columns: [{ Column: "SequenceNo" }],
          },
          {
            Name: "",
            Type: "multiple",
            Columns: [
              { Column: "ShowInSales", Type: "yesno", Label: "Show in sales" },
              {
                Column: "ShowInExpense",
                Type: "yesno",
                Label: "Show in expense",
              },
            ],
          },
          {
            Name: "",
            Type: "multiple",
            ShowInMobile: true,
            Columns: [
              { Column: "PaymentType" },
              { Column: "SequenceNo", Label: "Sequence" },
              { Column: "ShowInSales", Type: "yesno", Label: "Show in sales" },
              {
                Column: "ShowInExpense",
                Type: "yesno",
                Label: "Show in expense",
              },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "paymenttypes",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    if (this.props.type === "customroles") {
      listPageSettings = {
        Type: "customroles",
        Title: "Roles",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "customroles",
          },
        ],
        Columns: [
          { Name: "Role", Type: "field", Columns: [{ Column: "Role" }] },
          {
            Name: "Role",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Role" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "customroles",
          },
          // {ButtonType:"ImageButton",Type:"delete", Text:"Delete", Icon:"delete", Action:"delete"},
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    if (this.props.type === "moduleconfig") {
      listPageSettings = {
        Type: "moduleconfig",
        Title: "Module Configuration",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "moduleconfig",
          },
        ],
        Columns: [
          { Name: "Module", Type: "field", Columns: [{ Column: "Module" }] },
          {
            Name: "Transaction Type",
            Type: "field",
            Columns: [{ Column: "TransactionType" }],
          },
          {
            Name: "Numbering Format",
            Type: "field",
            Columns: [{ Column: "NumberingFormatDisplay" }],
          },
          {
            Name: "",
            Type: "field",
            ShowInMobile: true,
            Columns: [
              { Column: "Module" },
              { Column: "TransactionType" },
              { Column: "NumberingFormatDisplay" },
            ],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "moduleconfig",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    if (this.props.type === "transactionalsmstemplates") {
      listPageSettings = {
        Type: "transactionalsmstemplates",
        Title: "Transactional SMS Template",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "transactionalsmstemplates",
          },
        ],
        Columns: [
          {
            Name: "Template Name",
            Type: "field",
            Columns: [{ Column: "Name" }],
          },
          { Name: "Module", Type: "field", Columns: [{ Column: "Module" }] },
          {
            Name: "Transaction Type",
            Type: "field",
            Columns: [{ Column: "TransactionType" }],
          },
          {
            Name: "SMS Content",
            Type: "field",
            Columns: [{ Column: "SMSTemplate" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Name" }, { Column: "SMSTemplate" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "transactionalsmstemplates",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    if (this.props.type === "promotionalsmstemplates") {
      listPageSettings = {
        Type: "promotionalsmstemplates",
        Title: "SMS Template",
        API: "",
        SearchPlaceHolder: "",
        HeaderButtons: [
          {
            ButtonType: "Button",
            Type: "primary",
            Text: "New",
            Icon: "add",
            Action: "popup",
            PopupType: "promotionalsmstemplates",
          },
        ],
        Columns: [
          {
            Name: "Template Name",
            Type: "field",
            Columns: [{ Column: "Name" }],
          },
          {
            Name: "SMS Content",
            Type: "field",
            Columns: [{ Column: "SMSTemplate" }],
          },
          {
            Name: "Name",
            Type: "field",
            ShowInMobile: true,
            Columns: [{ Column: "Name" }, { Column: "SMSTemplate" }],
          },
        ],
        RowButtons: [
          {
            ButtonType: "ImageButton",
            Type: "edit",
            Text: "Edit",
            Icon: "edit",
            Action: "popup",
            PopupType: "promotionalsmstemplates",
          },
          {
            ButtonType: "ImageButton",
            Type: "delete",
            Text: "Delete",
            Icon: "delete",
            Action: "delete",
          },
        ],
        Filters: [],
        Options: [],
        ExportColumns: [],
        Sort: [],
      };
    }
    return listPageSettings;
  }

  loadListPage(itemsCountPerPage, listPageSettings) {
    let configFilters = this.props.getPageFilters(listPageSettings.Type);
    let searchText = this.state.searchText;
    let recordFrom = this.state.recordFrom;
    let activePage = this.state.activePage;
    // console.log('configFilters',configFilters)
    if (
      configFilters &&
      configFilters.PageFilters &&
      configFilters.PageFilters.length > 0
    ) {
      listPageSettings.Filters = configFilters.PageFilters;
      searchText = configFilters.SearchText;
      recordFrom = configFilters.RecordFrom ? configFilters.RecordFrom : 0;
      activePage = configFilters.ActivePage ? configFilters.ActivePage : 1;
    }
    let rowButtonWidth = 50 * listPageSettings.RowButtons.length;
    this.setState({
      listPageSettings: listPageSettings,
      rowButtonWidth: rowButtonWidth + "px",
      searchText: searchText,
      recordFrom: recordFrom,
      activePage: activePage,
      itemsCountPerPage: itemsCountPerPage,
    });
    this.getData(
      searchText,
      listPageSettings.Filters,
      listPageSettings.Sort,
      recordFrom,
      itemsCountPerPage,
      listPageSettings.API
    );
  }

  async deleteRecord() {
    let selectedRow = this.state.selectedRow;
    this.setState({ deleting: true });
    if (selectedRow) {
      if (this.props.type === "omnisliders") {
        await this.props.deleteCarousel(selectedRow.key);
        this.props.getToastr("Slider deleted successfully");
      }
      if (this.props.type === "omnipages") {
        await this.props.deleteOmniPage(selectedRow.key);
        this.props.getToastr("Page deleted successfully");
      }
      if (this.props.type === "omnihomesections") {
        let omniSettings = await this.props.getOmniSettings(
          this.props.user.user.RegistrationID
        );
        console.log("omniSettings", omniSettings);
        let omniHomeSections = omniSettings.hasOwnProperty("HomeSections")
          ? omniSettings.HomeSections
          : [];
        if (omniHomeSections.length > 0 && Number(selectedRow.key) > -1) {
          omniSettings.HomeSections.splice(Number(selectedRow.key), 1);
          await this.props.saveOmniSettings(omniSettings);
        }
        this.props.getToastr("Home section deleted successfully");
      }
      if (this.props.type === "omnimenus") {
        let omniSettings = await this.props.getOmniSettings(
          this.props.user.user.RegistrationID
        );
        console.log("omniSettings", omniSettings);
        let omniMenus = omniSettings.hasOwnProperty("Menus")
          ? omniSettings.Menus
          : [];
        if (omniMenus.length > 0 && Number(selectedRow.key) > -1) {
          omniSettings.Menus.splice(Number(selectedRow.key), 1);
          await this.props.saveOmniSettings(omniSettings);
        }
        this.props.getToastr("Menu deleted successfully");
      }
      if (this.props.type === "expensecategory") {
        await this.props.deleteExpenseCategories(selectedRow.key);
        this.props.getToastr("Expense category deleted successfully");
      }
      if (this.props.type === "account") {
        await this.props.deleteAccount(selectedRow.key);
        this.props.getToastr("Account deleted successfully");
      }
      if (this.props.type === "customer") {
        // console.log('delete customer')
        await this.props.deleteCustomer(selectedRow.key);
        this.props.getToastr("Customer deleted successfully");
      } else if (this.props.type === "vendor") {
        await this.props.deleteVendor(selectedRow.key);
        this.props.getToastr("Vendor deleted successfully");
      } else if (this.props.type === "store") {
        await this.props.deleteStore(selectedRow.key);
        this.props.getToastr("Store deleted successfully");
      } else if (this.props.type === "products") {
        await this.props.deleteProduct(selectedRow.key);
        this.props.getToastr("Product deleted successfully");
      } else if (this.props.type === "itemnotfoundinmastercatalogue") {
        await this.props.deleteProduct(selectedRow.key);
        this.props.getToastr("Product deleted successfully");
      } else if (this.props.type === "product") {
        await this.props.deleteProduct(selectedRow.key);
        this.props.getToastr("Product deleted successfully");
      } else if (this.props.type === "category") {
        await this.props.deleteProductCategory(selectedRow.key);
        this.props.getToastr("Category deleted successfully");
      } else if (this.props.type === "subcategory") {
        await this.props.deleteProductCategory(selectedRow.key);
        this.props.getToastr("Sub category deleted successfully");
      } else if (this.props.type === "brand") {
        await this.props.deleteProductBrand(selectedRow.key);
        this.props.getToastr("Brand deleted successfully");
      } else if (this.props.type === "modifier") {
        await this.props.deleteModifiers(selectedRow.key);
        this.props.getToastr("Modifier deleted successfully");
      } else if (this.props.type === "productgroup") {
        await this.props.deleteProductTag(selectedRow.key);
        this.props.getToastr("Product group deleted successfully");
      } else if (this.props.type === "tax") {
        await this.props.deleteProductTax(selectedRow.key);
        this.props.getToastr("Tax deleted successfully");
      } else if (this.props.type === "bom") {
        await this.props.deleteBOMs(selectedRow.key);
        this.props.getToastr("BOM deleted successfully");
      } else if (this.props.type === "campaign") {
        await this.props.deleteCampaign(selectedRow.key);
        this.props.getToastr("Campaign deleted successfully");
      } else if (this.props.type === "membership") {
        await this.props.deleteMembership(selectedRow.key);
        this.props.getToastr("Membership deleted successfully");
      } else if (this.props.type === "saleschannel") {
        await deleteSalesChannel(selectedRow.key, this.props.user.user);
        this.props.getToastr("Sales channel deleted successfully");
      } else if (
        this.props.type === "offers" ||
        this.props.type === "coupons"
      ) {
        await this.props.deleteOffer(selectedRow.key);
        this.props.getToastr("Record deleted successfully");
      } else if (this.props.type === "survey") {
        await this.props.deleteSurvey(selectedRow.key);
        this.props.getToastr("Survey deleted successfully");
      } else if (this.props.type === "smstemplate") {
        await this.props.deleteSMSTemplate(selectedRow.key);
        this.props.getToastr("SMS template deleted successfully");
      } else if (this.props.type === "loyaltytype") {
        await this.props.deleteLoyaltyType(selectedRow.key);
        this.props.getToastr("Loyalty type deleted successfully");
      } else if (this.props.type === "employeelist") {
        if (selectedRow.Role !== "" && selectedRow.Role === "Power User") {
          let powerUsers = this.state.list.filter(
            (f) => !f.IsDeleted && f.Role === "Power User"
          );
          // console.log("powerUsers", JSON.stringify(powerUsers));
          if (powerUsers && powerUsers.length > 0) {
            let ctr = powerUsers.filter((f) => f.key !== selectedRow.key)
              .length;
            if (ctr === 0) {
              alert("Power User cannot be deleted");
              return;
            }
          }
        }
        await this.props.deleteEmployee(selectedRow.key);
        this.props.getToastr("Employee deleted successfully");
      } else if (this.props.type === "paymenttypes") {
        await this.props.deletePaymentType(selectedRow.key);
        this.props.getToastr("Payment type deleted successfully");
      } else if (this.props.type === "moduleconfig") {
        await this.props.deleteTransactionType(selectedRow.key);
        this.props.getToastr("Transaction type deleted successfully");
      } else if (
        this.props.type === "transactionalsmstemplates" ||
        this.props.type == "promotionalsmstemplates"
      ) {
        await this.props.deleteSMSTemplate(selectedRow.key);
        this.props.getToastr("Template deleted successfully");
      } else if (this.props.type === "cashregisterclosinglist") {
        await this.props.deleteCashRegisterOpening(
          selectedRow.key,
          selectedRow
        );
        this.props.getToastr("Deleted successfully");
      }

      this.setState({
        showDelete: false,
        selectedRow: null,
        deleting: false,
        loading: true,
      });
      setTimeout(
        function() {
          //Start the timer
          this.getData(
            this.state.searchText,
            this.state.listPageSettings.Filters,
            this.state.listPageSettings.Sort,
            this.state.recordFrom,
            this.state.itemsCountPerPage,
            this.state.listPageSettings.API
          );
        }.bind(this),
        3000
      );
      // console.log(selectedRow.key)
    }
  }

  async cancelRecord() {
    if (this.state.cancellationReason === "") {
      this.setState({
        cancellationReasonError: "Please provide cancellation reason",
      });
      return;
    }
    let selectedRow = this.state.selectedRow;
    this.setState({ canceling: true });
    if (selectedRow) {
      if (this.props.type === "expense") {
        await this.props.cancelExpense(
          this.state.cancellationReason,
          selectedRow.key
        );
        this.props.getToastr("Record cancelled successfully");
      }
      if (this.props.type === "accountmanagement") {
        await this.props.cancelTransaction(
          this.state.cancellationReason,
          selectedRow.key
        );
        this.props.getToastr("Record cancelled successfully");
      }
    }
    this.setState({ canceling: false, showCancel: false, selectedRow: null });
  }

  async getData(
    searchText,
    filters,
    sort,
    recordFrom,
    itemsCountPerPage,
    api,
    exportData = false
  ) {
    // console.log('getData exportData', exportData)
    this.setState({ loading: true });
    if (this.props.type === "saleschannel") {
      let salesChannels = this.props.config.SalesChannel;
      // await getSalesChannel(
      //   this.props.registration.registration.key
      // );
      this.setState({
        list: salesChannels,
        totalItemsCount: salesChannels.length,
        loading: false,
      });
      return salesChannels;
    }

    if (this.props.type === "cashregisterclosinglist") {
      // console.log('filters',filters)
      let cashRegisterClosingList = await this.props.getCashRegisterClosingList(
        this.props.user.store.key,
        filters[1].Value,
        filters[0].Value
      );
      var sortedList = cashRegisterClosingList
        .sort((a, b) => {
          return (
            new Date(a.OpeningTime.seconds * 1000).getTime() -
            new Date(b.OpeningTime.seconds * 1000).getTime()
          );
        })
        .reverse();
      sortedList.map((m) => {
        m.OpeningTimeDisplay = m.OpeningTime
          ? new Date(m.OpeningTime.seconds * 1000).toString().substring(0, 24)
          : "";
        m.ClosingTimeDisplay = m.ClosingTime
          ? new Date(m.ClosingTime.seconds * 1000).toString().substring(0, 24)
          : "";
        m.ActionOnDisplay = m.ActionOn
          ? new Date(m.ActionOn.seconds * 1000).toString().substring(0, 24)
          : "";
      });
      this.setState({
        list: sortedList,
        totalItemsCount: sortedList.length,
        loading: false,
      });
      return sortedList;
    }
    if (this.props.type === "transactionalsmstemplates") {
      let tmp = await this.props.getSMSTemplates(
        this.props.user.user.RegistrationID
      );
      let smsTemplates = [];
      for (let i = 0; i < tmp.length; i++) {
        let type = tmp[i].hasOwnProperty("SMSType")
          ? tmp[i].SMSType
          : "Transactional";
        if (type === "Transactional") {
          smsTemplates.push(tmp[i]);
        }
      }
      this.setState({
        list: smsTemplates,
        totalItemsCount: smsTemplates.length,
        loading: false,
      });
      // console.log('smsTemplates',smsTemplates)
      return smsTemplates;
    }
    if (this.props.type == "promotionalsmstemplates") {
      let tmp = await this.props.getSMSTemplates(
        this.props.user.user.RegistrationID
      );
      // console.log('tmp',tmp)
      let smsTemplates = [];
      for (let i = 0; i < tmp.length; i++) {
        let type = tmp[i].hasOwnProperty("SMSType")
          ? tmp[i].SMSType
          : "Transactional";
        if (type !== "Transactional") {
          smsTemplates.push(tmp[i]);
        }
      }
      this.setState({
        list: smsTemplates,
        totalItemsCount: smsTemplates.length,
        loading: false,
      });
      return smsTemplates;
    }
    if (this.props.type == "moduleconfig") {
      await this.props.getTransactionTypes(this.props.user.user.RegistrationID);
      let moduleconfigs = this.props.config.TransactionTypes.filter(
        (f) => !f.IsDeleted
      );
      this.setState({
        list: moduleconfigs,
        totalItemsCount: moduleconfigs.length,
        loading: false,
      });
      return moduleconfigs;
    }
    if (this.props.type == "customroles") {
      let customRoles = await this.props.getCustomRoles(
        this.props.user.user.RegistrationID
      );
      this.setState({
        list: customRoles,
        totalItemsCount: customRoles.length,
        loading: false,
      });
      return customRoles;
    }
    if (this.props.type == "paymenttypes") {
      await this.props.getPaymentTypes(this.props.user.user.RegistrationID);
      let paymenttypes = this.props.config.PaymentTypes.filter(
        (e) => !e.IsDeleted
      );
      this.setState({
        list: paymenttypes,
        totalItemsCount: paymenttypes.length,
        loading: false,
      });
      return paymenttypes;
    }
    if (this.props.type == "account") {
      let accounts = await this.props.getAccounts(
        this.props.user.user.RegistrationID
      );
      this.setState({
        list: accounts,
        totalItemsCount: accounts.length,
        loading: false,
      });
      return accounts;
    }
    if (this.props.type == "expensecategory") {
      let expenseCategory = await this.props.getExpenseCategories(
        this.props.user.user.RegistrationID,
        this.props.user.store.key
      );
      this.setState({
        list: expenseCategory,
        totalItemsCount: expenseCategory.length,
        loading: false,
      });
      return expenseCategory;
    }
    if (this.props.type == "tax") {
      // console.log('tax')
      let allTaxes = await this.props.getProductTaxes(
        this.props.user.user.RegistrationID
      );
      // console.log('allTaxes', allTaxes)
      let taxes = [];
      taxes = allTaxes.filter((e) => !e.IsDeleted);
      // console.log('taxes',taxes)
      this.setState({
        list: taxes,
        totalItemsCount: taxes.length,
        loading: false,
      });
      return taxes;
    }
    if (this.props.type == "survey") {
      let allSurveys = await this.props.getSurveys(
        this.props.user.user.RegistrationID
      );
      let surveys = [];
      surveys = allSurveys.filter((e) => !e.IsDeleted);
      this.setState({
        list: surveys,
        totalItemsCount: surveys.length,
        loading: false,
      });
      return surveys;
    }
    if (this.props.type == "omnipages") {
      let pages = await this.props.getOmniPages(
        this.props.user.user.RegistrationID
      );
      this.setState({
        list: pages,
        totalItemsCount: pages.length,
        loading: false,
      });
      return pages;
    }
    if (this.props.type == "omnisliders") {
      let slider = await this.props.getCarousels(
        this.props.user.user.RegistrationID
      );
      this.setState({
        list: slider,
        totalItemsCount: slider.length,
        loading: false,
      });
      console.log("slider", slider);
      return slider;
    }
    if (this.props.type == "omnihomesections") {
      let omniSettings = await this.props.getOmniSettings(
        this.props.user.user.RegistrationID
      );
      console.log("omniSettings", omniSettings);
      let omniHomeSections = omniSettings.hasOwnProperty("HomeSections")
        ? omniSettings.HomeSections
        : [];
      for (let i = 0; i < omniHomeSections.length; i++) {
        omniHomeSections[i].key = i;
      }
      this.setState({
        list: omniHomeSections,
        totalItemsCount: omniHomeSections.length,
        loading: false,
      });
      return omniHomeSections;
    }
    if (this.props.type == "omnimenus") {
      let omniSettings = await this.props.getOmniSettings(
        this.props.user.user.RegistrationID
      );
      console.log("omniSettings", omniSettings);
      let omniMenus = omniSettings.hasOwnProperty("Menus")
        ? omniSettings.Menus
        : [];
      for (let i = 0; i < omniMenus.length; i++) {
        omniMenus[i].key = i;
      }
      this.setState({
        list: omniMenus,
        totalItemsCount: omniMenus.length,
        loading: false,
      });
      return omniMenus;
    }
    if (this.props.type == "offers" || this.props.type === "coupons") {
      let alloffers = await this.props.getOffers(
        this.props.user.user.RegistrationID
      );
      let offers = alloffers.filter((e) => !e.IsDeleted);

      let final = [];
      offers.map((m) => {
        if (
          m.hasOwnProperty("Type") &&
          m.Type === "Coupon" &&
          this.props.type === "coupons"
        ) {
          final.push(m);
        } else if (this.props.type === "offers" && !m.hasOwnProperty("Type")) {
          final.push(m);
        } else if (
          m.hasOwnProperty("Type") &&
          m.Type === "Offer" &&
          this.props.type === "offers"
        ) {
          final.push(m);
        }
      });
      let finalList = final;
      if (searchText !== "") {
        finalList = final.filter(
          (f) =>
            f.Name.search(searchText) > -1 ||
            f.Description.search(searchText) > -1
        );
        //   console.log('Filtered list', finalList)
      }
      this.setState({
        list: finalList,
        totalItemsCount: finalList.length,
        loading: false,
      });
      return final;
    }
    if (this.props.type == "campaign") {
      let allCampaigns = await this.props.getCampaign(
        this.props.user.user.RegistrationID
      );
      let campaigns = allCampaigns.filter((e) => !e.IsDeleted);

      for (let i = 0; i < campaigns.length; i++) {
        let obj = campaigns[0];
        // console.log("obj", obj);
        let campaignToRun = await this.props.getCampaignToRunByCampaignID(
          obj.key
        );
        if (campaignToRun && campaignToRun.length > 0) {
          obj.CampaignToRunID = campaignToRun[0].key;
          obj.Message = campaignToRun[0].Message;
          obj.MessageLength = campaignToRun[0].hasOwnProperty("MessageLength")
            ? campaignToRun[0].MessageLength
            : 0;
          obj.NoOfCustomers = campaignToRun[0].hasOwnProperty("NoOfCustomers")
            ? campaignToRun[0].NoOfCustomers
            : 0;
          obj.NoOfSMSSent = campaignToRun[0].hasOwnProperty("NoOfSMSSent")
            ? campaignToRun[0].NoOfSMSSent
            : 0;
          obj.Status = campaignToRun[0].Status;
          obj.LastRunOn = campaignToRun[0].ActionOn;
        }

        obj.SlotDisplay =
          obj.Slot === 8
            ? "8:00 - 10:00"
            : obj.Slot === 10
            ? "10:00 - 12:00"
            : obj.Slot === 12
            ? "12:00 - 14:00"
            : obj.Slot === 14
            ? "14:00 - 16:00"
            : obj.Slot === 16
            ? "16:00 - 18:00"
            : obj.Slot === 18
            ? "18:00 - 20:00"
            : obj.Slot === 20
            ? "20:00 - 2:00"
            : "";

        obj.StoresDisplay = "";
        if (obj.AllStoresSelected) {
          obj.StoresDisplay = "All stores";
        } else {
          if (obj.Stores && obj.Stores.length > 0) {
            obj.Stores.map((store) => {
              obj.Stores = obj.Stores + " " + store.label;
            });
          }
        }
      }
      this.setState({
        list: campaigns,
        totalItemsCount: campaigns.length,
        loading: false,
      });
      return campaigns;
    }
    if (this.props.type === "loyaltytype") {
      let allLoyatyTypes = await this.props.getLoyaltyType(
        this.props.user.user.RegistrationID
      );
      let loyaltyTypes = [];
      loyaltyTypes = allLoyatyTypes.filter((e) => !e.IsDeleted);
      this.setState({
        list: loyaltyTypes,
        totalItemsCount: loyaltyTypes.length,
        loading: false,
      });
      return loyaltyTypes;
    }
    if (this.props.type === "smstemplate") {
      let smsTemplates = await this.props.getSMSTemplates(
        this.props.user.user.RegistrationID
      );
      this.setState({
        list: smsTemplates,
        totalItemsCount: smsTemplates.length,
        loading: false,
      });
      return smsTemplates;
    }
    let result = null;
    if (this.props.type === "customer") {
      console.log("customer");
      result = await getCustomerData(
        searchText,
        filters,
        sort,
        recordFrom,
        itemsCountPerPage,
        api,
        exportData,
        this.props,
        this.state.stores,
        this.state.listPageSettings
      );
      // console.log("customer result",result);
    } else {
      result = await this.getElasticData(
        searchText,
        filters,
        sort,
        recordFrom,
        itemsCountPerPage,
        api,
        exportData
      );
    }
    if (!exportData) {
      this.setState({
        list: result.list,
        totalItemsCount: result.total,
        loading: false,
      });
    } else {
      return result.list;
    }
  }

  async getElasticData(
    searchText,
    filters,
    sort,
    recordFrom,
    itemsCountPerPage,
    api,
    exportData
  ) {
    let scrollData = false;
    let scrollID = "";
    let itemsCountPerPageFinal = itemsCountPerPage;
    if (exportData) {
      recordFrom = 0;
      //get incremetal data
      if (itemsCountPerPage > 10000) {
        scrollData = true;
        itemsCountPerPageFinal = 2000;
      } else {
        itemsCountPerPageFinal = 10000;
      }
    }

    let searchParameters = {
      from: recordFrom,
      track_total_hits: true,
      size: itemsCountPerPageFinal,
      query: {
        bool: {
          must: [],
          must_not: [],
          should: [],
          filter: {
            bool: {
              must: [],
              should: [],
            },
          },
        },
      },
      sort: [],
    };

    filters.map((f) => {
      if (
        f.hasOwnProperty("GeneralSearch") &&
        f.GeneralSearch &&
        searchText.trim() !== ""
      ) {
        if (f.hasOwnProperty("Type") && f.QueryType === "match_phrase") {
          let q =
            '{"match_phrase" : {"' +
            f.Column +
            '" : "' +
            searchText.trim().toLowerCase() +
            '" }}';
          searchParameters.query.bool.filter.bool.should.push(JSON.parse(q));
        } else {
          let q =
            '{"regexp" : {"' +
            f.Column +
            '" : "@' +
            searchText.trim().toLowerCase() +
            '.*" }}';
          searchParameters.query.bool.filter.bool.should.push(JSON.parse(q));
        }
      }
    });
    for (let i = 0; i < filters.length; i++) {
      let f = filters[i];
      if (f.Column === "ParentProductID" && exportData) {
        continue;
      }
      if (f.Type === "status") {
        if (f.Value === "active") {
          searchParameters.query.bool.must_not.push({
            match: { IsDeleted: "true" },
          });
        }
        if (f.Value === "inactive") {
          searchParameters.query.bool.must_not.push({
            match: { IsDeleted: "false" },
          });
        }
      } else if (f.Type === "approvalstatus") {
        // console.log('f.', f)
        if (f.Value !== "all") {
          let para = '{"match": {"' + f.Column + '":"' + f.Value + '" }}';
          searchParameters.query.bool.must.push(JSON.parse(para));
        }
      } else if (f.Type === "daterange") {
        // console.log('f.Value',f.Value)
        if (f.Value.ToDate !== "" && f.Value.FromDate !== "") {
          let para =
            '{"range": {' +
            '"' +
            f.Column +
            '":{"lte":"' +
            f.Value.ToDate +
            '","gte":"' +
            f.Value.FromDate +
            '","format": "yyyy-MM-dd"}}}';
          // console.log('para', para)
          searchParameters.query.bool.must.push(JSON.parse(para));
        }

        //   "range": {
        //     "TransactionDate": {
        //       "lte":f.Value.ToDate,
        //       "gte":f.Value.FromDate,
        //       "format": "yyyy-MM-dd"
        //     }
        //   }
      } else if (f.Type === "registrationid") {
        searchParameters.query.bool.must.push({
          match: { RegistrationID: this.props.registration.registration.key },
        });
      } else if (f.Type === "storeID") {
        searchParameters.query.bool.must.push({
          match: { StoreID: this.props.user.store.key },
        });
        // searchParameters.query.bool.filter.bool.must.push({
        //     "match": {"StoreID": this.props.user.store.key}
        // })
      } else if (f.Type === "stores") {
        // console.log('stores', f)
        if (f.Value && f.Value !== "" && f.Column && f.Column !== "") {
          //specific store
          if (f.Column.toLowerCase() === "store.key") {
            let q =
              '{"regexp" : {"' +
              f.Column +
              '" : "@' +
              f.Value.trim().toLowerCase() +
              '.*" }}';
            searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
            // if(api==="getInventory"){
            //     console.log('invenotry listpage');

            // }
          } else {
            let q = '{"term": {"' + f.Column + '":"' + f.Value + '"}}';
            searchParameters.query.bool.should.push(JSON.parse(q));
            if (api === "getInventory") {
              // console.log("invenotry listpage");
              let l = '{"term": {"ToStoreID.keyword":"' + f.Value + '"}}';
              searchParameters.query.bool.should.push(JSON.parse(l));
            }
          }
        } else {
          // if(f.Column  && f.Column !=="" && f.Value && f.Value==="")
          //all stores

          // console.log('all stores')
          if (this.state.stores.length < 50) {
            for (let i = 0; i < this.state.stores.length; i++) {
              const store = this.state.stores[i];
              if (f.Column.toLowerCase() === "store.key") {
                let q =
                  '{"regexp" : {"' +
                  f.Column +
                  '" : "@' +
                  store.key.trim().toLowerCase() +
                  '.*" }}';
                searchParameters.query.bool.filter.bool.should.push(
                  JSON.parse(q)
                );
              } else {
                let q = '{"term": {"' + f.Column + '":"' + store.key + '"}}';
                searchParameters.query.bool.should.push(JSON.parse(q));
              }
            }
          }
        }
      } else {
        if (f.hasOwnProperty("Value")) {
          // && f.Value !== ""
          let queryType = "regexp";
          if (f.hasOwnProperty("QueryType") && f.QueryType === "term") {
            queryType = "term";
            let q =
              '{"' +
              queryType +
              '" : {"' +
              f.Column +
              '" : "' +
              f.Value.trim().toLowerCase() +
              '" }}';
            searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
          } else if (
            f.hasOwnProperty("QueryType") &&
            f.QueryType === "must_not"
          ) {
            searchParameters.query.bool.must_not.push(f.Value);
          } else if (f.hasOwnProperty("QueryType") && f.QueryType === "must") {
            //console.log('f.Column', f)
            if (f.Column !== "") {
              queryType = f.QueryType;
              let q =
                '{"match" : {"' +
                f.Column +
                '" : "' +
                f.Value.trim().toLowerCase() +
                '" }}';
              searchParameters.query.bool.must.push(JSON.parse(q));
            } else {
              console.log(f);
              searchParameters.query.bool.must.push(f.Value);
            }
          } else if (f.hasOwnProperty("QueryType") && f.QueryType === "match") {
            if (f.Column !== "") {
              let q =
                '{"match" : {"' +
                f.Column +
                '" : "' +
                f.Value.trim().toLowerCase() +
                '" }}';
              searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
            }
          } else if (f.QueryType && f.QueryType === "match_phrase") {
            if (f.Value !== "" && f.Column !== "") {
              let q =
                '{"match_phrase" : {"' +
                f.Column +
                '" : "' +
                f.Value.trim().toLowerCase() +
                '" }}';
              searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
            }
          } else if (f.Column !== "" && f.Value !== "") {
            queryType = f.QueryType ? f.QueryType : "regexp";
            let q =
              '{"' +
              queryType +
              '" : {"' +
              f.Column +
              '" : "@' +
              f.Value.trim().toLowerCase() +
              '.*" }}';
            searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
          }
          // } else if (f.Column !== "" && f.Value !== "" && !f.QueryType) {
          //   let q =
          //     '{"' +
          //     queryType +
          //     '" : {"' +
          //     f.Column +
          //     '" : "@' +
          //     f.Value.trim().toLowerCase() +
          //     '.*" }}';
          //   searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
          // }
        }
      }
    }

    if (
      this.props.type === "customer" &&
      this.props.user.user.Role !== "Power User"
    ) {
      searchParameters.query.bool.must.push({
        match: {
          StoreID: this.props.user.store.key,
        },
      });
    }

    if (sort && sort.length > 0) {
      sort.map((s) => {
        let q = '{"' + s.Column + '":{"order":"' + s.Order + '"}}';
        searchParameters.sort.push(JSON.parse(q));
      });
    }

    if (exportData) {
      let exportColumns = [];
      this.state.listPageSettings.ExportColumns.map((e) => {
        exportColumns.push(e.Column);
      });
      if (exportColumns.length > 0) {
        searchParameters["_source"] = exportColumns;
      }
    }
    if (searchParameters.query.bool.should.length > 0) {
      searchParameters.query.bool["minimum_should_match"] = 1;
    }
    if (searchParameters.query.bool.filter.bool.should.length > 0) {
      searchParameters.query.bool.filter.bool["minimum_should_match"] = 1;
    }
    console.log("searchParameters", JSON.stringify(searchParameters));
    let finalAPI = api;
    let finalAPIMessage = { text: searchParameters };
    if (scrollData) {
      finalAPI = "scrollData";
      finalAPIMessage.collection = this.state.listPageSettings.Collection;
      finalAPIMessage.scrollID = "";
    }
    console.log("finalAPI", finalAPI);
    let addMessage = firebase.functions().httpsCallable(finalAPI);
    let result = await addMessage(finalAPIMessage);
    const list = [];
    let sanitizedMessage = result.data.text;
    let total = sanitizedMessage.hits.total.value;
    let datas = sanitizedMessage.hits.hits;
    datas = await this.getAdditonalInfo(datas);
    datas.map(async (data, index) => {
      list.push(this.dataTransformation(data, exportData, filters));
    });

    if (scrollData) {
      scrollID = sanitizedMessage._scroll_id;
      searchParameters = {
        scroll: "1m",
        scroll_id: scrollID,
      };
      finalAPIMessage.text = searchParameters;
      finalAPIMessage.scrollID = scrollID;
      for (let index = 1; index < total / 2000; index++) {
        let addMessage = firebase.functions().httpsCallable(finalAPI);
        console.log("finalAPIMessage", finalAPIMessage);
        let loopResult = await addMessage(finalAPIMessage);
        let loopDatas = loopResult.data.text.hits.hits;
        loopDatas = await this.getAdditonalInfo(loopDatas);
        loopDatas.map((data, index) => {
          list.push(this.dataTransformation(data, exportData, filters));
        });
      }
    }

    if (this.props.type === "employeelist") {
      for (let i = 0; i < list.length; i++) {
        let data = list[i];
        let stores = "";
        data.Role = "";
        if (data.hasOwnProperty("UID") && data.UID !== "") {
          let user = await this.props.getUserDetails(data.UID);
          // console.log('user',user)
          if (user) {
            data.Role = user.Role;
            data.Stores = user.Stores;
          }
          if (data.Stores) {
            data.Stores.map((store, index) => {
              stores += (index > 0 ? "," : "") + store.StoreName;
            });
          }
          data.StoresDisplay = stores;
        }

        list[i] = data;
      }
    } else if (this.props.type === "accountmanagement") {
      for (let i = 0; i < list.length; i++) {
        let data = list[i];
        if (
          data.hasOwnProperty("CancellationReason") &&
          data.CancellationReason !== ""
        ) {
          data.IsDeleted = true;
        }
      }
    }
    // console.log("list", list);
    return { list: list, total: total };
  }

  async getAdditonalInfo(datas) {
    if (this.props.type === "products") {
      if (
        this.props.user.store
          .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount
      ) {
        let productIDs = [];
        datas.map((m) => {
          productIDs.push(m._id);
        });
        // console.log("productIDs", productIDs);
        //get instock qty
        let instockResult = await this.props.getInStockQtyForProducts(
          this.props.user.store.key,
          "",
          productIDs
        );
        // console.log("instockResult", instockResult);
        for (let i = 0; i < datas.length; i++) {
          if (
            instockResult.searchResult.filter((f) => f.key === datas[i]._id)
              .length > 0
          ) {
            let tmp = instockResult.searchResult.filter(
              (f) => f.key === datas[i]._id
            );
            datas[i]._source.InStockQty = tmp[0].InStockQty;
            // console.log("datas[i]",datas[i])
          } else {
            datas[i]._source.InStockQty = 0;
          }
        }
      }
    }
    return datas;
  }

  dataTransformation(record, exportData = false, filters) {
    let data = { key: record._id, ...record._source };
    // console.log('data',data)
    if (
      this.props.type === "product" ||
      this.props.type === "products" ||
      this.props.type === "itemnotfoundinmastercatalogue"
    ) {
      data.Image =
        data.Images && data.Images.length > 0 ? data.Images[0] : null;

      if (data.hasOwnProperty("Variants") && data.Variants.length > 0) {
        for (let i = 0; i < data.Variants.length; i++) {
          const variant = data.Variants[i];
          if (!variant.hasOwnProperty("IsDeleted")) {
            variant.IsDeleted = false;
          }
        }
      }

      // if (data.hasOwnProperty("AccessibleToSelectedStores")) {
      //   if (data.AccessibleToSelectedStores) {
      //     let storePrice = data.StoreSpecificPrices.filter(
      //       (f) => f.StoreID === this.props.user.store.key
      //     );
      //     if (storePrice.length > 0) {
      //       data.RetailPrice = storePrice[0].RetailPrice;
      //       data.SupplyPrice = storePrice[0].SupplyPrice;
      //       data.Markup = storePrice[0].Markup;
      //       data.MRP = storePrice[0].MRP;
      //       data.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
      //         ? storePrice[0].DiscountAmount
      //         : 0;
      //       data.DiscountPercentage = storePrice[0].hasOwnProperty(
      //         "DiscountPercentage"
      //       )
      //         ? storePrice[0].DiscountPercentage
      //         : 0;
      //       data.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
      //         "WalletTopUpSameAsRetailPrice"
      //       )
      //         ? storePrice[0].WalletTopUpSameAsRetailPrice
      //         : false;
      //       data.WalletTopUpAmount = storePrice[0].hasOwnProperty(
      //         "WalletTopUpAmount"
      //       )
      //         ? storePrice[0].WalletTopUpAmount
      //         : 0;
      //       data.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
      //         "EditWalletTopUpAmount"
      //       )
      //         ? storePrice[0].EditWalletTopUpAmount
      //         : false;
      //     }
      //   }
      // }
      // if (data.hasOwnProperty("StoreSpecificPricing")) {
      //   if (data.StoreSpecificPricing) {
      //     let storePrice = data.StoreSpecificPrices.filter(
      //       (f) => f.StoreID === this.props.user.store.key
      //     );

      //     if (storePrice.length > 0) {
      //       data.SupplyPrice = storePrice[0].SupplyPrice;
      //       data.Markup = storePrice[0].Markup;
      //       data.RetailPrice = storePrice[0].RetailPrice;
      //       data.MRP = storePrice[0].MRP;
      //       data.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
      //         ? storePrice[0].DiscountAmount
      //         : 0;
      //       data.DiscountPercentage = storePrice[0].hasOwnProperty(
      //         "DiscountPercentage"
      //       )
      //         ? storePrice[0].DiscountPercentage
      //         : 0;
      //       data.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
      //         "WalletTopUpSameAsRetailPrice"
      //       )
      //         ? storePrice[0].WalletTopUpSameAsRetailPrice
      //         : false;
      //       data.WalletTopUpAmount = storePrice[0].hasOwnProperty(
      //         "WalletTopUpAmount"
      //       )
      //         ? storePrice[0].WalletTopUpAmount
      //         : 0;
      //       data.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
      //         "EditWalletTopUpAmount"
      //       )
      //         ? storePrice[0].EditWalletTopUpAmount
      //         : false;
      //     }
      //   }
      // }

      let storeID = "";
      if (
        filters.filter((f) => f.Type === "stores" && f.Value !== "").length > 0
      ) {
        storeID = filters.filter(
          (f) => f.Type === "stores" && f.Value !== ""
        )[0].Value;
        console.log("storeID", storeID);
      }
      if (
        data.hasOwnProperty("StoreSpecificPrices") &&
        data.StoreSpecificPrices.length > 0 &&
        storeID !== ""
      ) {
        let storePrice = data.StoreSpecificPrices.filter(
          (f) => f.StoreID === storeID
        );

        if (storePrice.length > 0) {
          data.SupplyPrice = storePrice[0].SupplyPrice;
          data.Markup = storePrice[0].Markup;
          data.RetailPrice = storePrice[0].RetailPrice;
          data.MRP = storePrice[0].MRP;
          data.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
            ? storePrice[0].DiscountAmount
            : 0;
          data.DiscountPercentage = storePrice[0].hasOwnProperty(
            "DiscountPercentage"
          )
            ? storePrice[0].DiscountPercentage
            : 0;
          data.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
            "WalletTopUpSameAsRetailPrice"
          )
            ? storePrice[0].WalletTopUpSameAsRetailPrice
            : false;
          data.WalletTopUpAmount = storePrice[0].hasOwnProperty(
            "WalletTopUpAmount"
          )
            ? storePrice[0].WalletTopUpAmount
            : 0;
          data.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
            "EditWalletTopUpAmount"
          )
            ? storePrice[0].EditWalletTopUpAmount
            : false;
          if (storePrice[0].hasOwnProperty("OMNIEnabled")) {
            data.OMNIEnabled = storePrice[0].OMNIEnabled;
          } else {
            data.OMNIEnabled = true;
          }
          if (storePrice[0].hasOwnProperty("OutOfStock")) {
            data.OutOfStock = storePrice[0].OutOfStock;
          } else {
            data.OutOfStock = false;
          }
        }
      }

      data.UOM = data.hasOwnProperty("UOM") ? data.UOM : "";
      data.SecondaryUOM =
        data.hasOwnProperty("SecondaryUOMs") && data.SecondaryUOMs.length > 0
          ? data.SecondaryUOMs[0].SecondaryUOMValue
          : "";
      data.UOMToSecondaryUOMConversion =
        data.hasOwnProperty("SecondaryUOMs") && data.SecondaryUOMs.length > 0
          ? data.SecondaryUOMs[0].Conversion
          : "";
      data.SalesUOM = data.hasOwnProperty("SalesUOM") ? data.SalesUOM : "";
      data.PurchaseUOM = data.hasOwnProperty("PurchaseUOM")
        ? data.PurchaseUOM
        : "";
    }
    if (this.props.type === "paybill") {
      if (data.CustomerPhone) {
        if (data.CustomerPhone && data.CustomerPhone.length >= 5) {
          // Extract the first 5 digits
          const firstDigits = data.CustomerPhone.slice(0, 5);

          // Replace the first 5 digits with asterisks
          const maskedPhoneNumber = data.CustomerPhone.replace(
            firstDigits,
            "#".repeat(firstDigits.length)
          );

          data.CustomerPhone = maskedPhoneNumber;
        }
      }
    }
    if (this.props.type === "customer") {
      data.Store =
        this.state.stores.filter((s) => s.key === data.StoreID).length > 0
          ? this.state.stores.filter((s) => s.key === data.StoreID)[0].StoreName
          : "";
    }
    if (this.props.type === "saleshistory") {
      data.CustomerName = data.Customer
        ? data.Customer.FirstName + " " + data.Customer.LastName
        : "";
      if (data.CancellationReason) {
        data.Cancelled = "Yes";
      } else {
        data.Cancelled = "No";
      }
      if (
        data.hasOwnProperty("ReceivedPaymentTransactions") &&
        data.ReceivedPaymentTransactions.length > 0
      ) {
        // console.log(data);
        if (!exportData) {
          console.log("receive payment record", data);
          let balanceAmount = Number(data.BalanceAmount);
          let receivedAount = 0;

          data.ReceivedPaymentTransactions.map((r) => {
            if (r.hasOwnProperty("ReceiveAmount")) {
              receivedAount = Number(receivedAount) + Number(r.ReceiveAmount);
              if (data.hasOwnProperty("Payments")) {
                data.Payments.push({
                  PaymentMode: r.ReceivePaymentNo,
                  Amount: r.ReceiveAmount,
                });
              }
            }
          });
          balanceAmount = balanceAmount - receivedAount;
          data.BalanceAmount = balanceAmount;
          data.BillPaidAmount = Number(data.BillAmount) - Number(balanceAmount);
        }
      }
      // for (let index = 0; index < this.props.config.PaymentTypes.length; index++) {
      //     data[this.props.config.PaymentTypes[index].PaymentType] = ""
      // }
      // if(data.Payments && data.Payments.length > 0){
      //     data.Payments.map((payment) => {
      //         data[payment.PaymentMode] = (payment.PaymentMode === "Cash" ? (payment.Amount - (data.BillChangeDue ? data.BillChangeDue : 0)) : (payment.Amount != undefined ? payment.Amount : 0));
      //         // console.log('data[payment.PaymentMode] ',payment.PaymentMode )
      //     })
      // }
    }
    if (this.props.type === "deliverylist") {
      // console.log('data.DeliveryTime ',data.DeliveryTime);
      data.DeliveryTimeDisplay =
        data.DeliveryTime && data.DeliveryTime.HH && data.DeliveryTime.HH !== ""
          ? data.DeliveryTime.HH +
            ":" +
            data.DeliveryTime.MM +
            " " +
            data.DeliveryTime.AMPM
          : "";
    }
    if (this.props.type === "attendance") {
      let inTimeStr = "";
      let outTimeStr = "";
      if (data.AttendanceType != "Leave" && data.AttendanceType != "Week-Off") {
        inTimeStr = data.InTime.HH
          ? data.InTime.HH + ":" + data.InTime.MM + " " + data.InTime.AMPM
          : "";
        outTimeStr = data.OutTime.HH
          ? data.OutTime.HH + ":" + data.OutTime.MM + " " + data.OutTime.AMPM
          : "";
      }
      data.InTimeDisplay = inTimeStr;
      data.OutTimeDisplay = outTimeStr;
      data.EmployeeDisplay = data.Employee.label;
    }
    if (this.props.type === "expense") {
      data.StatusDisplay = data.Status;
      if (data.Status.toLowerCase() === "cancelled") {
        data.StatusDisplay = "";
        data.CancellationReasonDisplay =
          "Cancelled: " + data.CancellationReason;
        data.IsDeleted = true;
      }
    }
    //console.log('data',data)
    return data;
  }

  setConfigFilters(pageSettings, searchText, recordFrom = 0, activePage = 1) {
    this.props.setPageFilters(
      pageSettings.Type,
      pageSettings.Filters,
      searchText,
      recordFrom,
      activePage
    );
  }

  onSearchClick() {
    this.setState({ showFilter: false, recordFrom: 0, activePage: 1 });
    this.setConfigFilters(
      this.state.listPageSettings,
      this.state.searchText,
      0,
      1
    );
    this.getData(
      this.state.searchText,
      this.state.listPageSettings.Filters,
      this.state.listPageSettings.Sort,
      0,
      this.state.itemsCountPerPage,
      this.state.listPageSettings.API
    );
  }

  onClearClick() {
    this.setState({ showFilter: false });
    let listPageSettings = this.state.listPageSettings;
    // listPageSettings.Filters.map(f=> f.Value="");
    for (let i = 0; i < listPageSettings.Filters.length; i++) {
      if (
        listPageSettings.Filters[i].hasOwnProperty("Type") &&
        (listPageSettings.Filters[i].Type === "text" ||
          listPageSettings.Filters[i].Type === "producttype")
      ) {
        listPageSettings.Filters[i].Value = "";
      }
    }

    this.setState({
      searchText: "",
      listPageSettings: listPageSettings,
      recordFrom: 0,
      activePage: 1,
    });
    this.setConfigFilters(listPageSettings, "", 0, 1);
    this.getData(
      "",
      listPageSettings.Filters,
      listPageSettings.Sort,
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      listPageSettings.API
    );
  }

  onFirstClick() {
    if (this.state.activePage > 1) {
      let pageNo = 1;
      let recordFrom = 0;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.setConfigFilters(
        this.state.listPageSettings,
        this.state.searchText,
        recordFrom,
        pageNo
      );
      this.getData(
        this.state.searchText,
        this.state.listPageSettings.Filters,
        this.state.listPageSettings.Sort,
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.listPageSettings.API
      );
    }
  }

  onPreviousClick() {
    if (this.state.activePage > 1) {
      let pageNo = Number(this.state.activePage) - 1;
      let recordFrom = (pageNo - 1) * this.state.itemsCountPerPage;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.setConfigFilters(
        this.state.listPageSettings,
        this.state.searchText,
        recordFrom,
        pageNo
      );
      this.getData(
        this.state.searchText,
        this.state.listPageSettings.Filters,
        this.state.listPageSettings.Sort,
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.listPageSettings.API
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage) + 1;
    let recordFrom = (pageNo - 1) * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.setConfigFilters(
        this.state.listPageSettings,
        this.state.searchText,
        recordFrom,
        pageNo
      );
      this.getData(
        this.state.searchText,
        this.state.listPageSettings.Filters,
        this.state.listPageSettings.Sort,
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.listPageSettings.API
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );
    let recordFrom = Number(pageNo) * Number(this.state.itemsCountPerPage);
    this.setState({ activePage: pageNo, recordFrom: recordFrom });
    this.setConfigFilters(
      this.state.listPageSettings,
      this.state.searchText,
      recordFrom,
      pageNo
    );
    this.getData(
      this.state.searchText,
      this.state.listPageSettings.Filters,
      this.state.listPageSettings.Sort,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.listPageSettings.API
    );
  }

  onRecordsPerPageCountChange(itemsCountPerPage) {
    let pageNo = 1;
    let recordFrom = 0;
    this.setState({
      activePage: pageNo,
      recordFrom: recordFrom,
      itemsCountPerPage: Number(itemsCountPerPage),
    });
    if (typeof Storage !== "undefined") {
      localStorage.listPageRecordsPerPage = itemsCountPerPage;
    }
    this.setConfigFilters(
      this.state.listPageSettings,
      this.state.searchText,
      recordFrom,
      pageNo
    );
    this.getData(
      this.state.searchText,
      this.state.listPageSettings.Filters,
      this.state.listPageSettings.Sort,
      recordFrom,
      Number(itemsCountPerPage),
      this.state.listPageSettings.API
    );
  }

  onClosePopup() {
    this.setState({ showPopup: false });
  }

  onPostPopupSave(keepwindowOpen = false) {
    this.setState({ showPopup: keepwindowOpen, selectedRow: null });
    this.getData(
      "",
      this.state.listPageSettings.Filters,
      this.state.listPageSettings.Sort,
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      this.state.listPageSettings.API
    );
  }

  onOptionsButtonClick(button) {
    if (button.Type === "export") {
      // console.log('export clicked');
      this.setState({ showOptions: false });
      this.onExport();
    } else if (button.Type === "import") {
      this.setState({
        showImport: true,
        importType: button.ImportType,
        showOptions: false,
      });
    } else if (button.Type === "log") {
      this.setState({
        showLog: true,
        importType: button.LogType,
        showOptions: false,
      });
    } else if (button.Type === "redirect") {
      if (button.hasOwnProperty("State")) {
        this.props.history.push({ pathname: button.URL, state: button.State });
      } else {
        this.props.history.push({ pathname: button.URL });
      }
    } else if (button.Type === "popup") {
      this.setState({
        showPopup: true,
        selectedRow: null,
        popupType: button.PopupType,
      });
    }
  }

  onHeaderButtonClicked(button) {
    if (
      button.Action === "redirect" &&
      !button.hasOwnProperty("URLQueryString")
    ) {
      this.props.history.push({ pathname: button.URL });
    }
    if (button.Action === "filter") {
      this.setState({ showFilter: true });
    }
    if (button.Action === "options") {
      this.setState({ showOptions: true });
    }
    if (button.Action === "popup") {
      this.setState({
        showPopup: true,
        selectedRow: null,
        popupType: button.PopupType,
      });
    }
    if (button.Action === "popupwindow") {
      this.setState({ showPopupWindow: true, popupType: button.PopupType });
    }
  }

  async onRowButtonClicked(button, row) {
    console.log("onRowButtonClicked.row", JSON.stringify(row));
    if (button.Action === "redirect") {
      let hist = { pathname: button.URL };
      if (button.hasOwnProperty("URLQueryString")) {
        hist.pathname = hist.pathname + row[button.URLQueryString];
      }
      if (button.hasOwnProperty("URLState")) {
        hist.state = button.URLState;
      }
      // console.log('hist',hist);
      this.props.history.push(hist);
      // this.props.history.push({pathname: button.URL + row[button.URLQueryString]})
    } else if (button.Action === "delete") {
      this.setState({ showDelete: true, selectedRow: row });
    } else if (button.Action === "popup") {
      this.setState({
        showPopup: true,
        selectedRow: row,
        popupType: button.PopupType,
      });
    } else if (button.Action === "cancel") {
      this.setState({ showCancel: true, selectedRow: row });
    } else if (this.state.listPageSettings.Type === "deliverylist") {
      //depending on selected delivery status show options
      // this.state.listPageSettings.Filters.
    }
    this.setState({ showPopupWindow: false });
    // console.log('onRowButtonClicked',button)
  }

  onCloseFilter() {
    this.setState({ showFilter: false });
  }

  async onExport() {
    let data = await this.getData(
      this.state.searchText,
      this.state.listPageSettings.Filters,
      this.state.listPageSettings.Sort,
      this.state.recordFrom,
      this.state.totalItemsCount,
      this.state.listPageSettings.API,
      true
    );
    let exportList = [];
    let exporcolumns = this.state.listPageSettings.ExportColumns;
    //console.log('exporcolumns',exporcolumns)
    for (let i = 0; i < data.length; i++) {
      if (this.state.listPageSettings.Type === "bom") {
        let obj = data[i];
        for (let k = 0; k < obj.RawMaterials.length; k++) {
          let record = {};
          if (k === 0) {
            record = {
              BOMName: obj.BOMName,
              ProductSKU: obj.Product.SKU,
              ProductName: obj.Product.Name,
              RawMaterialName: obj.RawMaterials[k].Product,
              Qty: obj.RawMaterials[k].Qty,
              ProductID: obj.Product.ProductID,
              RawMaterialID: obj.RawMaterials[k].ProductID,
            };
          } else {
            record = {
              BOMName: "",
              ProductSKU: "",
              ProductName: "",
              RawMaterialName: obj.RawMaterials[k].Product,
              Qty: obj.RawMaterials[k].Qty,
              ProductID: obj.Product.ProductID,
              RawMaterialID: obj.RawMaterials[k].ProductID,
            };
          }
          exportList.push(record);
        }
      } else {
        let row = {};
        exporcolumns.map((col) => {
          if (col.Type === "address") {
            //address
            let addressType = "";
            let address1 = "";
            let address2 = "";
            let city = "";
            let state = "";
            let postalCode = "";
            let country = "";
            if (
              data[i].hasOwnProperty("Addresses") &&
              data[i].Addresses.length > 0
            ) {
              // console.log('data[i].Addresses[0]', data[i].Addresses[0])
              addressType = data[i].Addresses[0].AddressType;
              address1 = data[i].Addresses[0].Address1;
              address2 = data[i].Addresses[0].Address2;
              city = data[i].Addresses[0].City;
              state = data[i].Addresses[0].State;
              postalCode = data[i].Addresses[0].PostalCode;
              country = data[i].Addresses[0].Country;
            }
            row["Address Type"] = addressType;
            row["Address Line1"] = address1;
            row["Address Line2"] = address2;
            row["City"] = city;
            row["State"] = state;
            row["Postal Code"] = postalCode;
            row["Country"] = country;
          } else if (col.Type === "payments") {
            for (
              let index = 0;
              index < this.props.config.PaymentTypes.length;
              index++
            ) {
              let amount = 0;
              // console.log('data[i]', data[i].Payments);
              // console.log('this.props.config.PaymentTypes', this.props.config.PaymentTypes);
              if (
                data[i].Payments &&
                data[i].Payments.filter(
                  (f) =>
                    f.hasOwnProperty("PaymentMode") &&
                    f.PaymentMode.trim() ===
                      this.props.config.PaymentTypes[index].PaymentType.trim()
                ).length > 0
              ) {
                amount = data[i].Payments.filter(
                  (f) =>
                    f.PaymentMode ===
                    this.props.config.PaymentTypes[index].PaymentType
                )[0].Amount;
              }
              // console.log('this.props.config.PaymentTypes[index].PaymentType',this.props.config.PaymentTypes[index].PaymentType);
              // console.log('amount',amount)
              row[this.props.config.PaymentTypes[index].PaymentType] = amount; //""
            }
          } else if (col.Name !== "") {
            let value = this.renderColumnValue(data[i], col);
            // if (
            //   col.Name.toLowerCase() === "barcode" ||
            //   col.Name.toLowerCase() === "sku"
            // ) {
            //   row[col.Name] = "'" + value;
            // } else {
            row[col.Name] = value;
            // }
          }
        });
        exportList.push(row);
      }
    }
    // console.log('exportList',exportList)
    const csvData = constatnts.objToCSV(exportList);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", this.state.listPageSettings.Type + ".csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onCloseOptions() {
    this.setState({ showOptions: false });
  }

  onCloseImport() {
    this.setState({ showImport: false, showLog: false });
  }

  onSearchKeyDown(e) {
    if (e.key === "Enter") {
      // console.log('do validate');
      this.onSearchClick();
    }
  }

  onFilterValueChange(value, index) {
    // console.log('value', value);
    let listPageSettings = this.state.listPageSettings;
    let filter = listPageSettings.Filters[index];
    if (filter.Type === "stores") {
      ///
      // console.log('store selected', value);
      if (value !== "") {
        this.loadCashRegister(value);
      }
      //clear cash register
      listPageSettings.Filters.map((m) => {
        if (m.Type === "cashregister") {
          m.Value = "";
        }
      });
    }
    // if()
    listPageSettings.Filters[index].Value = value;
    this.setState({ listPageSettings: listPageSettings });
  }

  showPaginationStatus() {
    //   console.log('this.state.recordFrom', this.state.recordFrom)
    // console.log('totalItemsCount',this.state.totalItemsCount);
    // console.log('this.state.recordFrom ', this.state.recordFrom )
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  renderColumnValue(row, col) {
    // console.log('renderColumnValue col',col)
    // console.log('renderColumnValue row',row)
    let value = "";
    let columns = col.Column.split(".");
    // console.log('columns.length', columns.length)
    if (columns.length === 1) {
      if (row.hasOwnProperty(col.Column)) {
        value = row[col.Column];
      }
    } else if (columns.length === 2) {
      if (row.hasOwnProperty(columns[0])) {
        if (row[columns[0]] && row[columns[0]].hasOwnProperty(columns[1])) {
          value = row[columns[0]][columns[1]];
        }
      }
    }

    let displayValue = "";
    if (col.hasOwnProperty("Type")) {
      if (col.Type === "yesno") {
        if (value) {
          displayValue = "YES";
        } else {
          displayValue = "NO";
        }
      } else if (col.Type === "date" && value && value !== "") {
        displayValue = new Date(value).toString().substring(0, 15);
        // Mon Oct 18 2021 06:34:30
      } else if (col.Type === "datetime" && value && value !== "") {
        displayValue = new Date(value).toString().substring(0, 24);
        // Mon Oct 18 2021 06:34:30
      } else if (col.Type === "number" && value) {
        // console.log('number',value)
        displayValue = value !== 0 ? value : "";
      } else if (col.Type === "date_formatted" && value) {
        // console.log('value', value)
        if (
          value.hasOwnProperty("Day") &&
          value.hasOwnProperty("Month") &&
          value.Day !== "" &&
          value.Month !== ""
        ) {
          displayValue =
            value.Day +
            "/" +
            value.Month +
            (value.hasOwnProperty("Year") && value.Year !== ""
              ? "/" + value.Year
              : "");
        } else {
          displayValue = "";
        }
      } else if (
        col.Type === "image" &&
        value &&
        value.hasOwnProperty("Preview")
      ) {
        displayValue = <img className="list-page-image" src={value.Preview} />;
      } else if (
        col.Type === "image" &&
        value &&
        value.hasOwnProperty("ImagePath")
      ) {
        displayValue = (
          <img className="list-page-image" src={value.ImagePath} />
        );
      } else if (col.Type === "color" && value) {
        displayValue = (
          <div
            className="list-page-color"
            style={{ backgroundColor: value.Color }}
          ></div>
        );
      } else if (col.Type === "text" && value) {
        displayValue = value;
      } else if (col.Type === "badge" && value) {
        let css = "badge ";
        if (col.hasOwnProperty("CSS")) {
          css = css + col.CSS;
        }
        displayValue = <div className={css}>{value}</div>;
      } else {
        displayValue = value;
      }
    } else {
      displayValue = value ? value : "";
    }
    if (displayValue !== "" && col.hasOwnProperty("Label")) {
      displayValue = col.Label + ": " + displayValue;
    }
    //  if(displayValue!==""){
    //      return <div>{displayValue}</div>
    //  }
    return displayValue;
  }

  renderCellData(row, col) {
    // if (col.hasOwnProperty("Type") && col.Type === "accessiblestore") {
    //   if (row.hasOwnProperty("AccessibleToSelectedStores")) {
    //     if (!row.AccessibleToSelectedStores) {
    //       return "All stores";
    //     } else {
    //       if (
    //         row.hasOwnProperty("StoreSpecificPrices") &&
    //         row.StoreSpecificPrices.length > 0
    //       ) {
    //         return (
    //           <React.Fragment>
    //             {row.StoreSpecificPrices.map((m) => (
    //               <div>{m.Store}</div>
    //             ))}
    //           </React.Fragment>
    //         );
    //       }
    //     }
    //   }
    // } else
    if (col.hasOwnProperty("Type") && col.Type === "concatenate") {
      return (
        <div>
          {col.Columns.map((d) => (
            <React.Fragment>
              {this.renderColumnValue(row, d)} &nbsp;
            </React.Fragment>
          ))}
        </div>
      );
    } else if (col.hasOwnProperty("Type") && col.Type === "list") {
      // console.log('list',row[col.Column])
      if (row[col.Column]) {
        if (col.hasOwnProperty("Columns")) {
          return (
            <React.Fragment>
              {row[col.Column].map((a) => (
                <React.Fragment>
                  {col.Columns.map((s) => (
                    <div className="badge">{a[s.Column]}</div>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        }
      }
    } else if (col.Type === "salelineitems") {
      return (
        <React.Fragment>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Product</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {row.LineItems.map((m) => (
                <tr>
                  <td>
                    {m.Product.Name}
                    <div>
                      <i>{m.Notes}</i>
                    </div>
                  </td>
                  <td style={{ textAlign: "right" }}>{m.Qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else if (col.Type === "cashregisterclosingpaymentdetails") {
      return (
        <React.Fragment>
          {row.ClosingPaymentModes && (
            <div>
              {row.ClosingPaymentModes.map((paymentMode) => (
                <React.Fragment>
                  <div style={{ fontSize: "20px" }}>
                    {paymentMode.PaymentMode}
                  </div>
                  {paymentMode.Components.map((c) => (
                    <div>
                      {c.Name} <span className="pull-right">{c.Amount}</span>
                    </div>
                  ))}
                  <div style={{ fontWeight: "bold" }}>
                    Total{" "}
                    <span
                      className="pull-right"
                      style={{ borderTop: "solid 1px" }}
                    >
                      {paymentMode.Amount}
                    </span>
                  </div>
                  <br></br>
                </React.Fragment>
              ))}
              {row.ClosingTotal && (
                <div
                  className="pull-right"
                  style={{ fontSize: "20px", borderTop: "solid 1px" }}
                >
                  {row.ClosingTotal}
                </div>
              )}
              <br></br>
              <br></br>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div
          className={
            col.hasOwnProperty("Type") &&
            col.Type === "number" &&
            !col.hasOwnProperty("Label")
              ? col.Type
              : ""
          }
        >
          {this.renderColumnValue(row, col)}
        </div>
      );
    }
    return;
  }

  renderRow(row, col, index) {
    // console.log('row', row)
    if (isMobileApp() && !col.ShowInMobile) {
      return;
    }
    if (!isMobileApp() && col.ShowInMobile) {
      return;
    }
    if (col.Type === "checkbox") {
      return (
        <React.Fragment>
          {/* <input type="checkbox" checked={row.Selected} onChange={}/> */}
          <Input
            type="checkbox"
            value={row.Selected}
            onChange={() => this.checkboxSelected(index)}
            noMargin={true}
          />
        </React.Fragment>
      );
    } else if (col.Type === "multiple") {
      // console.log('multiple row', row);
      // console.log('multiple col',col)
      return (
        <React.Fragment>
          {col.Columns.map((c) => this.renderCellData(row, c))}
        </React.Fragment>
      );
    } else if (col.Type === "payments") {
      return (
        <React.Fragment>
          {row.Payments.map((payment) => (
            <div>
              {payment.PaymentMode +
                " " +
                ":" +
                " " +
                (payment.PaymentMode === "Cash"
                  ? payment.Amount - row.BillChangeDue
                  : payment.Amount)}
            </div>
          ))}
        </React.Fragment>
      );
    } else if (col.Type === "address") {
      return (
        <React.Fragment>
          {row.Addresses.map((address) => (
            <div>
              <div>
                <b>{address.AddressType}</b>
              </div>
              <div>{address.Address1}</div>
              <div>{address.Address2}</div>
              <div>{address.City}</div>
              <div>{address.State}</div>
              <div>{address.PostalCode}</div>
              <div>{address.Country}</div>
            </div>
          ))}
        </React.Fragment>
      );
    } else if (col.Type === "taxes") {
      return (
        <React.Fragment>
          {row.Taxes.map((tax) => (
            <div>
              {tax.TaxName}-{tax.TaxPercentage}%
            </div>
          ))}
        </React.Fragment>
      );
    }
    // else if(col.Type==="time" )
    // {
    //     return <React.Fragment>
    //         {/* <div>{AMPM}</div> */}
    //     </React.Fragment>
    // }
    else {
      return (
        <React.Fragment>
          {col.Columns.map((c) => this.renderCellData(row, c))}
        </React.Fragment>
      );
    }
  }

  renderDeleteWindow() {
    return (
      <React.Fragment>
        {this.state.showDelete && (
          <Window
            header={"Delete"}
            height={"200px"}
            modal={true}
            processing={this.state.deleting}
            onClose={() => this.setState({ showDelete: false })}
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "yes") {
                this.deleteRecord();
              } else {
                this.setState({ showDelete: false, selectedRow: null });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "No", Align: "left" },
              { Type: "primary", Text: "Yes", Align: "right" },
            ]}
          >
            <div>Are you sure you wish to delete this record?</div>
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderCancelWindow() {
    return (
      <React.Fragment>
        {this.state.showCancel && (
          <Window
            header={"Cancellation Remarks"}
            height={"200px"}
            modal={true}
            processing={this.state.canceling}
            onClose={() => this.setState({ showCancel: false })}
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "save") {
                this.cancelRecord();
              } else {
                this.setState({ showCancel: false, selectedRow: null });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <Input
              type="textarea"
              value={this.state.cancellationReason}
              onChange={(value) =>
                this.setState({
                  cancellationReason: value,
                  cancellationReasonError: "",
                })
              }
              error={this.state.cancellationReasonError}
            />
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderRowButtons(index, l) {
    let IsDeleted = l.hasOwnProperty("IsDeleted") ? l.IsDeleted : false;

    return (
      <React.Fragment>
        {this.state.listPageSettings.RowButtons.length > 0 && (
          <td key={"rowBtns" + index}>
            {!IsDeleted && (
              <div className="list-page-row-btns">
                {this.state.listPageSettings.RowButtons.map((button, index) => (
                  <React.Fragment>
                    {button.ButtonType.toLowerCase() === "button" && (
                      <Button
                        type={button.Type}
                        text={button.Text}
                        label={button.Text}
                        icon={button.Icon}
                        onClick={() => this.onRowButtonClicked(button, l)}
                      />
                    )}
                    {button.ButtonType.toLowerCase() === "imagebutton" && (
                      <ImageButton
                        type={button.Type}
                        label={button.Text}
                        onClick={() => this.onRowButtonClicked(button, l)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </td>
        )}
      </React.Fragment>
    );
  }

  renderListSearch() {
    return (
      <div className="list-page-search">
        <input
          type="text"
          className="form-control"
          placeholder={this.state.listPageSettings.SearchPlaceHolder}
          value={this.state.searchText}
          onKeyDown={this.onSearchKeyDown}
          onChange={(e) => this.setState({ searchText: e.target.value })}
        />
        <div className="list-page-search-btn-grp">
          <ImageButton
            type="search"
            text="Search"
            onClick={this.onSearchClick}
          />
          <ImageButton type="remove" text="Clear" onClick={this.onClearClick} />
        </div>
      </div>
    );
  }

  renderFilterControl(f, index) {
    if (f.Type === "status") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="active">Active</option>
          <option value="inactive">In Active</option>
          <option value="all">All</option>
        </Input>
      );
    } else if (f.Type === "approvalstatus") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="all">All</option>
          <option value="Pending First Approval">Pending First Approval</option>
          <option value="Pending Final Approval">Pending Final Approval</option>
          <option value="In Transit">In Transit</option>
          <option value="Completed">Completed</option>
        </Input>
      );
    } else if (f.Type === "dropdown") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          {f.Options.map((o) => (
            <option value={o.Value}>{o.Text}</option>
          ))}
        </Input>
      );
    } else if (f.Type === "producttype") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          <option value={"Goods for sale"}>Goods for sale</option>
          <option value={"Goods not for sale"}>Goods not for sale</option>
          <option value={"Services"}>Services</option>
          <option value={"Wallet Recharge"}>Wallet Recharge</option>
          <option value={"Package"}>Package</option>
        </Input>
      );
    } else if (f.Type === "stores") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          {this.state.stores.length > 0 && <option value="">All</option>}
          {this.state.stores.map((m) => (
            <option value={m.key}>{m.StoreName}</option>
          ))}
        </Input>
      );
    } else if (f.Type === "cashregister") {
      if (
        this.state.listPageSettings.Filters.filter(
          (f) => f.Type === "stores" && f.Value !== ""
        )
      ) {
        return (
          <Input
            type={"select"}
            label={f.Name}
            value={f.Value}
            onEnter={this.onSearchClick}
            onChange={(value) => {
              this.onFilterValueChange(value, index);
            }}
          >
            {this.state.cashRegisters.length > 0 && (
              <option value="">All</option>
            )}
            {this.state.cashRegisters.map((m) => (
              <option value={m.key}>
                {m.CashRegisterCode} {m.CashRegisterName}
              </option>
            ))}
          </Input>
        );
      }
    } else if (f.Type === "salestransactiontype") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          {this.props.config.TransactionTypes.filter(
            (e) => e.Module === "sales" && !e.IsDeleted
          ).map((e) => (
            <option value={e.key}>{e.TransactionType}</option>
          ))}
        </Input>
      );
    } else if (f.Type === "inventorytransactiontype") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          {this.props.config.TransactionTypes.filter(
            (e) => e.Module === "inventory" && !e.IsDeleted
          ).map((e) => (
            <option value={e.key}>{e.TransactionType}</option>
          ))}
        </Input>
      );
    } else if (f.Type === "daterange") {
      return (
        <React.Fragment>
          <Input
            type={"date"}
            label={"From Date"}
            value={f.Value.FromDate}
            onEnter={this.onSearchClick}
            onChange={(value) => {
              let val = f.Value;
              val.FromDate = value;
              this.onFilterValueChange(val, index);
            }}
          />
          <Input
            type={"date"}
            label={"To Date"}
            value={f.Value.ToDate}
            onEnter={this.onSearchClick}
            onChange={(value) => {
              let val = f.Value;
              val.ToDate = value;
              this.onFilterValueChange(val, index);
            }}
          />
        </React.Fragment>
      );
    } else if (f.Type == "attendanceType") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          <option value="Present">Present</option>
          <option value="Half Day">Half Day</option>
          <option value="Leave">Leave</option>
          <option value="Week-Off">Week-Off</option>
        </Input>
      );
    } else if (f.Type === "expensestatus") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          <option value="pending">Pending</option>
          <option value="paid">Paid</option>
          <option value="cancelled">Cancelled</option>
        </Input>
      );
    } else if (f.Type === "paymenttypeexpense") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          {this.props.config.PaymentTypes.filter(
            (e) => e.ShowInExpense && !e.IsDeleted
          ).map((e) => (
            <option value={e.key}>{e.PaymentType}</option>
          ))}
        </Input>
      );
    } else if (f.Type === "account") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          <optgroup label="Cash Register">
            {this.state.cashRegisters.map((cashRegister) => (
              <option value={cashRegister.key}>
                {cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}
              </option>
            ))}
          </optgroup>
          <optgroup label="Account">
            {this.state.accountList.map((account) => (
              <option value={account.key}>{account.Name}</option>
            ))}
          </optgroup>
        </Input>
      );
    } else if (f.Type === "category") {
      return (
        <Input
          type={"select"}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        >
          <option value="">All</option>
          {this.props.config.ProductCategories.filter(
            (e) => e.ParentID === "" && !e.IsDeleted
          ).map((e) => (
            <option value={e.key}>{e.Name}</option>
          ))}
        </Input>
      );
    } else {
      return (
        <Input
          type={f.Type}
          label={f.Name}
          value={f.Value}
          onEnter={this.onSearchClick}
          onChange={(value) => {
            this.onFilterValueChange(value, index);
          }}
        />
      );
    }
  }

  renderFilter() {
    return (
      <React.Fragment>
        {this.state.showFilter && (
          <div
            className={"list-page-filter-overlay"}
            onClick={() => this.onCloseFilter()}
          />
        )}

        <div
          className={
            this.state.showFilter
              ? "list-page-filter list-page-filter-show"
              : "list-page-filter"
          }
          // className="list-page-filter"
          style={{ width: isMobileApp() ? "80%" : "460px" }}
        >
          <div
            className="list-page-filter-close-button"
            onClick={() => this.onCloseFilter()}
          >
            <ImageButton type="remove" onClick={() => this.onCloseFilter()} />
          </div>
          <div className="list-page-filter-container">
            <div className="list-page-filter-header">Filter</div>
            <div
              className="list-page-filter-content"
              style={{ height: window.innerHeight - 110 + "px" }}
            >
              {this.state.listPageSettings.Filters.map((f, index) =>
                this.renderFilterControl(f, index)
              )}
            </div>
            <div className="list-page-filter-footer">
              <Button
                type="secondary"
                text="Clear"
                onClick={this.onClearClick}
              />
              <Button
                type="primary"
                text="Search"
                onClick={this.onSearchClick}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderOptions() {
    return (
      <React.Fragment>
        {this.state.showOptions && (
          <div
            className={"list-page-filter-overlay"}
            onClick={() => this.onCloseOptions()}
          />
        )}

        <div
          className={
            this.state.showOptions
              ? "list-page-filter list-page-filter-show"
              : "list-page-filter"
          }
          style={{ width: isMobileApp() ? "80%" : "460px" }}
        >
          <div
            className="list-page-filter-close-button"
            onClick={() => this.onCloseOptions()}
          >
            <ImageButton type="remove" onClick={() => this.onCloseOptions()} />
          </div>
          <div className="list-page-filter-container">
            <div className="list-page-filter-header">Options</div>
            <div
              className="list-page-filter-content"
              style={{ height: window.innerHeight - 110 + "px" }}
            >
              {this.state.listPageSettings.Options.map((f, index) => (
                <Button
                  type="secondary"
                  text={f.Text}
                  onClick={() => this.onOptionsButtonClick(f)}
                />
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderImportWindow() {
    return (
      <React.Fragment>
        {(this.state.showImport || this.state.showLog) && (
          <div
            className={"list-page-filter-overlay"}
            onClick={() => this.onCloseImport()}
          />
        )}

        <div
          className={
            this.state.showImport || this.state.showLog
              ? "list-page-import list-page-import-show"
              : "list-page-import"
          }
          style={{ width: isMobileApp() ? "90%" : "460px" }}
        >
          <div
            className="list-page-filter-close-button"
            onClick={() => this.onCloseImport()}
          >
            <ImageButton type="remove" onClick={() => this.onCloseImport()} />
          </div>
          <div className="list-page-import-container">
            {this.state.showImport && (
              <React.Fragment>
                {this.props.registration.registration.PincodeIntegration && (
                  <ImportProduct
                    {...this.props}
                    importType={this.state.importType}
                    onClose={() => this.onCloseImport()}
                    onImportCompleted={() => {
                      this.getData(
                        this.state.searchText,
                        this.state.listPageSettings.Filters,
                        this.state.listPageSettings.Sort,
                        this.state.recordFrom,
                        this.state.itemsCountPerPage,
                        this.state.listPageSettings.API
                      );
                    }}
                  />
                )}
                {!this.props.registration.registration.PincodeIntegration && (
                  <ImportData
                    {...this.props}
                    importType={this.state.importType}
                    onClose={() => this.onCloseImport()}
                    onImportCompleted={() => {
                      this.getData(
                        this.state.searchText,
                        this.state.listPageSettings.Filters,
                        this.state.listPageSettings.Sort,
                        this.state.recordFrom,
                        this.state.itemsCountPerPage,
                        this.state.listPageSettings.API
                      );
                    }}
                  />
                )}
              </React.Fragment>
            )}

            {this.state.showLog && (
              <ImportLog
                {...this.props}
                importType={this.state.importType}
                onClose={() => this.onCloseImport()}
              />
            )}

            {/* </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPopup() {
    return (
      <React.Fragment>
        {isMobileApp() && (
          <React.Fragment>{this.renderPopupControls()}</React.Fragment>
        )}

        {!isMobileApp() && (
          <React.Fragment>
            {this.state.showPopup && (
              <div
                className={"list-page-filter-overlay"}
                onClick={() => this.onClosePopup()}
              />
            )}
            <div
              className={
                this.state.showPopup
                  ? "list-page-import list-page-import-show"
                  : "list-page-import"
              }
            >
              <div
                className="list-page-filter-close-button"
                onClick={() => this.onCloseImport()}
              >
                <ImageButton
                  type="remove"
                  onClick={() => this.onClosePopup()}
                />
              </div>
              <div className="list-page-import-container">
                {this.renderPopupControls()}
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderPopupControls() {
    return (
      <React.Fragment>
        {this.state.showPopup && this.state.popupType === "paybill" && (
          <PayBill
            {...this.props}
            selectedRecord={this.state.selectedRow}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "complaints" && (
          <PincodeIssue
            {...this.props}
            selectedRecord={this.state.selectedRow}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "product" && (
          <ProductQuickAdd
            {...this.props}
            product={this.state.selectedRow}
            onSave={(keepwindowOpen) => this.onPostPopupSave(keepwindowOpen)}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "category" && (
          <AddEditProductCategory
            {...this.props}
            productCategory={this.state.selectedRow}
            parentID=""
            parent=""
            type={actionTypes.PRODUCT_CATEGORY}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "subcategory" && (
          <AddEditProductCategory
            {...this.props}
            productCategory={this.state.selectedRow}
            parentID=""
            parent=""
            type={actionTypes.PRODUCT_SUB_CATEGORY}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "brand" && (
          <AddEditProductBrand
            {...this.props}
            productBrand={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "modifier" && (
          <AddEditModifier
            {...this.props}
            modifier={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "productgroup" && (
          <AddEditProductTag
            {...this.props}
            productGroup={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "tax" && (
          <AddEditProductTax
            {...this.props}
            action={this.state.action}
            productTax={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup &&
          this.state.popupType === "captureattendance" && (
            <AddAttendance
              {...this.props}
              attendance={this.state.selectedRow}
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup && this.state.popupType === "modifiergroup" && (
          <AddEditModifierGroup
            {...this.props}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "employee" && (
          <AddEditEmployee
            {...this.props}
            employee={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "expense" && (
          <Expense
            {...this.props}
            expense={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "expensecategory" && (
          <AddEditExpenseCategory
            {...this.props}
            expenseCategory={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "account" && (
          <AddEditAccount
            {...this.props}
            account={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup &&
          this.state.popupType === "accountmanagement" && (
            <AddTransaction
              {...this.props}
              transaction={this.state.selectedRow}
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup &&
          this.state.popupType === "employeesystemaccess" && (
            <EmployeeAccess
              {...this.props}
              employee={this.state.selectedRow}
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup &&
          this.state.popupType === "employeeservicemapping" && (
            <EmployeeServiceMapping
              {...this.props}
              employee={this.state.selectedRow}
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup && this.state.popupType === "membership" && (
          <NewMembership
            {...this.props}
            membership={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "saleschannel" && (
          <NewSalesChanel
            {...this.props}
            record={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}
        {this.state.showPopup && this.state.popupType === "campaign" && (
          <AddCampaign
            {...this.props}
            campaign={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "paymenttypes" && (
          <AddEditPaymentTypes
            {...this.props}
            paymentType={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "customroles" && (
          <AddEditCustomRoles
            {...this.props}
            customRole={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "moduleconfig" && (
          <AddEditTransactiontype
            {...this.props}
            transactionType={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup &&
          this.state.popupType === "transactionalsmstemplates" && (
            <SMS
              {...this.props}
              sms={this.state.selectedRow}
              smsType="Transactional"
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup &&
          this.state.popupType === "promotionalsmstemplates" && (
            <SMS
              {...this.props}
              sms={this.state.selectedRow}
              smsType="Promotional"
              onSave={() => this.onPostPopupSave()}
              onClose={() => this.onClosePopup()}
            />
          )}

        {this.state.showPopup && this.state.popupType === "offers" && (
          <AddEditOffer
            {...this.props}
            selectedRecord={this.state.selectedRow}
            type="offers"
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "coupons" && (
          <AddEditOffer
            {...this.props}
            selectedRecord={this.state.selectedRow}
            type="coupons"
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "bom" && (
          <AddEditBOM
            {...this.props}
            selectedRecord={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "omnipage" && (
          <OmniPageEdit
            {...this.props}
            selectedRecord={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup && this.state.popupType === "omnislider" && (
          <OmniSlider
            {...this.props}
            selectedRecord={this.state.selectedRow}
            onSave={() => this.onPostPopupSave()}
            onClose={() => this.onClosePopup()}
          />
        )}

        {this.state.showPopup &&
          this.state.popupType === "parkedinventory" &&
          this.renderInventoryParkedTransacitons()}
      </React.Fragment>
    );
  }

  renderInventoryParkedTransacitons() {
    return (
      <Form
        title="Parked Transaction(s)"
        onClick={(action) => {
          this.onClosePopup();
        }}
        showFooter={false}
      >
        {this.state.inventoryDrafts.map((draftTransaction, index) => (
          <div
            className="info"
            key={index}
            onClick={() => {
              this.props.deleteDraftTransaction(draftTransaction.key);
              this.props.history.push({
                pathname: "/home/inventory/inventory/",
                state: {
                  type: actionTypes.INVENTORY_PARKED,
                  key: "",
                  transactionType: draftTransaction.Transaction.TransactionType,
                  inventory: draftTransaction.Transaction,
                },
              });
            }}
          >
            {draftTransaction.ActionOn && (
              <div className="info-header">
                {new Date(draftTransaction.ActionOn.seconds * 1000)
                  .toString()
                  .substring(0, 24)}
              </div>
            )}
            <div className="info-text">
              Qty: {draftTransaction.Transaction.BillQty}
            </div>
            <div className="info-text">
              Amount: {draftTransaction.Transaction.BillAmount}
            </div>
          </div>
        ))}
      </Form>
    );
    // <React.Fragment>
    //             <div className="import-header">
    //                 <div>Parked Transaction(s)</div>
    //             </div>
    //             <div className="import-content">
    //                 {this.state.inventoryDrafts.map((draftTransaction,index) => <div className="info" key={index}
    //                 onClick={()=>{
    //                     this.props.deleteDraftTransaction(draftTransaction.key)
    //                     this.props.history.push({
    //                         pathname: '/home/inventory/inventory/',
    //                         state: {
    //                             type: actionTypes.INVENTORY_PARKED,
    //                             key: '',
    //                             transactionType: draftTransaction.Transaction.TransactionType,
    //                             inventory: draftTransaction.Transaction
    //                         }
    //                     })
    //                 }}
    //                 >
    //                         {draftTransaction.ActionOn && <div className="info-header">{(new Date(draftTransaction.ActionOn.seconds*1000)).toString().substring(0,24)}</div>}
    //                         <div className="info-text">Qty: {draftTransaction.Transaction.BillQty}</div>
    //                         <div className="info-text">Amount: {draftTransaction.Transaction.BillAmount}</div>
    //                 </div>)}
    //             </div>

    // </React.Fragment>
  }

  renderPopupWindow() {
    return (
      <React.Fragment>
        {this.state.showPopupWindow && (
          <Window
            header={""}
            height={"200px"}
            modal={true}
            onClose={() => this.setState({ showPopupWindow: false })}
            buttons={[]}
          >
            {this.state.popupType === "newsales" && this.renderNewSalesPopup()}
            {this.state.popupType === "convertsales" &&
              this.renderConvertSales()}
            {this.state.popupType === "convertinventory" &&
              this.renderConvertInventory()}
            {this.state.popupType === "newinventory" &&
              this.renderNewInventoryPopup()}
            {this.state.popupType === "mobilerowoptions" &&
              this.renderMobileRowOptions()}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderMobileRowOptions() {
    return (
      <React.Fragment>
        {this.state.listPageSettings.RowButtons.map((button, index) => (
          <React.Fragment>
            {/* {button.ButtonType.toLowerCase() ==="button" &&  */}
            <Button
              type="secondary"
              text={button.Text}
              label={button.Text}
              icon={button.Icon}
              onClick={() =>
                this.onRowButtonClicked(button, this.state.selectedRow)
              }
            />
            {/* } */}
            {/* {button.ButtonType.toLowerCase() ==="imagebutton" && <ImageButton type={button.Type} label={button.Text} onClick={()=>this.onRowButtonClicked(button, l)}/>} */}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  renderNewSalesPopup() {
    return (
      <React.Fragment>
        {this.state.salesTransactionTypes.map((e) => (
          <Button
            text={"New " + e.TransactionType}
            type="secondary"
            icon="add"
            onClick={() => {
              this.props.history.push({
                pathname: "/home/sales/sales/",
                state: { transactionType: e, type: actionTypes.SALE_NEW },
              });
            }}
          />
        ))}
      </React.Fragment>
    );
  }

  renderNewInventoryPopup() {
    return (
      <React.Fragment>
        {/* {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted && e.TransactionType.toLowerCase() !=="transfer in").map((e) => 
            <Button text={"New "  + e.TransactionType} type="secondary" icon="add"
            onClick={()=>{this.props.history.push({pathname:"/home/inventory/inventory/",state: {transactionType: e,type:actionTypes.INVENTORY_NEW}})}}
            />)} */}

        {this.state.inventoryTransactionTypes.map((e) => (
          <Button
            text={"New " + e.TransactionType}
            type="secondary"
            icon="add"
            onClick={() => {
              this.props.history.push({
                pathname: "/home/inventory/inventory/",
                state: { transactionType: e, type: actionTypes.INVENTORY_NEW },
              });
            }}
          />
        ))}
      </React.Fragment>
    );
  }

  renderConvertSales() {
    return (
      <React.Fragment>
        {/* {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted && e.TransactionType.toLowerCase().trim() !== "receive payment").map((e) => 
            <Button text={"New "  + e.TransactionType} type="secondary" icon="add"
            onClick={()=>{ this.onConvertTransaction(e,false)}}
            />)} */}

        {this.state.salesTransactionTypes
          .filter(
            (e) =>
              e.Module === "sales" &&
              !e.IsDeleted &&
              e.TransactionType.toLowerCase().trim() !== "receive payment"
          )
          .map((e) => (
            <Button
              text={"New " + e.TransactionType}
              type="secondary"
              icon="add"
              onClick={() => {
                this.onConvertTransaction(e, false);
              }}
            />
          ))}
      </React.Fragment>
    );
  }

  renderConvertInventory() {
    return (
      <React.Fragment>
        {/* {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted && e.TransactionType.toLowerCase() !=="transfer in").map((e) => 
            <Button text={"New "  + e.TransactionType} type="secondary" icon="add"
            onClick={()=>{ this.onConvertTransaction(e,false)}}
            />)} */}
        {this.state.inventoryTransactionTypes
          .filter(
            (e) =>
              e.Module === "inventory" &&
              !e.IsDeleted &&
              e.TransactionType.toLowerCase() !== "transfer in"
          )
          .map((e) => (
            <Button
              text={"New " + e.TransactionType}
              type="secondary"
              icon="add"
              onClick={() => {
                this.onConvertTransaction(e, false);
              }}
            />
          ))}
      </React.Fragment>
    );
  }

  checkboxSelected(index) {
    let list = this.state.list;
    if (index === -1) {
      let selectAll = !this.state.selectAll;
      list.map((l) => {
        l.Selected = selectAll;
      });
      this.setState({ selectAll: selectAll, list: list });
    } else {
      if (index < list.length) {
        list[index].Selected = !list[index].Selected;
        this.setState({ list: list });
      }
    }
    // check if at least one row is selected
    if (list.filter((f) => f.Selected).length > 0) {
      this.setState({ showSelectionOption: true });
    } else {
      this.setState({ showSelectionOption: false });
    }
  }

  async onConvertTransaction(transactionType, copy = false) {
    // console.log('onConvertTransaction')

    // let selectedRecordsTemp = this.state.list.filter((f) => f.Selected);
    // let selectedRecords =[];
    // for (let k = 0; k < selectedRecordsTemp.length; k++) {//get data from firestore
    //     // console.log('selectedRecordsTemp[k]', selectedRecordsTemp[k])
    //     let record = await this.props.getSaleDetails(selectedRecordsTemp[k].key)
    //     // console.log('record',record)
    //     record.key = selectedRecordsTemp[k].key
    //     selectedRecords.push(record)
    // }
    // console.log("selectedRecords",selectedRecords);
    // for (let l = 0; l < selectedRecords.length; l++){
    //     let sale = selectedRecords[l];
    //     sale.BackEndUpdate=true;
    //     sale.RegistrationID = sale.RegistrationID + "_deleted";
    //     sale.Store.key =sale.Store.key + "_deleted";
    //     await this.props.updateSales(sale,sale.key);
    //     console.log("sale",sale)
    //     console.log("deleted", l);
    // }
    // return;
    if (
      (transactionType && transactionType.Module.toLowerCase() === "sales") ||
      copy
    ) {
      //get selected records
      let selectedRecords = [];
      let selectedRecordsTemp = [];
      let firstRecordTransactionType = null;
      let firstRecordCustomer = null;
      let firstRecord = false;
      let firstRecordData = null;
      let paymentReceivedForSelectedTransaction = false;
      let receivedPaymentAmount = 0;
      let convertedFromTransactionBalanceAmount = 0;
      selectedRecordsTemp = this.state.list.filter((f) => f.Selected);
      if (selectedRecordsTemp.length === 0) {
        alert("Please select record(s).");
        return;
      }
      for (let k = 0; k < selectedRecordsTemp.length; k++) {
        //get data from firestore
        // console.log('selectedRecordsTemp[k]', selectedRecordsTemp[k])
        let record = await this.props.getSaleDetails(
          selectedRecordsTemp[k].key
        );
        // console.log('record',record)
        record.key = selectedRecordsTemp[k].key;
        selectedRecords.push(record);
      }

      for (let i = 0; i < selectedRecords.length; i++) {
        let record = selectedRecords[i];
        // console.log('record',record)
        if (!firstRecord) {
          firstRecordData = selectedRecords[i];
          firstRecordTransactionType = record.TransactionType;
          firstRecordCustomer = record.Customer;
          firstRecord = true;
          if (
            transactionType &&
            transactionType.TransactionType ===
              firstRecordTransactionType.TransactionType
          ) {
            alert(
              "Transaction can not be converted to same type. Please select different transaction types."
            );
            return;
          }
        } else {
          if (
            record.TransactionType.TransactionType !==
            firstRecordTransactionType.TransactionType
          ) {
            alert("Selected records should be of same transaction type");
            return;
          }
          if (!firstRecordCustomer && record.Customer) {
            alert("Selected records should have same customer");
            return;
          }
          if (firstRecordCustomer && !record.Customer) {
            alert("Selected records should have same customer");
            return;
          }
          // if(!billWiseDetail.ChildTransactionNo && !billWiseDetail.SalesReturnTransactionNo && !billWiseDetail.CancellationReason )
          if (firstRecordCustomer && record.Customer) {
            if (firstRecordCustomer.key !== record.Customer.key) {
              alert("Selected records should have same customer");
              return;
            }
          }
        }
        if (!copy && record.hasOwnProperty("ChildTransactionNo")) {
          if (record.ChildTransactionNo.trim() !== "") {
            alert("#" + record.TransactionNo + " is already converted");
            return;
          }
        }
        if (record.Payments && record.Payments.length > 0) {
          receivedPaymentAmount = record.BillPaidAmount;
          convertedFromTransactionBalanceAmount = record.BalanceAmount;
          // console.log('convertedFromTransactionBalanceAmount', convertedFromTransactionBalanceAmount)
          paymentReceivedForSelectedTransaction = true;
        }
      }
      if (
        !copy &&
        paymentReceivedForSelectedTransaction &&
        selectedRecords.length > 1
      ) {
        alert(
          "Payment collected for one or more transaction. Please select only one transaction at a time to convert."
        );
        return;
      }
      //all checksout well...so lets create a new sales that conatains all these records.
      let sale = {
        TransactionType: firstRecordTransactionType,
        Customer: firstRecordCustomer,
        RegistrationID: this.props.user.user.RegistrationID,
        CashRegister: null,
        Store: this.props.user.store,
        LineItems: [],
        BillQty: 0,
        BillSubTotal: 0,
        BillDiscount: 0,
        BillTaxAmount: 0,
        BillAmount: 0,
        RoundOff: 0,
        BillBalanceAmount: 0,
        BillPaidAmount: 0,
        BillChangeDue: 0,
        Payments: null,
        ParentTransactionNo: "",
        ParentTransactionKey: "",
        Employee: null,
        Notes: "",
        TaxSummaryList: [],
        PlaceOfSupply: "",
        BillingAddress: null,
        DeliveryCustomer: null,
        DeliveryCustomerAddress: null,
        DeliveryDate: "",
        DeliveryTime: "",
        deliveryNotes: "",
        BackEndUpdate: false,
        ReceivedPaymentAmount: !copy ? receivedPaymentAmount : 0,
        ConvertedFromTransactionBalanceAmount: !copy
          ? convertedFromTransactionBalanceAmount
          : 0,
        IsConvert: !copy,
        //  true
        // IsConvert:paymentReceivedForSelectedTransaction
      };
      if (paymentReceivedForSelectedTransaction && !copy) {
        //e.g sales order to sales invoice in which payment was received during sales order
        // sale.IsConvert = paymentReceivedForSelectedTransaction
        sale.ReceivedPaymentTransactions = [];
        sale.ReceivedPaymentTransactions.push({
          ReceiveAmount: receivedPaymentAmount,
          //ReceivePaymentDate:firstRecord.TransactionDate,
          ReceivePaymentDate: null,
          ReceivePaymentKey: firstRecordData.key,
          ReceivePaymentNo: firstRecordData.TransactionNo,
          IsConvert: true,
        });
      }
      for (let i = 0; i < selectedRecords.length; i++) {
        let record = selectedRecords[i];
        sale.CustomField1 = record.hasOwnProperty("CustomField1")
          ? record.CustomField1
          : "";
        sale.CustomField2 = record.hasOwnProperty("CustomField2")
          ? record.CustomField2
          : "";
        sale.CustomField3 = record.hasOwnProperty("CustomField3")
          ? record.CustomField3
          : "";
        sale.CustomField4 = record.hasOwnProperty("CustomField4")
          ? record.CustomField4
          : "";
        sale.CustomField5 = record.hasOwnProperty("CustomField5")
          ? record.CustomField5
          : "";
        sale.CustomField6 = record.hasOwnProperty("CustomField6")
          ? record.CustomField6
          : "";
        sale.CustomField7 = record.hasOwnProperty("CustomField7")
          ? record.CustomField7
          : "";
        sale.CustomField8 = record.hasOwnProperty("CustomField8")
          ? record.CustomField8
          : "";
        sale.CustomField9 = record.hasOwnProperty("CustomField9")
          ? record.CustomField9
          : "";
        sale.CustomField10 = record.hasOwnProperty("CustomField10")
          ? record.CustomField10
          : "";
        sale.CustomField11 = record.hasOwnProperty("CustomField11")
          ? record.CustomField11
          : "";
        sale.CustomField12 = record.hasOwnProperty("CustomField12")
          ? record.CustomField12
          : "";
        sale.CustomField13 = record.hasOwnProperty("CustomField13")
          ? record.CustomField13
          : "";
        sale.CustomField14 = record.hasOwnProperty("CustomField14")
          ? record.CustomField14
          : "";
        sale.CustomField15 = record.hasOwnProperty("CustomField15")
          ? record.CustomField15
          : "";
        sale.CustomField16 = record.hasOwnProperty("CustomField16")
          ? record.CustomField16
          : "";
        sale.CustomField17 = record.hasOwnProperty("CustomField17")
          ? record.CustomField17
          : "";
        sale.CustomField18 = record.hasOwnProperty("CustomField18")
          ? record.CustomField18
          : "";
        sale.CustomField19 = record.hasOwnProperty("CustomField19")
          ? record.CustomField19
          : "";
        sale.CustomField20 = record.hasOwnProperty("CustomField20")
          ? record.CustomField20
          : "";
        record.LineItems.map((l) => {
          if (!copy) {
            l.ParentTransactionNo = record.TransactionNo;
            l.ParentTransactionKey = record.key;
            l.ParentTransactionType = record.TransactionType.TransactionType;
            l.Notes = record.TransactionNo;
          }
          sale.LineItems.push(l);
        });
      }
      if (firstRecordTransactionType.hasOwnProperty("UpdateStockChecked")) {
        if (firstRecordTransactionType.UpdateStockChecked && !copy) {
          firstRecordTransactionType.UpdateStockChecked = false; //donot update stock again
        }
      }
      // console.log('firstRecordTransactionType ', firstRecordTransactionType)
      // console.log('sale ', sale)
      this.props.history.push({
        pathname: "/home/sales/sales/",
        state: {
          type: actionTypes.SALE_NEW,
          key: "",
          transactionType: copy ? firstRecordTransactionType : transactionType,
          sale: sale,
        },
      });
    } else if (
      transactionType &&
      transactionType.Module.toLowerCase() === "inventory"
    ) {
      let selectedRecords = [];
      let selectedRecordsTemp = [];
      let firstRecord = false;
      let firstRecordData = null;
      selectedRecordsTemp = this.state.list.filter((f) => f.Selected);

      if (selectedRecordsTemp.length === 0) {
        alert("Please select record(s).");
        return;
      }
      for (let i = 0; i < selectedRecordsTemp.length; i++) {
        let record = selectedRecordsTemp[i];
        // console.log('record',record)
        // console.log('record',record)
        if (!firstRecord) {
          firstRecordData = record;
          // firstRecordTransactionType = record.TransactionType
          // firstRecordVendor = record.Vendor
          firstRecord = true;
          if (
            transactionType.TransactionType ===
            firstRecordData.TransactionType.TransactionType
          ) {
            alert(
              "Transaction can not be converted to same type. Please select different transaction types."
            );
            return;
          }
        } else {
          if (
            record.TransactionType.TransactionType !==
            firstRecordData.TransactionType.TransactionType
          ) {
            alert("Selected records should be of same transaction type");
            return;
          }
          if (!firstRecordData.Vendor && record.Vendor) {
            alert("Selected records should have same vendor");
            return;
          }
          if (firstRecordData.Vendor && !record.Vendor) {
            alert("Selected records should have same vendor");
            return;
          }
          if (firstRecordData.ToStoreID !== record.ToStoreID) {
            alert("Selected records should have same To Store");
            return;
          }
          // if(!billWiseDetail.ChildTransactionNo && !billWiseDetail.SalesReturnTransactionNo && !billWiseDetail.CancellationReason )
          if (firstRecordData.Vendor && record.Vendor) {
            if (firstRecordData.Vendor.key !== record.Vendor.key) {
              alert("Selected records should have same vendor");
              return;
            }
          }
        }
        if (record.Status.toLowerCase() === "in transit") {
          alert("In transit records can not be coneverted");
          return;
        }
        if (record.Status.toLowerCase() !== "completed") {
          alert("Only completed record(s) can be coneverted");
          return;
        }
      }

      let inventory = {
        TransactionType: transactionType,
        Vendor: firstRecordData.hasOwnProperty("Vendor")
          ? firstRecordData.Vendor
          : null,
        RegistrationID: this.props.user.user.RegistrationID,
        Store: this.props.user.store,
        LineItems: [],
        BillQty: 0,
        BillSubTotal: 0,
        BillDiscount: 0,
        BillTaxAmount: 0,
        BillAmount: 0,
        ParentTransactionNo: "",
        ParentTransactionKey: "",
        Notes: "",
        TaxSummaryList: null,
        HSNSummary: [],
        PlaceOfSupply: firstRecordData.hasOwnProperty("PlaceOfSupply")
          ? firstRecordData.PlaceOfSupply
          : "",
        StoreLocation: "",
        StoreLocationID: "",
        RefNo: "",
        BackEndUpdate: false,
        Status: "Completed",
        ToStoreID: firstRecordData.hasOwnProperty("ToStoreID")
          ? firstRecordData.ToStoreID
          : "",
        ToStore: firstRecordData.hasOwnProperty("ToStore")
          ? firstRecordData.ToStore
          : "",
        ChildTransactionNo: "",
        ChildTransactionKey: "",
        BillLineItemTotal: 0,
        BillRoundOff: 0,
        BillAdditionalCharges: 0,
        BillAdditionalDiscount: 0,
      };

      inventory.ParentTransactions = [];
      let refNo = "";
      for (let k = 0; k < selectedRecordsTemp.length; k++) {
        //get data from firestore
        let recordToBeConverted = false;
        let record = await this.props.getInventoryDetails(
          selectedRecordsTemp[k].key
        );
        record.key = selectedRecordsTemp[k].key;
        // selectedRecords.push(record)
        inventory.ParentTransactionNo =
          (inventory.ParentTransactionNo.trim() !== "" ? "," : "") +
          record.TransactionNo;
        inventory.ParentTransactionKey =
          (inventory.ParentTransactionKey.trim() !== "" ? "," : "") +
          record.key;
        record.LineItems.map((l, index) => {
          let lineItem = {};
          let parentTransactionRemainingQty = l.hasOwnProperty("RemainingQty")
            ? l.RemainingQty
            : l.Qty;
          if (parentTransactionRemainingQty > 0) {
            lineItem.LineItemQty = parentTransactionRemainingQty;
            lineItem.FreeQty = 0;
            lineItem.Qty = parentTransactionRemainingQty;
            lineItem.SupplyPrice = l.SupplyPrice;
            lineItem.SubTotal = 0;
            lineItem.ChargeTaxOnProduct = l.ChargeTaxOnProduct;
            lineItem.PriceBasedTax = l.PriceBasedTax;
            lineItem.HSNSACCode = l.HSNSACCode;
            lineItem.TaxInclusive = l.TaxInclusive;
            lineItem.TaxGroup = l.TaxGroup;
            lineItem.TaxID = l.TaxID;
            lineItem.DiscountPer = 0;
            lineItem.DiscountAmount = 0;
            lineItem.SubTotalWithDiscount = 0;
            lineItem.TaxableAmount = 0;
            lineItem.TotalTax = 0;
            lineItem.Amount = 0;
            lineItem.Product = l.Product;
            lineItem.Notes = record.TransactionNo;
            lineItem.UniqueNoList = [];
            lineItem.InStockQty = 0;
            lineItem.ParentTransactionNo = record.TransactionNo;
            lineItem.ParentTransactionKey = record.key;
            lineItem.ParentTransactionLineItemIndex = index;
            lineItem.ParentTransactionRemainingQty = parentTransactionRemainingQty;

            lineItem.BatchNo = "";
            lineItem.BatchMRP = 0;
            lineItem.BatchRetailPrice = 0;
            lineItem.BatchMfgDate = "";
            lineItem.BatchExpDate = "";
            lineItem.BatchMfgDateValue = null;
            lineItem.BatchExpDateValue = null;

            recordToBeConverted = true;
            inventory.LineItems.push(lineItem);
          }
        });
        if (recordToBeConverted) {
          refNo =
            refNo + refNo !== ""
              ? "," + record.TransactionNo
              : record.TransactionNo;
          inventory.ParentTransactions.push({
            ParentTransactionKey: record.key,
            ParentTransactionNo: record.TransactionNo,
          });
        }
      }
      if (
        inventory.TransactionType.UpdateStockChecked &&
        firstRecordData.TransactionType.UpdateStockChecked
      ) {
        inventory.TransactionType.UpdateStockChecked = false;
        // console.log('inventory.TransactionType.UpdateStockChecked', inventory.TransactionType.UpdateStockChecked)
      }
      inventory.RefNo = refNo;
      this.props.history.push({
        pathname: "/home/inventory/inventory/",
        state: {
          type: actionTypes.INVENTORY_NEW,
          inventory: inventory,
          transactionType: transactionType,
        },
      });
    }
  }

  renderSelectionOptions() {
    if (this.state.listPageSettings.Type === "saleshistory") {
      return (
        <React.Fragment>
          <Button
            type={"secondary"}
            text={"Copy"}
            onClick={() => this.onConvertTransaction(null, true)}
          />
          <Button
            type={"secondary"}
            text={"Convert"}
            onClick={() =>
              this.setState({
                showPopupWindow: true,
                popupType: "convertsales",
              })
            }
          />
        </React.Fragment>
      );
    }
    if (this.state.listPageSettings.Type === "inventorylist") {
      return (
        <React.Fragment>
          <Button
            type={"secondary"}
            text={"Convert"}
            onClick={() =>
              this.setState({
                showPopupWindow: true,
                popupType: "convertinventory",
              })
            }
          />
        </React.Fragment>
      );
    }
  }

  getSelectedTabValue() {
    let listPageSettings = this.state.listPageSettings;
    if (
      listPageSettings.HeaderButtons.filter((f) => f.Type === "filterbuttons")
        .length > 0
    ) {
      let filterColumnName = listPageSettings.HeaderButtons.filter(
        (f) => f.Type === "filterbuttons"
      )[0].FilterColumn;
      let filterColumn = listPageSettings.Filters.filter(
        (col) => col.Column === filterColumnName
      )[0];
      console.log("filterColumn", filterColumn);
      if (filterColumn) {
        return filterColumn.Value;
      }
    }
  }

  renderFilterButtons() {
    if (
      this.state.listPageSettings.HeaderButtons.filter(
        (f) => f.Type === "filterbuttons"
      ).length > 0
    ) {
      let filterColumnName = this.state.listPageSettings.HeaderButtons.filter(
        (f) => f.Type === "filterbuttons"
      )[0].FilterColumn;
      // console.log('filterColumnName',filterColumnName)
      let filterColumn = this.state.listPageSettings.Filters.filter(
        (col) => col.Column === filterColumnName
      )[0];
      // console.log('filterColumn',filterColumn);
      // return filterColumn.Options.map(button=><Button type="secondary" text={button.Text} icon={button.Icon} onClick={()=>this.onHeaderButtonClicked(button)}/>)

      return (
        <TabStrip
          tabButtons={filterColumn.Options}
          selectedTabValue={this.getSelectedTabValue()}
          onTabSelected={(value) => {
            let listPageSettings = this.state.listPageSettings;
            if (
              listPageSettings.HeaderButtons.filter(
                (f) => f.Type === "filterbuttons"
              ).length > 0
            ) {
              let filterColumnName = listPageSettings.HeaderButtons.filter(
                (f) => f.Type === "filterbuttons"
              )[0].FilterColumn;
              // let filterColumn =
              listPageSettings.Filters.filter(
                (col) => col.Column === filterColumnName
              )[0].Value = value;
              this.setState({ listPageSettings: listPageSettings });

              this.setState({
                showFilter: false,
                recordFrom: 0,
                activePage: 1,
              });
              this.setConfigFilters(
                listPageSettings,
                this.state.searchText,
                0,
                1
              );
              this.getData(
                this.state.searchText,
                listPageSettings.Filters,
                this.state.listPageSettings.Sort,
                0,
                this.state.itemsCountPerPage,
                this.state.listPageSettings.API
              );

              // console.log('filterColumn',filterColumn);
              // if(filterColumn){
              //     return filterColumn.Value;
              // }
            }
          }}
        />
      );
    }
  }

  renderDesktop() {
    return (
      <div className="header-margin">
        <div className="list-page">
          <div className="list-page-header">
            <div className="left left-40">
              <div className="list-page-header-title">
                {this.state.listPageSettings.Title}
              </div>
            </div>
            <div className="right right-60">
              {this.renderFilterButtons()}
              {this.state.showSelectionOption && this.renderSelectionOptions()}
              {this.state.listPageSettings.Filters.length > 0 &&
                this.state.listPageSettings.Filters.filter(
                  (f) => f.GeneralSearch
                ).length > 0 &&
                this.renderListSearch()}
              {this.state.listPageSettings.HeaderButtons.map(
                (button, index) => (
                  <React.Fragment>
                    {button.ButtonType.toLowerCase() === "button" && (
                      <Button
                        type={button.Type}
                        text={button.Text}
                        icon={button.Icon}
                        onClick={() => this.onHeaderButtonClicked(button)}
                      />
                    )}
                    {button.ButtonType.toLowerCase() === "imagebutton" && (
                      <ImageButton
                        type={button.Type}
                        label={button.Text}
                        onClick={() => this.onHeaderButtonClicked(button)}
                      />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>
          <div
            className="list-page-content"
            style={{
              height: window.innerHeight - 210 + "px",
              overflowY: "auto",
            }}
          >
            {this.state.loading && <Spinner />}
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  {this.state.listPageSettings.Columns.filter(
                    (f) => !f.ShowInMobile
                  ).map((c, index) => (
                    <th key={index}>
                      {c.Type === "checkbox" && (
                        <Input
                          type="checkbox"
                          noMargin={true}
                          value={this.state.selectAll}
                          onChange={() => this.checkboxSelected(-1)}
                        />
                      )}
                      {c.Name}
                    </th>
                  ))}
                  {this.state.listPageSettings.RowButtons.length > 0 && (
                    <th
                      className="list-page-row-btns-th"
                      style={{ width: this.state.rowButtonWidth }}
                      key={"rowBtns"}
                    ></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.list.map((l, index) => (
                  <tr key={"tdtr" + index}>
                    {this.state.listPageSettings.Columns.filter(
                      (f) => !f.ShowInMobile
                    ).map((col, colIndex) => (
                      <td key={"td" + colIndex}>
                        {this.renderRow(l, col, index)}
                      </td>
                    ))}
                    {this.renderRowButtons(index, l)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="list-page-footer">
            <div className="left">{this.showPaginationStatus()}</div>
            <div className="right">
              <div className="list-page-footer-pagination">
                <ImageButton
                  type={"leftarrowdouble"}
                  label={"First"}
                  onClick={() => this.onFirstClick()}
                />
                <ImageButton
                  type={"leftarrow"}
                  label={"Previous"}
                  onClick={() => this.onPreviousClick()}
                />
                <Input
                  type="select"
                  value={this.state.itemsCountPerPage}
                  onChange={(value) => this.onRecordsPerPageCountChange(value)}
                >
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </Input>
                <ImageButton
                  type={"rightarrow"}
                  label={"Next"}
                  onClick={() => this.onNextClick()}
                />
                <ImageButton
                  type={"rightarrowdouble"}
                  label={"Last"}
                  onClick={() => this.onLastClick()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="list-page-mobile-background"></div>
        <div className="list-page-mobile-header">
          <div className="left-70">
            {/* <ImageButton type={"leftarrow"} label={"back"} onClick={()=>this.props.history.push("/home")}/> */}
            <Menu {...this.props} />
            <div className="list-page-mobile-header-title">
              {this.state.listPageSettings.Title}
            </div>
          </div>
          <div className="right-30">
            {this.state.showSelectionOption && this.renderSelectionOptions()}
            {this.state.listPageSettings.HeaderButtons.filter(
              (f) => f.Icon !== "add"
            ).map((button, index) => (
              <React.Fragment>
                {button.ButtonType.toLowerCase() === "button" && (
                  <Button
                    type={button.Type}
                    text={button.Text}
                    icon={button.Icon}
                    onClick={() => this.onHeaderButtonClicked(button)}
                  />
                )}
                {button.ButtonType.toLowerCase() === "imagebutton" && (
                  <ImageButton
                    type={button.Type}
                    label={button.Text}
                    onClick={() => this.onHeaderButtonClicked(button)}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="list-page-content">
          {this.state.loading && <Spinner />}
          {this.state.listPageSettings.Filters.length > 0 &&
            this.state.listPageSettings.Filters.filter((f) => f.GeneralSearch)
              .length > 0 &&
            this.renderListSearch()}
          <div
            style={{
              height: window.innerHeight - 200 + "px",
              overflowY: "auto",
            }}
          >
            {this.state.list.map((l, index) => (
              <div
                key={"tdtr" + index}
                className="list-row seperator"
                onClick={() => {
                  if (this.state.listPageSettings.RowButtons.length === 1) {
                    this.onRowButtonClicked(
                      this.state.listPageSettings.RowButtons[0],
                      l
                    );
                  } else {
                    this.setState({
                      showPopupWindow: true,
                      popupType: "mobilerowoptions",
                      selectedRow: l,
                    });
                  }
                }}
              >
                {this.state.listPageSettings.Columns.filter(
                  (f) => f.ShowInMobile
                ).map((col, colIndex) => (
                  <div key={"td" + colIndex} className="row-text">
                    {this.renderRow(l, col, index)}
                  </div>
                ))}
                {/* {this.renderRowButtons(index, l)} */}
              </div>
            ))}
            {this.state.list.length === 0 && !this.state.loading && (
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <RecordNotFound />
              </div>
            )}
          </div>
        </div>
        {/* {this.state.listPageSettings.HeaderButtons.filter(f=>f.Icon ==="add").map( (button,index)=><div className="list-page-mobile-new-button">
                        <ImageButton type="primary" icon="add" label={button.Text} onClick={()=>this.onHeaderButtonClicked(button)}/>
            </div>)} */}

        {this.state.listPageSettings.HeaderButtons.filter(
          (f) => f.Icon === "add"
        ).map((button, index) => (
          <ActionButton
            type="add"
            icon="add"
            label={button.Text}
            bottom="90px"
            onClick={() => this.onHeaderButtonClicked(button)}
          />
        ))}

        <div className="list-page-mobile-footer">
          {this.showPaginationStatus()}
          <div className="list-page-mobile-footer-pagination">
            <ImageButton
              type={"leftarrowdouble"}
              label={"First"}
              onClick={() => this.onFirstClick()}
            />
            <ImageButton
              type={"leftarrow"}
              label={"Previous"}
              onClick={() => this.onPreviousClick()}
            />
            <Input
              type="select"
              value={this.state.itemsCountPerPage}
              onChange={(value) => this.onRecordsPerPageCountChange(value)}
            >
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </Input>
            <ImageButton
              type={"rightarrow"}
              label={"Next"}
              onClick={() => this.onNextClick()}
            />
            <ImageButton
              type={"rightarrowdouble"}
              label={"Last"}
              onClick={() => this.onLastClick()}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="header-margin">
        {this.state.listPageSettings && (
          <React.Fragment>
            {this.renderCancelWindow()}
            {this.renderPopupWindow()}
            {this.renderDeleteWindow()}
            {this.renderFilter()}
            {this.renderOptions()}
            {this.renderImportWindow()}
            {this.renderPopup()}
            {!isMobileApp() && this.renderDesktop()}
            {isMobileApp() && !this.state.showPopup && this.renderMobile()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ListPage;
