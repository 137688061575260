import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Pagination from 'react-js-pagination'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util'
import { Link } from 'react-router-dom';

class OutStandingLedger extends Component {
    constructor() {
        super()
        this.state = {
            outStandingAmounts: [],
            searchTransactionNo: '',
            searchTransactionFromDate: '',
            searchTransactionToDate: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: true,
        }
        this.loadOutStandingLedger = this.loadOutStandingLedger.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
    }

    componentDidMount() {
        this.loadOutStandingLedger(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate);
    }

    loadOutStandingLedger(from, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
        this.setState({ loading: true })
        this.props.getOutStandingLedgerByCustomerID(this.props.location.state.Customer.key, from, this.state.itemsCountPerPage, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                this.setState({ outStandingAmounts: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            });
    }

    onSearchClick() {
        this.loadOutStandingLedger(
            this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate
        )
    }

    onClearSearch() {
        this.setState({ recordFrom: 0, searchTransactionNo: '', searchTransactionFromDate: '', searchTransactionToDate: '' })
        this.loadOutStandingLedger(0, "", "", "")
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getOutStandingLedgerByCustomerID(this.props.location.state.Customer.key, 0, 10000,
            this.state.searchTransactionNo, this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let customerOutStandings = []
                result.searchResult.map((outStanding) => {
                    let customerOutStanding = {
                        "Transaction No": outStanding.TransactionNo,
                        "Transaction Date": (new Date(outStanding.TransactionDate).toISOString().split('T')[0]),
                        "Transaction Type": outStanding.TransactionType.TransactionType,
                        "Customer Name": outStanding.Customer.FirstName,
                        "Phone No": !isNullOrUndefined(outStanding.Customer.PhoneNo) ? outStanding.Customer.PhoneNo : "",
                        "Bill Amount": outStanding.BillAmount,
                        "Paid Amount": outStanding.BillPaidAmount,
                        "Balance Amount": outStanding.BalanceAmount
                    }
                    customerOutStandings.push(customerOutStanding);
                })
                const csvData = constatnts.objToCSV(customerOutStandings);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "CustomerOutStandingLedger.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadOutStandingLedger(
            this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate
        )
    }

    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount
        }
        this.setState({ paginationStatus: paginationStatus })
    }


    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Customer OutStanding Ledger</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/customeroutstandingledger" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>Transaction No</label>
                                                <input type="text"
                                                    onKeyPress={this.handleKeyPress}
                                                    autoFocus={true}
                                                    className="form-control input-md"
                                                    value={this.state.searchTransactionNo}
                                                    onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>From Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                    value={this.state.searchTransactionFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>To Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                    value={this.state.searchTransactionToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3"><br />
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginLeft: "5px" }}
                                                onClick={this.onSearchClick}
                                                disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                                onClick={this.onClearSearch} >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginRight: "5px" }}
                                                onClick={this.onExportClick}
                                            >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Transaction No</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Customer</th>
                                                <th>Amount</th>
                                                <th>Paid Amount</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.outStandingAmounts.map((outStanding) =>
                                                <tr>
                                                    <td>{outStanding.TransactionNo}</td>
                                                    <td>{(new Date(outStanding.TransactionDate).toISOString().split('T')[0])}</td>
                                                    <td>{outStanding.TransactionType.TransactionType}</td>
                                                    <td>{outStanding.Customer.FirstName + ' ' + outStanding.Customer.LastName}
                                                        <div>{outStanding.Customer.PhoneNo}</div>
                                                    </td>
                                                    <td>{outStanding.BillAmount}</td>
                                                    <td>{outStanding.BillPaidAmount}</td>
                                                    <td>{outStanding.BalanceAmount}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }

}

export default withRouter(OutStandingLedger)