import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import Loader from '../Loader'
import Delivery from "./Delivery";
import Confirm from "react-confirm-bootstrap";
import { isNullOrUndefined } from "util";
import * as constatnts from "../../Constatnts";
import * as actionTypes from "../../actionTypes";
import CancelSales from "./CancelSales";
import Window from "../2/Controls/Window";
import ImageButton from "../2/Controls/ImageButton";
import { isMobileApp } from "../../actions/Util";
import Button from "../2/Controls/Button";
import Input from "../2/Controls/Input";
import Print from "./Print";
import {
  salesCalculateLineItem,
  salesCalculateBillTotal,
} from "../../actions/Util";
import "./DeliveryList.css";
import Spinner from "../Common/Spinner";
import Icon from "../2/Controls/Icon";
import Menu from "../2/Menu";
import RecordNotFound from "../Common/RecordNotFound";
import PageSection from "../2/Controls/PageSection";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import ExcelJS from "exceljs";
import axios from "axios";

// import { result } from 'lodash';

class DeliveryList extends Component {
  constructor() {
    super();
    this.state = {
      deliveryListDetail: [],
      selectedDelivery: null,
      searchCustomerDetail: "",
      searchTransactionNo: "",
      searchTransactionFromDate: "", //new Date().toISOString().split('T')[0],
      searchTransactionToDate: "",
      cashRegisterList: [],
      cashRegister: {},
      searchCashRegister: "",
      searchStore: "",
      searchEmployeeName: "",
      searchPendingReadyForPickupDeliveredAllRecords: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      loading: false,
      showDelivery: false,
      showOutForDelivery: false,
      selectedEmployeeKey: "",
      selectedEmployee: "",
      sendSMSForOutForDelivery: false,
      SMSNo: "",
      employeeList: [],
      selectedSale: null,
      showSearchOptions: false,
      mobile: false,
      showFilter: false,
      enableONDC: false,
      nonONDC: false,
      cancellationReasonCodes: [],
      ONDCCancellationReason: "",
      ONDCCancellationReasonCode: "",
      ONDCCancellationReasonError: "",
      deliveryPersonKey: "",
      deliveryPersonMobileNo: "",
      deliveryPersonName: "",
      statusSummary: [],
      orderNo: "",
      showMobileRowOptions: false,
      showMobileOrderDetailWindow: false,
      activePage: 0,
      merchantInvoiceNo: "",
      showMerchantInvoiceNo: false,
      stores: [],
      merchantInvoiceAmount: "",
      merchantInvoiceNoError: "",
    };
    this.loadDeliveryDetailReport = this.loadDeliveryDetailReport.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.OnSearchPendingReadyForPickupDeliveredAllRecords = this.OnSearchPendingReadyForPickupDeliveredAllRecords.bind(
      this
    );
    this.onSearchClick = this.onSearchClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onDeliveryAction = this.onDeliveryAction.bind(this);
    this.onSaveOutForDelivery = this.onSaveOutForDelivery.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStatusUpdateClick = this.onStatusUpdateClick.bind(this);
    this.redirectToEditSales = this.redirectToEditSales.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    // this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onRecordsPerPageCountChange = this.onRecordsPerPageCountChange.bind(
      this
    );
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrintClicked = this.onPrintClicked.bind(this);
    this.renderStatusFilters = this.renderStatusFilters.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.renderQuickActionButtons = this.renderQuickActionButtons.bind(this);
    this.onShowONDCEdit = this.onShowONDCEdit.bind(this);
    this.updateSalesTransaction = this.updateSalesTransaction.bind(this);
    this.onQtyChange = this.onQtyChange.bind(this);
    this.getPendingOlineOrder = this.getPendingOlineOrder.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.renderMobileRowOptions = this.renderMobileRowOptions.bind(this);
    this.renderMobileOrderDetails = this.renderMobileOrderDetails.bind(this);
    this.renderLineItems = this.renderLineItems.bind(this);
    this.renderCancellationText = this.renderCancellationText.bind(this);
    this.onDownloadOrder = this.onDownloadOrder.bind(this);
  }

  async componentDidMount() {
    let stores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      this.props.user.auth.uid
    );
    let enableONDC = this.props.registration.registration.EnableONDC;
    let nonONDC = !enableONDC;
    let searchPendingReadyForPickupDeliveredAllRecords = "pending";
    if (enableONDC) {
      if (this.props.user.store.AutoAcceptOnlineOrders) {
        searchPendingReadyForPickupDeliveredAllRecords = "Accepted";
      } else {
        searchPendingReadyForPickupDeliveredAllRecords = "Created";
      }
    }
    let searchStore = this.props.user.store.key;
    // if(stores.length===0){
    //     searchStore = stores[0].key;
    // }
    // let cancellationReasonCodes = await
    this.props.getCancellationReasonCodes().then((cancellationReasonCodes) => {
      // console.log("cancellationReasonCodes", cancellationReasonCodes);
      let tmp = cancellationReasonCodes.filter((f) =>
        f.cost_attributed_to.includes("Seller")
      );
      this.setState({ cancellationReasonCodes: tmp });
      // console.log('tmp',tmp);
    });

    this.setState({
      stores: stores,
      searchStore: searchStore,
      enableONDC: enableONDC,
      nonONDC: nonONDC,
      searchPendingReadyForPickupDeliveredAllRecords: searchPendingReadyForPickupDeliveredAllRecords,
      // cancellationReasonCodes:cancellationReasonCodes
    });

    this.props
      .getEmployees(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        0,
        1000,
        "active",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
      .then((employees) => {
        this.setState({ employeeList: employees.searchResult });
      });

    this.loadDeliveryDetailReport(
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      searchPendingReadyForPickupDeliveredAllRecords,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );

    // if(this.props.registration.registration.Delivery){
    this.timer = setInterval(() => this.getPendingOlineOrder(), 10000);
    // }

    if (statusCheckInterval) {
      clearInterval(statusCheckInterval);
    }

    const statusCheckInterval = setInterval(async () => {
      console.log("statusCheckInterval");
      // Fetch the summary status from the API
      let summaryStatus = await this.props.getDeliveryStatusSummary(
        this.props.user.user.RegistrationID,
        this.state.searchStore
      );

      // Your target statuses
      const targetStatuses = [
        "Created",
        "Packed",
        "Accepted",
        "Out-for-delivery",
      ];

      // Function to get the count of a specific status from the status list
      const getStatusCount = (statusList, status) => {
        const statusObj = statusList.find((item) => item.status === status);
        return statusObj ? statusObj.count : 0;
      };

      // Check if the counts of target statuses match between current and previous summaryStatus
      const hasMismatch = targetStatuses.some((status) => {
        const currentCount = getStatusCount(summaryStatus, status);
        const prevCount = getStatusCount(this.state.statusSummary, status);
        return currentCount !== prevCount;
      });

      // If there's a mismatch, update the state
      let searchPendingReadyForPickupDeliveredAllRecords =
        this.state.searchPendingReadyForPickupDeliveredAllRecords !== ""
          ? this.state.searchPendingReadyForPickupDeliveredAllRecords
          : "pending";
      console.log(
        "searchPendingReadyForPickupDeliveredAllRecords",
        searchPendingReadyForPickupDeliveredAllRecords
      );
      if (hasMismatch) {
        //     //  this.setState({ statusSummary: summaryStatus });

        // Optional: Reload delivery details if needed

        let searchStore = this.props.user.store.key;
        this.loadDeliveryDetailReport(
          this.state.recordFrom,
          this.state.itemsCountPerPage,
          searchPendingReadyForPickupDeliveredAllRecords,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
      }
    }, 60000); // 1 minute interval

    return () => {
      clearInterval(statusCheckInterval);
    };
  }

  async getPendingOlineOrder() {
    // console.log("Pawan");
    let startTime = new Date().getTime(); //
    let result = await this.props.getDeliveryDetailReport(
      this.props.user.user.RegistrationID,
      0,
      100,
      "Pending",
      "",
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      "",
      this.props.user.store.key,
      "",
      ""
    );
    let newOrderReceived = false;
    // console.log("getPendingOlineOrder", result);
    result.searchResult.map((r) => {
      if (r.hasOwnProperty("RequestSource") && r.RequestSource !== "") {
        let timeDiff = (startTime - r.TransactionDate) / 1000;
        if (timeDiff < 12) {
          console.log("show record", r);
          newOrderReceived = true;
          this.props.getToastr(
            "New order received: " + r.TransactionNo,
            null,
            "",
            this.props,
            null,
            "onlineorder",
            ""
          );
          // this.props.setNewOnlineOrder(r.key);
        }
      }
    });
    // console.log("pawan newOrderReceived", newOrderReceived);
    if (newOrderReceived) {
      console.log("pawan if newOrderReceived");
      if (
        this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Accepted" ||
        this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Created" ||
        this.state.searchPendingReadyForPickupDeliveredAllRecords.toLowerCase() ===
          "pending"
      ) {
        // console.log("pawan referesh list data");
        this.loadDeliveryDetailReport(
          this.state.recordFrom,
          this.state.itemsCountPerPage,
          this.state.searchPendingReadyForPickupDeliveredAllRecords,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
      }
    }
  }

  // componentDidUpdate(prevProps) {

  //     console.log("componentDidUpdate");
  //     if(this.props.config.NewOnlineOrder !== prevProps.config.NewOnlineOrder){
  //         console.log("componentDidUpdate - if");
  //         let searchPendingReadyForPickupDeliveredAllRecords = "pending";
  //         let enableONDC = this.props.registration.registration.EnableONDC;
  //         if(enableONDC){
  //             if(this.props.user.store.AutoAcceptOnlineOrders){
  //                 searchPendingReadyForPickupDeliveredAllRecords="Accepted";
  //             }
  //             else
  //             {
  //                 searchPendingReadyForPickupDeliveredAllRecords="Created";
  //             }
  //         }

  //         if(this.state.searchPendingReadyForPickupDeliveredAllRecords.toLowerCase()===searchPendingReadyForPickupDeliveredAllRecords){
  //             this.loadDeliveryDetailReport(
  //                 this.state.recordFrom,
  //                 this.state.itemsCountPerPage,
  //                 this.state.searchPendingReadyForPickupDeliveredAllRecords,
  //                 this.state.searchTransactionNo,
  //                 this.state.searchTransactionFromDate,
  //                 this.state.searchTransactionToDate,
  //                 this.state.searchCustomerDetail,
  //                 this.state.searchStore,
  //                 this.state.searchEmployeeName,
  //                 this.state.orderNo
  //             )
  //         }
  //     }
  // }

  async loadDeliveryDetailReport(
    from,
    itemsCountPerPage,
    searchPendingReadyForPickupDeliveredAllRecords,
    searchTransactionNo,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchStore,
    searchEmployeeName,
    orderNo
  ) {
    // console.log(
    //   "loadDeliveryDetailReport - searchPendingReadyForPickupDeliveredAllRecords",
    //   searchPendingReadyForPickupDeliveredAllRecords
    // );

    this.setState({ loading: true });

    // if (orderNo.trim() !== "") {
    //   from = 0;
    //   itemsCountPerPage = 10000;
    // }

    let result = await this.props.getDeliveryDetailReport(
      this.props.user.user.RegistrationID,
      from,
      itemsCountPerPage,
      searchPendingReadyForPickupDeliveredAllRecords,
      searchTransactionNo,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchStore,
      searchEmployeeName,
      orderNo
    );
    // .then((result) => {

    // })
    result.searchResult.map((r) => {
      r.DeliveryStatus = r.hasOwnProperty("DeliveryStatus")
        ? r.DeliveryStatus
        : "";
      if (r.ONDC) {
        r.ExternalTransactionNoPrefix = r.ExternalTransactionNo.substring(
          0,
          r.ExternalTransactionNo.length - 5
        );
        r.ExternalTransactionNoSufix = r.ExternalTransactionNo.substring(
          r.ExternalTransactionNo.length - 5,
          r.ExternalTransactionNo.length
        );
      }
    });

    let results = [];
    let totalItemsCount = 0;
    // console.log("orderno", orderNo);
    // if (orderNo.trim() !== "") {
    //   results = result.searchResult.filter((item) =>
    //     item.ExternalTransactionNo.toLowerCase().includes(
    //       this.state.orderNo.toLowerCase()
    //     )
    //   );
    //   console.log("results", result);
    //   totalItemsCount = results.length;
    //   // recordFrom=0;
    // } else {
    results = result.searchResult;
    totalItemsCount = result.totalItemsCount;
    // recordFrom =
    // }

    // console.log("searchStore",searchStore)
    let statusSummary = await this.props.getDeliveryStatusSummary(
      this.props.user.user.RegistrationID,
      searchStore
    );
    // .then((result)=>{
    //     this.setState({statusSummary:result })
    // })
    // console.log("result", result);
    this.setState({
      statusSummary: statusSummary,
      deliveryListDetail: results,
      totalItemsCount: totalItemsCount,
      loading: false,
      recordFrom: from,
      showSearchOptions: false,
    });

    this.showPaginationStatus();
  }

  onShowONDCEdit(delivery) {
    delivery.LineItems.map((l) => {
      l.QtyChange = l.Qty;
    });
    this.setState({
      selectedDelivery: delivery,
      showONDCEdit: true,
      showMobileRowOptions: false,
    });
  }

  onFirstClick() {
    // if (this.state.activePage > 1) {
    let pageNumber = 1;
    let recordFrom = 0;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadDeliveryDetailReport(
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchPendingReadyForPickupDeliveredAllRecords,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
    // }
  }

  onPreviousClick() {
    if (this.state.activePage > 0) {
      let pageNo = Number(this.state.activePage) - 1;
      let recordFrom = pageNo * this.state.itemsCountPerPage;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadDeliveryDetailReport(
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.searchPendingReadyForPickupDeliveredAllRecords,
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchStore,
        this.state.searchEmployeeName,
        this.state.orderNo
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage) + 1;
    let recordFrom = pageNo * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadDeliveryDetailReport(
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.searchPendingReadyForPickupDeliveredAllRecords,
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchStore,
        this.state.searchEmployeeName,
        this.state.orderNo
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );
    let recordFrom = Number(pageNo) * Number(this.state.itemsCountPerPage);
    this.setState({ activePage: pageNo, recordFrom: recordFrom });
    this.loadDeliveryDetailReport(
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchPendingReadyForPickupDeliveredAllRecords,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onClearSearch() {
    let enableONDC = this.props.registration.registration.EnableONDC;
    let searchPendingReadyForPickupDeliveredAllRecords = "pending";
    if (enableONDC) {
      if (this.props.user.store.AutoAcceptOnlineOrders) {
        searchPendingReadyForPickupDeliveredAllRecords = "Accepted";
      } else {
        searchPendingReadyForPickupDeliveredAllRecords = "Created";
      }
    }

    this.setState({
      recordFrom: 0,
      // searchPendingReadyForPickupDeliveredAllRecords: searchPendingReadyForPickupDeliveredAllRecords,
      searchTransactionNo: "",
      searchTransactionToDate: "",
      searchTransactionFromDate: "",
      searchCustomerDetail: "",
      searchCashRegister: "",
      // searchStore:'',
      searchEmployeeName: "",
      showSearchOptions: false,
      showFilter: false,
      orderNo: "",
    });
    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      this.state.searchPendingReadyForPickupDeliveredAllRecords,
      "",
      "",
      "",
      "",
      this.state.searchStore,
      "",
      ""
    );
  }

  onRecordsPerPageCountChange(itemsCountPerPage) {
    let pageNo = 1;
    let recordFrom = 0;
    this.setState({
      activePage: pageNo,
      recordFrom: recordFrom,
      itemsCountPerPage: Number(itemsCountPerPage),
    });
    this.loadDeliveryDetailReport(
      0,
      itemsCountPerPage,
      this.state.searchPendingReadyForPickupDeliveredAllRecords,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onSearchClick() {
    // console.log('onSearchClick called')
    this.setState({ searchFrom: 0, selected: false, showFilter: false });
    // console.log(
    //   "this.state.searchPendingReadyForPickupDeliveredAllRecords",
    //   this.state.searchPendingReadyForPickupDeliveredAllRecords
    // );
    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      this.state.searchPendingReadyForPickupDeliveredAllRecords,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  OnSearchPendingReadyForPickupDeliveredAllRecords(option) {
    // console.log(option);
    // new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]
    let searchTransactionFromDate = "";
    let searchTransactionToDate = "";
    if (
      option.toLowerCase() === "order-delivered" ||
      option.toLowerCase() === "order-picked-up" ||
      option.toLowerCase() === "all"
    ) {
      searchTransactionFromDate = this.state.searchTransactionFromDate;
      searchTransactionToDate = this.state.searchTransactionToDate;
    }
    this.setState({
      searchPendingReadyForPickupDeliveredAllRecords: option,
      recordFrom: 0,
      selected: false,
    });

    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      option,
      this.state.searchTransactionNo,
      searchTransactionFromDate,
      searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onStatusUpdateClick(status) {
    this.setState({ loading: true });
    let recordsToBeUpdated = this.state.deliveryListDetail.filter(
      (f) => f.selected
    );
    if (recordsToBeUpdated.length === 0) {
      alert("No records selected");
      return;
    }
    this.setState({ loading: true });

    if (
      status.toLowerCase() === "packed" ||
      status.toLowerCase() === "accepted" ||
      status.toLowerCase() === "out-for-delivery" ||
      status.toLowerCase() === "order-delivered"
    ) {
      recordsToBeUpdated.map(async (sale) => {
        if (status.toLowerCase() === "out-for-delivery") {
          if (this.state.deliveryPersonKey !== "") {
            sale.DeliveryPersonKey = this.state.deliveryPersonKey;
          }
          if (this.state.deliveryPersonName !== "") {
            sale.DeliveryPersonName = this.state.deliveryPersonName;
          }
          if (this.state.deliveryPersonMobileNo !== "") {
            sale.DeliveryPersonMobileNo = this.state.deliveryPersonMobileNo;
          }
        }
        let updateStatus = await this.props.updateONDCDeliveryStatus(
          sale,
          status
        );
        // console.log("updateStatus", updateStatus);
        if (updateStatus) {
          this.props.getToastr("Record saved successfully!!!");
        } else {
          alert("Error saving data. Please try again.");
        }
      });
    }

    if (status === "confirmed") {
      recordsToBeUpdated.map(async (sale) => {
        await this.props.orderConfirmed(
          sale,
          sale.DeliveryCustomer ? sale.DeliveryCustomer.PhoneNo : ""
        );
      });
    }
    if (status === "ready for pickup") {
      recordsToBeUpdated.map(async (sale) => {
        await this.props.readyForPickup(
          sale,
          sale.DeliveryCustomer ? sale.DeliveryCustomer.PhoneNo : ""
        );
      });
    }
    if (
      status === "out for delivery"
      // || status.toLowerCase()==="out-for-delivery"
    ) {
      this.setState({ showOutForDelivery: false });
      recordsToBeUpdated.map(async (sale) => {
        await this.props.outForDelivery(
          sale,
          this.state.selectedEmployee,
          this.state.selectedEmployeeKey,
          sale.DeliveryCustomer ? sale.DeliveryCustomer.PhoneNo : ""
        );
      });
    }

    this.setState({
      showOutForDelivery: false,
      multipleRecordsToBeUpdated: false,
      saving: false,
      deliveryPersonKey: "",
      deliveryPersonMobileNo: "",
      deliveryPersonName: "",
    });
    // console.log("onStatusUpdateClick 6 second timer");
    setTimeout(
      function() {
        //Start the timer
        this.onSearchClick();
        this.setState({ loading: false });
      }.bind(this),
      6000
    );
  }

  showPaginationStatus() {
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  async updateSalesTransaction(delivery) {
    // console.log("updateSalesTransaction", delivery);
    this.setState({ saving: true });
    let sale = await this.props.getSaleDetails(delivery.key);
    // console.log("sale", sale);
    if (!sale.hasOwnProperty("OrderLineItems")) {
      sale.OrderLineItems = [];
      sale.LineItems.map((m) => {
        if (m.Product.Name.toLowerCase() !== "delivery charges") {
          sale.OrderLineItems.push({
            ProductID: m.Product.key,
            ProductName: m.Product.Name,
            ProductSKU: m.Product.SKU,
            ProductBarCode: m.Product.BarCode,
            RetailPrice: m.RetailPrice,
            Qty: m.Qty,
            Images: m.Product.hasOwnProperty("Images") ? m.Product.Images : [],
          });
        }
      });
    }
    let lineItems = [];
    delivery.LineItems.map((l, index) => {
      // console.log("index", index);
      if (Number(l.QtyChange) === 0) {
        // console.log("remove lineitem", index);
        // sale.LineItems.splice(index, 1);
      } else {
        sale.LineItems[index].Qty = Number(l.QtyChange);
        lineItems.push(sale.LineItems[index]);
      }
    });
    // return;

    sale.LineItems = lineItems;
    sale.LineItems.map((l, index) => {
      sale.LineItems[index] = salesCalculateLineItem(
        index,
        sale.LineItems[index],
        sale.PlaceOfSupply ? sale.PlaceOfSupply : "",
        "",
        "",
        "",
        "",
        sale.TransactionType,
        this.props.config.ProductTaxes
      );
    });
    let totals = salesCalculateBillTotal(sale.LineItems, 0);
    sale.BillQty = totals.billQty;
    sale.BillSubTotal = totals.billSubTotal;
    sale.BillAmount = totals.billAmount;
    sale.BillTaxAmount = totals.billTaxAmount;
    sale.BillDiscount = totals.billDiscount;
    sale.TaxSummaryList = totals.taxSummaryList;
    sale.RoundOff = totals.roundOff;
    sale.BillPaidAmount = totals.billAmount;
    if (sale.Payments.length > 0) {
      sale.Payments[0].Amount = totals.billAmount;
    }
    // if (
    //   this.props.registration.registration.hasOwnProperty("ONDCTest") &&
    //   this.props.registration.registration.ONDCTest
    // ) {
    let response = await axios
      .post(
        "https://backend-o3dyulj4pa-el.a.run.app/v1/pincode/partialcancellation/update",
        { ...sale }
      )
      .then((res) => {
        if (res.data.code === "0") {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
    if (response) {
      await this.props.updateSales(sale, delivery.key);
      this.props.getToastr("Record saved successfully!!!");
    } else {
      alert("Error saving data. Please try again");
    }
    // } else {
    //   await this.props.updateSales(sale, delivery.key);
    //   this.props.getToastr("Record saved successfully!!!");
    // }
    if (
      sale.DeliveryBy &&
      sale.DeliveryBy.toLowerCase() === "shadowfax" &&
      sale.DeliveryPartnerOrderID &&
      sale.DeliveryPartnerOrderID !== ""
    ) {
      await this.props.shadowfaxUpdateOrder(delivery.key);
    }
    setTimeout(
      function() {
        // this.setState({ searchFrom: 0 ,loading:false})
        this.loadDeliveryDetailReport(
          0,
          this.state.itemsCountPerPage,
          this.state.searchPendingReadyForPickupDeliveredAllRecords,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
        this.setState({ saving: false, showONDCEdit: false });
      }.bind(this),
      3000
    );
  }

  async onPrintClicked(delivery) {
    let sale = await this.props.getSaleDetails(delivery.key);
    let printConfigs = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    );
    let printConfig = null;
    if (printConfigs.length === 0) {
      alert("Print setup does not exists");
      return;
    }
    printConfig = printConfigs[0];
    this.printFormatting(sale);
    let printData = {
      Sale: sale,
      PrintConfig: printConfig,
    };
    this.setState({
      print: true,
      printChecked: true,
      sendEmailChecked: false,
      printData: printData,
      showMobileRowOptions: false,
    }); //TBDpawan
  }

  async printFormatting(sale) {
    // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
    //in case of sales order to invoice
    if (
      sale.hasOwnProperty("ReceivedPaymentTransactions") &&
      sale.hasOwnProperty("IsConvert") &&
      sale.IsConvert
    ) {
      for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
        sale.BillAmount =
          Number(sale.BillAmount) +
          Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount);
      }
    }

    sale.TransactionDateShort = new Date(sale.TransactionDate.seconds * 1000)
      .toISOString()
      .split("T")[0];
    sale.TransactionDate = new Date(sale.TransactionDate.seconds * 1000)
      .toString()
      .substring(0, 24);
    sale.AmountInWords = this.props.convertNumberToWords(sale.BillAmount);
    // console.log('sale1.AmountInWords', sale1.AmountInWords)
    let billTaxableAmount = 0;
    let lineItemsTmp = [];
    for (let i = 0; i < sale.LineItems.length; i++) {
      lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)]);
    }
    sale.LineItems = lineItemsTmp;
    let costPriceTotal = 0;
    let mrpTotal = 0;

    let lineItemsWithOutAdditionalCharges = [];
    let lineItemsWithAdditonalCharges = [];
    let subTotalWithoutAdditionalCharges = 0;

    sale.LineItems.map((lineItem, index) => {
      sale.LineItems[index].SrNo = index + 1;
      sale.LineItems[index].BasePrice = this.roundUpTo2(
        Number(sale.LineItems[index].TaxableAmount) /
          Number(sale.LineItems[index].Qty)
      );
      if (
        lineItem.TaxComponentAmount &&
        lineItem.TaxComponentAmount.length > 0 &&
        lineItem.TaxInclusive
      ) {
        let taxPercentage = 0;
        let taxAmount = 0;
        lineItem.TaxComponentAmount.map((m) => {
          taxPercentage = taxPercentage + Number(m.TaxPercentage);
        });
        taxAmount = this.roundUpTo2(
          (Number(lineItem.RetailPrice) * Number(taxPercentage)) /
            (100 + Number(taxPercentage))
        );
        sale.LineItems[index].CostPrice =
          Number(lineItem.RetailPrice) - Number(taxAmount);
        //sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice :  lineItem.Product.MRP) - Number(taxAmount);
      } else {
        sale.LineItems[index].CostPrice = lineItem.RetailPrice;
      }
      sale.LineItems[index].MRP = Number(
        lineItem.Product.MRP === 0 ? lineItem.RetailPrice : lineItem.Product.MRP
      );

      sale.LineItems[index].CostPriceTotal = this.roundUpTo2(
        Number(sale.LineItems[index].CostPrice) *
          Number(sale.LineItems[index].Qty)
      );
      costPriceTotal =
        costPriceTotal + Number(sale.LineItems[index].CostPriceTotal);

      sale.LineItems[index].MRPTotal = this.roundUpTo2(
        Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty)
      );
      mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal);

      billTaxableAmount = this.roundUpTo2(
        Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount)
      );

      if (
        lineItem.Product.hasOwnProperty("ShowAsAdditionalCharges") &&
        lineItem.Product.ShowAsAdditionalCharges
      ) {
        lineItemsWithAdditonalCharges.push(sale.LineItems[index]);
      } else {
        lineItemsWithOutAdditionalCharges.push(sale.LineItems[index]);
        subTotalWithoutAdditionalCharges =
          Number(subTotalWithoutAdditionalCharges) +
          Number(sale.LineItems[index].Amount);
      }
    });
    sale.BillCostPriceTotal = costPriceTotal;
    // console.log("mrpTotal", mrpTotal);
    sale.BillMRPTotal = mrpTotal;
    sale.MRPSaving = this.roundUpTo2(
      this.roundUpTo2(
        Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))
      )
    );

    sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount));
    let printTaxComponents = [];
    let printTaxComponentsWithRate = [];
    sale.LineItems.map((lineItem, index) => {
      //check modifers
      if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
        let modifiersPrint = [];
        for (let k = 0; k < lineItem.Modifiers.length; k++) {
          let index = -1;
          for (let l = 0; l < modifiersPrint.length; l++) {
            if (
              modifiersPrint[l].ModifierName ===
              lineItem.Modifiers[k].ModifierName
            ) {
              index = l;
              break;
            }
          }
          if (index === -1) {
            modifiersPrint.push({
              key: lineItem.Modifiers[k].key,
              ModifierName: lineItem.Modifiers[k].ModifierName,
              ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
              ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
              ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
              ModifierQty: 1,
            });
          } else {
            modifiersPrint[index].ModifierQty =
              modifiersPrint[index].ModifierQty + 1;
            modifiersPrint[index].ModifierPrice =
              modifiersPrint[index].ModifierPrice +
              lineItem.Modifiers[k].ModifierPrice;
          }
        }
        // console.log('modifiersPrint', modifiersPrint)
        lineItem.ModifiersPrint = modifiersPrint;
      }
      if (lineItem.TaxID !== "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }

          let indexFoundTaxRate = -1;
          printTaxComponentsWithRate.map((pt, ptIndex) => {
            if (
              t.TaxType === pt.TaxType &&
              t.TaxPercentage === pt.TaxPercentage
            ) {
              indexFoundTaxRate = ptIndex;
              return;
            }
          });
          if (indexFoundTaxRate < 0) {
            printTaxComponentsWithRate.push({
              TaxType: t.TaxType,
              TaxPercentage: t.TaxPercentage,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
              TaxableAmount: Number(lineItem.TaxableAmount),
            });
          } else {
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxAmount = this.roundUpTo2(
              Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) +
                Number(t.TaxAmount)
            );
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxableAmount = this.roundUpTo2(
              Number(
                printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount
              ) + Number(lineItem.TaxableAmount)
            );
          }
        });
      }
    });
    sale.PrintTaxComponents = printTaxComponents;
    sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate;
    sale.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    let previousBalance = 0;
    if (sale.Customer && sale.Customer.hasOwnProperty("OutStandingAmount")) {
      previousBalance = sale.Customer.OutStandingAmount;
    }
    sale.PreviousBalance = previousBalance;
    sale.TotalBalanceAmount = sale.BillAmount + previousBalance;
    sale.TotalBalanceAfterPaid = sale.TotalBalanceAmount - sale.BillPaidAmount;

    sale.LineItemsWithOutAdditionalCharges = lineItemsWithOutAdditionalCharges;
    sale.LineItemsWithAdditonalCharges = lineItemsWithAdditonalCharges;
    sale.SubTotalWithoutAdditionalCharges = subTotalWithoutAdditionalCharges;

    // console.log('sale print', sale);
    //wallet balance
    // sale.Payments.filter(f=> f.PaymentMode.toLowerCase()==="wallet").map(m=> sale.WalletBalance = Number(sale.Customer.WalletBalance )- Number(m.Amount))
    // console.log('print sale', sale)
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  async onDownloadOrder(sale) {
    let records = [];
    let downloadFormat = "";
    if (
      this.props.registration.registration.hasOwnProperty(
        "EnableOrderDownloadFormat"
      )
    ) {
      downloadFormat = this.props.registration.registration
        .EnableOrderDownloadFormat;
    }

    sale.LineItems.map((l) => {
      if (downloadFormat === "") {
        records.push({
          "Item Code": l.Product.SKU,
          Description: l.Product.Name,
          Stock: "",
          Quantity: l.Qty,
          Unit: "",
          FOC: "",
          Rate: l.RetailPrice,
          Amount: l.Amount,
          "Discount Type": "%",
          "Discount Rate": 0,
          "Discount Amount": 0,
          "Excise Duty code": "",
          "Excise Duty AMOUNT": 0,
          Tax: "",
          "Tax AMOUNT": 0,
          "Net Amount": l.Amount,
          Status: sale.DeliveryStatus,
          "DLV Date": "",
          Remarks: "",
        });
      } else {
        records.push({
          "Order ID": sale.ExternalTransactionNo,
          "Item Code": l.Product.SKU,
          Barcode: l.Product.BarCode,
          Description: l.Product.Name,
          Stock: "",
          Quantity: l.Qty,
          Unit: "",
          FOC: "",
          MRP: l.Product.MRP,
          Rate: l.RetailPrice,
          Amount: l.Amount,
          "Discount Type": "%",
          "Discount Rate": 0,
          "Discount Amount": 0,
          "Excise Duty code": "",
          "Excise Duty AMOUNT": 0,
          Tax: "",
          "Tax AMOUNT": 0,
          "Net Amount": l.Amount,
          Status: sale.DeliveryStatus,
          "DLV Date": "",
          Remarks: "",
        });
      }
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add column headers
    worksheet.columns = Object.keys(records[0]).map((key) => ({
      header: key,
      key,
    }));

    // Add rows
    records.forEach((record) => {
      worksheet.addRow(record);
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and trigger a download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", sale.ExternalTransactionNo + ".xls");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // const csvData = constatnts.objToCSV(records);
    // this.setState({ exportCalled: false });
    // const csvBlob = new Blob([csvData], { type: "text/csv" });
    // const url = window.URL.createObjectURL(csvBlob);
    // const a = document.createElement("a");
    // a.setAttribute("hidden", "");
    // a.setAttribute("href", url);
    // a.setAttribute("download", sale.ExternalTransactionNo + ".xls");
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }

  onExportClick() {
    this.setState({ exportCalled: true });
    this.props
      .getDeliveryDetailReport(
        this.props.user.user.RegistrationID,
        0,
        10000,
        "all",
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchCashRegister,
        this.state.searchEmployeeName,
        this.state.orderNo
      )
      .then((result) => {
        if (
          isNullOrUndefined(result.searchResult) ||
          result.searchResult.length === 0
        ) {
          this.props.getToastr("No Record Found");
          return;
        }
        let deliveryLists = [];
        result.searchResult.map((data) => {
          let deliveryList = {
            "Delivery Date": data.DeliveryDate,
            "Delivery Time": "",
            "Customer Name":
              data.DeliveryCustomer.FirstName +
              " " +
              data.DeliveryCustomer.LastName,
            "Customer PhoneNo.": !isNullOrUndefined(
              data.DeliveryCustomer.PhoneNo
            )
              ? data.DeliveryCustomer.PhoneNo
              : "",
            "Customer EmailID": !isNullOrUndefined(
              data.DeliveryCustomer.EmailID
            )
              ? data.DeliveryCustomer.EmailID
              : "",
            "Delivery Address": "",
            "Transaction No": data.TransactionNo,
            "Delivery Status": data.DeliveryStatus,
            "Employee Name": !isNullOrUndefined(
              data.DeliveryOutForDeliveryEmployee
            )
              ? data.DeliveryOutForDeliveryEmployee
              : "",
            "Delivery Note": data.DeliveryNotes,
          };

          let deliveryTime =
            data.DeliveryTime.HH != "" && data.DeliveryTime.MM
              ? data.DeliveryTime.HH + ":" + data.DeliveryTime.MM
              : "";
          deliveryList["Delivery Time"] = deliveryTime;
          let address = "";
          // console.log('data', data.DeliveryCustomerAddress)
          if (
            data.hasOwnProperty("DeliveryCustomerAddress") &&
            data.DeliveryCustomerAddress
          ) {
            address = address + data.DeliveryCustomerAddress.Address1 + ", ";
            address = address + data.DeliveryCustomerAddress.Address2 + ", ";
            address = address + data.DeliveryCustomerAddress.City + ", ";
            address = address + data.DeliveryCustomerAddress.State + ", ";
            address = address + data.DeliveryCustomerAddress.PostalCode + ", ";
            address = address + data.DeliveryCustomerAddress.Country;
          }
          deliveryList["Delivery Address"] = address;
          deliveryLists.push(deliveryList);
        });
        const csvData = constatnts.objToCSV(deliveryLists);
        this.setState({ exportCalled: false });
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "deliveryList.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  async onDeliveryAction(action, sale) {
    // console.log("action",action);
    this.setState({ loading: true, showMobileRowOptions: false });
    if (action === "confirm") {
      this.setState({ loading: true });
      await this.props.orderConfirmed(
        sale,
        sale.DeliveryCustomer ? sale.DeliveryCustomer.PhoneNo : ""
      );
    }
    if (action === "outfordelivery") {
      //show popup to select employee an then update status
      this.setState({
        selectedSale: sale,
        showOutForDelivery: true,
        SMSNo: sale.DeliveryCustomer.PhoneNo,
        sendSMSForOutForDelivery:
          sale.DeliveryCustomer.PhoneNo.trim() !== "" ? true : false,
      });
      return;
    }

    if (action === "readyforpickup") {
      //show popup to select employee an then update status
      this.setState({
        selectedSale: sale,
        showReadyForPickup: true,
        // SMSNo: sale.DeliveryCustomer.PhoneNo,
        // sendSMSForOutForDelivery: sale.DeliveryCustomer.PhoneNo.trim() !== "" ? true : false
      });
      return;
    }

    if (action === "delivered") {
      if (sale.key !== "") {
        // sale.DeliveryStatus = action
        await this.props.markDelivered(sale);
        if (sale.BalanceAmount > 0) {
          //get sales details
          this.redirectToEditSales(sale, true);
          return;
        }
      }
    }

    if (
      action.toLowerCase() === "accepted" ||
      action.toLowerCase() === "packed" ||
      action.toLowerCase() === "out-for-delivery" ||
      action.toLowerCase() === "order-delivered" ||
      action.toLowerCase() === "order-picked-up"
    ) {
      if (sale.key !== "") {
        if (action.toLowerCase() === "packed") {
          //check if merchant is required to enter invoie no
          if (
            this.props.registration.registration.EnablePincodeMerchantInvoiceNo
          ) {
            this.setState({
              selectedDelivery: sale,
              showMerchantInvoiceNo: true,
              loading: false,
            });
            return;
          }
          // if (
          //   sale.DeliveryBy &&
          //   sale.DeliveryBy.toLowerCase() === "shadowfax"
          // ) {
          //   // console.log("calll shadowfax api");
          //   this.props.shadowfaxPlaceOrder(sale.key);
          // }
        }
        let updateStatus = await this.props.updateONDCDeliveryStatus(
          sale,
          action
        );
        if (updateStatus) {
          this.props.getToastr("Record saved successfully!!!");
        } else {
          alert("Error saving data. Please try again");
        }
      }
    }

    setTimeout(
      function() {
        // this.setState({ searchFrom: 0 ,loading:false})
        this.loadDeliveryDetailReport(
          0,
          this.state.itemsCountPerPage,
          this.state.searchPendingReadyForPickupDeliveredAllRecords,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
      }.bind(this),
      4000
    );
  }

  async redirectToEditSales(delivery, showPayment) {
    this.setState({ showMobileRowOptions: false });
    let saleEdit = await this.props.getSaleDetails(delivery.key);
    saleEdit.key = delivery.key;

    if (showPayment) {
      this.props.history.push({
        pathname: "/home/sales/sales",
        state: {
          type: actionTypes.SALE_EDIT,
          key: saleEdit.key,
          transactionType: saleEdit.TransactionType,
          sale: saleEdit,
          show: "payment",
          module: "deliverylist",
        },
      });
    } else {
      this.props.history.push({
        pathname: "/home/sales/sales",
        state: {
          type: actionTypes.SALE_EDIT,
          key: saleEdit.key,
          transactionType: saleEdit.TransactionType,
          sale: saleEdit,
          module: "deliverylist",
        },
      });
    }
  }

  async onSaveOutForDelivery() {
    if (this.state.multipleRecordsToBeUpdated) {
      this.onStatusUpdateClick(
        this.state.enableONDC ? "Out-for-delivery" : "out for delivery"
      );
    } else {
      if (this.state.enableONDC) {
        // console.log('this.state.deliveryPersonKey',this.state.deliveryPersonKey)
        let sale = this.state.selectedSale;
        sale.DeliveryPersonKey = this.state.deliveryPersonKey;
        sale.DeliveryPersonName = this.state.deliveryPersonName;
        sale.DeliveryPersonMobileNo = this.state.deliveryPersonMobileNo;
        await this.onDeliveryAction(
          "Out-for-delivery",
          this.state.selectedSale
        );
        this.setState({
          showOutForDelivery: false,
          multipleRecordsToBeUpdated: false,
          saving: false,
          deliveryPersonKey: "",
          deliveryPersonMobileNo: "",
          deliveryPersonName: "",
        });
      } else {
        this.props
          .outForDelivery(
            this.state.selectedSale,
            this.state.deliveryPersonName,
            this.state.deliveryPersonKey,
            this.state.deliveryPersonMobileNo
          )
          .then(() => {
            setTimeout(
              function() {
                this.props.getToastr("Record saved successfully!!!");
                this.setState({ searchFrom: 0, showOutForDelivery: false });
                this.loadDeliveryDetailReport(
                  0,
                  this.state.itemsCountPerPage,
                  this.state.searchPendingReadyForPickupDeliveredAllRecords,
                  this.state.searchTransactionNo,
                  this.state.searchTransactionFromDate,
                  this.state.searchTransactionToDate,
                  this.state.searchCustomerDetail,
                  this.state.searchStore,
                  this.state.searchEmployeeName,
                  this.state.orderNo
                );
              }.bind(this),
              2000
            );
          });
      }
    }
  }

  onSaveReadyForPickup() {
    this.props
      .readyForPickup(this.state.selectedSale, this.state.SMSNo)
      .then(() => {
        setTimeout(
          function() {
            this.props.getToastr("Record saved successfully!!!");
            this.setState({ searchFrom: 0, showReadyForPickup: false });
            this.loadDeliveryDetailReport(
              0,
              this.state.itemsCountPerPage,
              this.state.searchPendingReadyForPickupDeliveredAllRecords,
              this.state.searchTransactionNo,
              this.state.searchTransactionFromDate,
              this.state.searchTransactionToDate,
              this.state.searchCustomerDetail,
              this.state.searchStore,
              this.state.searchEmployeeName,
              this.state.orderNo
            );
          }.bind(this),
          2000
        );
      });
  }

  renderFilter() {
    return (
      <React.Fragment>
        {this.state.showFilter && (
          <div
            className={"list-page-filter-overlay"}
            onClick={() => this.setState({ showFilter: false })}
          />
        )}

        <div
          className={
            this.state.showFilter
              ? "list-page-filter list-page-filter-show"
              : "list-page-filter"
          }
          // className="list-page-filter"
          style={{ width: isMobileApp() ? "80%" : "460px" }}
        >
          <div
            className="list-page-filter-close-button"
            onClick={() => this.setState({ showFilter: false })}
          >
            <ImageButton
              type="remove"
              onClick={() => this.setState({ showFilter: false })}
            />
          </div>
          <div className="list-page-filter-container">
            <div className="list-page-filter-header">Filter</div>
            <div
              className="list-page-filter-content"
              style={{ height: window.innerHeight - 110 + "px" }}
            >
              <Input
                label="Store"
                type="select"
                onChange={(value) => this.setState({ searchStore: value })}
                value={this.state.searchStore}
              >
                <option value="">All</option>
                {this.state.stores.map((s) => (
                  <option value={s.key}>
                    {s.StoreCode} {s.StoreName}
                  </option>
                ))}
              </Input>
              <Input
                label="Order No"
                type="text"
                onChange={(value) => this.setState({ orderNo: value })}
                value={this.state.orderNo}
              />
              <Input
                label="Search by customer name, email id, contact no"
                type="text"
                onChange={(value) =>
                  this.setState({ searchCustomerDetail: value })
                }
                value={this.state.searchCustomerDetail}
              />
              <Input
                label="Transaction No"
                type="text"
                onChange={(value) =>
                  this.setState({ searchTransactionNo: value })
                }
                value={this.state.searchTransactionNo}
              />

              {(this.state.searchPendingReadyForPickupDeliveredAllRecords.toLowerCase() ===
                "order-delivered" ||
                this.state.searchPendingReadyForPickupDeliveredAllRecords.toLowerCase() ===
                  "order-picked-up" ||
                this.state.searchPendingReadyForPickupDeliveredAllRecords.toLowerCase() ===
                  "all") && (
                <React.Fragment>
                  <Input
                    label="Transaction From"
                    type="date"
                    onChange={(value) =>
                      this.setState({ searchTransactionFromDate: value })
                    }
                    value={this.state.searchTransactionFromDate}
                  />
                  <Input
                    label="Transaction To"
                    type="date"
                    onChange={(value) =>
                      this.setState({ searchTransactionToDate: value })
                    }
                    value={this.state.searchTransactionToDate}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="list-page-filter-footer">
              <Button
                type="secondary"
                text="Clear"
                onClick={this.onClearSearch}
              />
              <Button
                type="primary"
                text="Search"
                onClick={this.onSearchClick}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderActionButtons(delivery) {
    // console.log("renderActionButtons delivery", delivery.key);
    let showEditButton = false;
    let showUpdateDeliveryDetails = false;
    let showConfirmButton = false;
    let showOutForDeliveryButton = false;
    let showReadyForPickuputton = false;
    let showDeliveredButton = false;
    let showPackedButton = false;
    let showONDCOutForDeliveryButton = false;
    let showONDCOrderPickedUpByCustomerButton = false;
    let showONDCOrderPickedUpByPincodeDeliveryPartnerButton = false;
    let showONDCDeliveredButton = false;
    let showONDCCancelButton = false;
    let showCancelButton = false;
    let showONDCEditButton = false;
    let showAcceptbutton = false;
    let showPlaceShadowFaxOrder = false;
    let showOrderDownloadButton = false;
    if (this.props.registration.registration.EnableOrderDownload) {
      showOrderDownloadButton = true;
    }
    if (this.state.enableONDC) {
      if (
        delivery.DeliveryStatus &&
        delivery.DeliveryBy &&
        delivery.DeliveryStatus.toLowerCase() === "packed" &&
        delivery.DeliveryBy.toLowerCase() === "shadowfax"
      ) {
        if (!delivery.hasOwnProperty("DeliveryPartnerOrderID")) {
          showPlaceShadowFaxOrder = true;
        }
        if (
          delivery.hasOwnProperty("DeliveryPartnerOrderID") &&
          delivery.DeliveryPartnerOrderID === ""
        ) {
          showPlaceShadowFaxOrder = true;
        }
      }
      if (delivery.TransactionStatus.toLowerCase() === "created") {
        showONDCCancelButton = true;
        showONDCEditButton = true;
        showAcceptbutton = true;
      } else if (delivery.DeliveryStatus.toLowerCase() === "pending") {
        showPackedButton = true;
        showONDCCancelButton = true;
        showONDCEditButton = true;
      } else if (delivery.DeliveryStatus.toLowerCase() === "packed") {
        if (
          delivery.DeliveryBy &&
          delivery.DeliveryBy.toLowerCase() === "customer pickup"
        ) {
          showONDCOrderPickedUpByCustomerButton = true;
        } else {
          if (
            delivery.DeliveryType &&
            delivery.DeliveryType.toLowerCase() === "partner"
          ) {
            showONDCOutForDeliveryButton = false;
          } else {
            showONDCOutForDeliveryButton = true;
          }
        }
        showONDCCancelButton = true;
        showONDCEditButton = true;
      } else if (delivery.DeliveryStatus.toLowerCase() === "out-for-delivery") {
        if (
          delivery.DeliveryType &&
          delivery.DeliveryType.toLowerCase() === "partner"
        ) {
          showONDCDeliveredButton = false;
          showONDCEditButton = false;
        } else {
          showONDCDeliveredButton = true;
          showONDCEditButton = true;
        }
        // showONDCDeliveredButton = true;
        showONDCCancelButton = true;
        // showONDCEditButton=true;
      }
      if (
        delivery.DeliveryBy &&
        delivery.DeliveryBy.toLowerCase() === "pincode" &&
        (delivery.DeliveryStatus.toLowerCase() === "packed" ||
          delivery.DeliveryStatus.toLowerCase() === "out-for-delivery" ||
          delivery.DeliveryStatus.toLowerCase() === "order-delivered")
      ) {
        showONDCCancelButton = false;
        showONDCEditButton = false;
        showONDCOrderPickedUpByPincodeDeliveryPartnerButton = true;
      }
      if (this.props.registration.registration.EnablePincodeOrderManagement) {
        showEditButton = false;
        showUpdateDeliveryDetails = false;
        showConfirmButton = false;
        showOutForDeliveryButton = false;
        showReadyForPickuputton = false;
        showDeliveredButton = false;
        showPackedButton = false;
        showONDCOutForDeliveryButton = false;
        showONDCOrderPickedUpByCustomerButton = false;
        showONDCDeliveredButton = false;
        showONDCCancelButton = false;
        showCancelButton = false;
        showONDCEditButton = false;
        showAcceptbutton = false;
        showPlaceShadowFaxOrder = false;
      }
    } else if (!this.state.enableONDC) {
      showCancelButton = true;
      if (
        delivery.DeliveryStatus.toLowerCase() !== "delivered" &&
        delivery.DeliveryStatus.toLowerCase() !== "out for delivery" &&
        delivery.DeliveryStatus.toLowerCase() !== "ready for pickup"
      ) {
        showEditButton = true;
      }
      if (
        delivery.DeliveryStatus.toLowerCase() !== "delivered" &&
        delivery.DeliveryStatus.toLowerCase() !== "ready for pickup"
      ) {
        showUpdateDeliveryDetails = true;
      }
      if (delivery.DeliveryStatus.toLowerCase() === "pending") {
        showConfirmButton = true;
      }
      if (
        delivery.DeliveryStatus.toLowerCase() === "pending" ||
        delivery.DeliveryStatus.toLowerCase() === "confirmed"
      ) {
        showOutForDeliveryButton = true;
      }
      if (
        delivery.DeliveryStatus.toLowerCase() === "pending" ||
        delivery.DeliveryStatus.toLowerCase() === "confirmed"
      ) {
        showReadyForPickuputton = true;
      }
      if (
        delivery.DeliveryStatus.toLowerCase() === "pending" ||
        delivery.DeliveryStatus.toLowerCase() === "out for delivery" ||
        delivery.DeliveryStatus.toLowerCase() === "ready for pickup" ||
        delivery.DeliveryStatus.toLowerCase() === "confirmed"
      ) {
        showDeliveredButton = true;
      }
    }
    return (
      <React.Fragment>
        {showAcceptbutton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat btn-lg"
            onClick={() => this.onDeliveryAction("Accepted", delivery)}
          >
            Accept Order
          </button>
        )}
        {showPackedButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat btn-lg"
            onClick={() => this.onDeliveryAction("Packed", delivery)}
          >
            Packed
          </button>
        )}
        {showONDCOutForDeliveryButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat  btn-lg"
            onClick={() =>
              this.setState({
                showOutForDelivery: true,
                multipleRecordsToBeUpdated: false,
                selectedSale: delivery,
                showMobileRowOptions: false,
              })
            }
          >
            Out for delivery
          </button>
        )}
        {/* {showONDCOrderPickedUpByPincodeDeliveryPartnerButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat  btn-lg"
            onClick={() => this.onDeliveryAction("Order-picked-up", delivery)}
          >
            Order picked up by delivery partner
          </button>
        )} */}
        {showONDCOrderPickedUpByCustomerButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat  btn-lg"
            onClick={() => this.onDeliveryAction("Order-picked-up", delivery)}
          >
            Order picked up by customer
          </button>
        )}
        {showONDCDeliveredButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green  btn-lg btn-flat"
            onClick={() => this.onDeliveryAction("Order-delivered", delivery)}
          >
            Delivered
          </button>
        )}
        {showOrderDownloadButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-lg btn-flat"
            onClick={() => this.onDownloadOrder(delivery)}
          >
            Download Order
          </button>
        )}
        <button
          style={{ marginBottom: "10px", width: "100%" }}
          type="button"
          className="btn btn-md btn-default btn-lg btn-flat"
          onClick={() => this.onPrintClicked(delivery)}
        >
          Print
        </button>
        {isMobileApp() && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat  btn-lg"
            onClick={() =>
              this.setState({
                showMobileOrderDetailWindow: true,
                showMobileRowOptions: false,
              })
            }
          >
            View Order Details
          </button>
        )}
        {showONDCEditButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat  btn-lg"
            onClick={() => this.onShowONDCEdit(delivery)}
          >
            Edit
          </button>
        )}
        {showEditButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat  btn-lg"
            onClick={() => this.redirectToEditSales(delivery, false)}
          >
            <i className="fa fa-edit"></i>Edit
          </button>
        )}
        {showUpdateDeliveryDetails && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat  btn-lg"
            onClick={() =>
              this.setState({
                selectedDelivery: delivery,
                showUpdateDeliveryDetails: true,
                showMobileRowOptions: false,
              })
            }
          >
            Update Delivery Details
          </button>
        )}
        {showConfirmButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default  btn-lg btn-flat"
            onClick={() => this.onDeliveryAction("confirm", delivery)}
          >
            Confirm Order
          </button>
        )}
        {showOutForDeliveryButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat"
            onClick={() => this.onDeliveryAction("outfordelivery", delivery)}
          >
            Out For Delivery
          </button>
        )}
        {showReadyForPickuputton && (
          <Confirm
            style={{ marginBottom: "10px", width: "100%" }}
            onConfirm={() => this.onDeliveryAction("readyforpickup", delivery)}
            body="Are you sure you would like to mark this as Ready for Pickup?"
            confirmText="Ready for Pickup"
            title="Ready for Pickup"
          >
            <button
              style={{ marginBottom: "10px", width: "100%" }}
              className="btn btn-default btn-flat  btn-lg btn-md"
            >
              Ready for Pickup
            </button>
          </Confirm>
        )}
        {showDeliveredButton && (
          <div>
            <Confirm
              style={{ marginBottom: "10px", width: "100%" }}
              onConfirm={() => this.onDeliveryAction("delivered", delivery)}
              body="Are you sure you would like to mark this as Delivered?"
              confirmText="Delivered"
              title="Delivered"
            >
              <button
                style={{ marginBottom: "10px", width: "100%" }}
                className="btn btn-default  btn-lg btn-flat btn-md"
              >
                Delivered
              </button>
            </Confirm>
          </div>
        )}
        {showONDCCancelButton && (
          <button
            type="button"
            className="btn btn-md btn-danger btn-lg btn-flat"
            style={{ marginBottom: "10px", width: "100%" }}
            onClick={() =>
              this.setState({
                showCancel: true,
                selectedRecord: delivery,
                showMobileRowOptions: false,
              })
            }
          >
            Cancel
          </button>
        )}
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-md btn-danger btn-lg btn-flat"
            style={{ marginBottom: "10px", width: "100%" }}
            onClick={() =>
              this.setState({
                showCancel: true,
                selectedRecord: delivery,
                showMobileRowOptions: false,
              })
            }
          >
            Cancel
          </button>
        )}
      </React.Fragment>
    );
  }

  renderStatusFilterText(text, status) {
    // let text = status;
    let count = 0;
    let statusSummary = this.state.statusSummary;
    // console.log("statusSummary",statusSummary)
    if (statusSummary.filter((f) => f.status === status).length > 0) {
      count = statusSummary.filter((f) => f.status === status)[0].count;
    }
    return text + " (" + count + ")";
  }

  renderStatusFilters() {
    return (
      <React.Fragment>
        {this.state.nonONDC && (
          <React.Fragment>
            {/* <button 
                    type="button" 
                    style={{borderTopLeftRadius:"32px", borderBottomLeftRadius:"32px"}}  
                    className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "pending" ? "btn button-flat button-border button-lg button-selected " : "btn button-flat button-border button-lg button-left-rounded"}
                    onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("pending")}>
                    Created
                </button> */}
            <button
              type="button"
              style={{
                borderTopLeftRadius: "32px",
                borderBottomLeftRadius: "32px",
              }}
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "pending"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg button-left-rounded"
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords("pending")
              }
            >
              Pending
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "packed"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "confirmed"
                )
              }
            >
              Confirmed
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "outfordelivery"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg  "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "outfordelivery"
                )
              }
            >
              Out For Delivery
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "readyforpickup"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg  "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "readyforpickup"
                )
              }
            >
              Ready For Pickup
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "delivered"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg  "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "delivered"
                )
              }
            >
              Delivered
            </button>
            <button
              type="button"
              style={{
                borderTopRightRadius: "32px",
                borderBottomRightRadius: "32px",
              }}
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "all"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg"
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords("all")
              }
            >
              All
            </button>
          </React.Fragment>
        )}
        {this.state.enableONDC && (
          <React.Fragment>
            {!this.props.user.store.AutoAcceptOnlineOrders && (
              <React.Fragment>
                <button
                  type="button"
                  style={{
                    borderTopLeftRadius: "32px",
                    borderBottomLeftRadius: "32px",
                  }}
                  className={
                    this.state
                      .searchPendingReadyForPickupDeliveredAllRecords ===
                    "Created"
                      ? "btn button-flat button-border button-lg button-selected  button-left-rounded"
                      : "btn button-flat button-border button-lg button-left-rounded"
                  }
                  onClick={() =>
                    this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                      "Created"
                    )
                  }
                >
                  {this.renderStatusFilterText("Order received", "Created")}
                </button>
                <button
                  type="button"
                  className={
                    this.state
                      .searchPendingReadyForPickupDeliveredAllRecords ===
                    "Accepted"
                      ? "btn button-flat button-border button-lg button-selected "
                      : "btn button-flat button-border button-lg"
                  }
                  onClick={() =>
                    this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                      "Accepted"
                    )
                  }
                >
                  {/* Accepted */}
                  {this.renderStatusFilterText("Accepted", "Accepted")}
                </button>
              </React.Fragment>
            )}
            {this.props.user.store.AutoAcceptOnlineOrders && (
              <button
                type="button"
                style={{
                  borderTopLeftRadius: "32px",
                  borderBottomLeftRadius: "32px",
                }}
                className={
                  this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                  "Accepted"
                    ? "btn button-flat button-border button-lg button-selected "
                    : "btn button-flat button-border button-lg button-left-rounded"
                }
                onClick={() =>
                  this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                    "Accepted"
                  )
                }
              >
                {/* Accepted */}
                {this.renderStatusFilterText("Accepted", "Accepted")}
              </button>
            )}
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "Packed"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords("Packed")
              }
            >
              {this.renderStatusFilterText("Packed", "Packed")}
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "Out-for-delivery"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "Out-for-delivery"
                )
              }
            >
              {this.renderStatusFilterText(
                "Out for delivery",
                "Out-for-delivery"
              )}
            </button>
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "Order-delivered"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "Order-delivered"
                )
              }
            >
              Delivered
            </button>
            {this.props.user.store.CustomerPickUp && (
              <button
                type="button"
                className={
                  this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                  "Order-picked-up"
                    ? "btn button-flat button-border button-lg button-selected "
                    : "btn button-flat button-border button-lg button-left-rounded"
                }
                onClick={() =>
                  this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                    "Order-picked-up"
                  )
                }
              >
                {/* Accepted */}
                Order picked up
              </button>
            )}
            <button
              type="button"
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "Cancelled"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg "
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords(
                  "Cancelled"
                )
              }
            >
              Cancelled
            </button>
            <button
              type="button"
              style={{
                borderTopRightRadius: "32px",
                borderBottomRightRadius: "32px",
              }}
              className={
                this.state.searchPendingReadyForPickupDeliveredAllRecords ===
                "all"
                  ? "btn button-flat button-border button-lg button-selected "
                  : "btn button-flat button-border button-lg"
              }
              onClick={() =>
                this.OnSearchPendingReadyForPickupDeliveredAllRecords("all")
              }
            >
              All
            </button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderQuickActionButtons() {
    if (this.props.registration.registration.EnablePincodeOrderManagement) {
      return;
    }
    return (
      <React.Fragment>
        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "pending" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("confirmed");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Confirm Order
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Accepted" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Packed");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Packed
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Packed" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            // onClick={()=>{this.onStatusUpdateClick("Out-for-delivery")}}
            onClick={() => {
              let recordsToBeUpdated = this.state.deliveryListDetail.filter(
                (f) => f.selected
              );
              if (recordsToBeUpdated.length === 0) {
                alert("No records selected");
                return;
              }
              this.setState({
                showOutForDelivery: true,
                multipleRecordsToBeUpdated: true,
              });
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Out for delivery
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Created" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Accepted");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Accept order
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "Out-for-delivery" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Order-delivered");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Delivered
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "confirmed" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              let recordsToBeUpdated = this.state.deliveryListDetail.filter(
                (f) => f.selected
              );
              if (recordsToBeUpdated.length === 0) {
                alert("No records selected");
                return;
              }
              this.setState({
                showOutForDelivery: true,
                multipleRecordsToBeUpdated: true,
              });
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Out For Delivery
          </button>
        )}

        {this.state.searchPendingReadyForPickupDeliveredAllRecords ===
          "confirmed" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("ready for pickup");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Ready For Pickup
          </button>
        )}
      </React.Fragment>
    );
  }

  onQtyChange(value, index) {
    console.log("value", value);
    console.log("index", index);
    if (value < 0) {
      return;
    }
    let delivery = this.state.selectedDelivery;
    if (value > delivery.LineItems[index].Qty) {
      return;
    }
    delivery.LineItems[index].QtyChange = value;
    this.setState({ selectedDelivery: delivery });
  }

  renderONDCEdit() {
    return (
      <React.Fragment>
        {this.state.showONDCEdit && this.state.enableONDC && (
          <Window
            header={"Edit"}
            width={"600px"}
            processing={this.state.saving}
            onClose={() => this.setState({ showONDCEdit: false })}
            onButtonClick={async (buttonType) => {
              if (buttonType.toLowerCase() === "save") {
                console.log("delivery", this.state.selectedDelivery);
                let allQtyZero = true;
                this.state.selectedDelivery.LineItems.map((l) => {
                  if (
                    l.QtyChange !== "" &&
                    Number(l.QtyChange) > 0 &&
                    l.Product.Name !== "Delivery Charges"
                  ) {
                    allQtyZero = false;
                  }
                });
                if (allQtyZero) {
                  alert("All product quantities can not be zero.");
                  return;
                }
                //now start saving invoice
                this.updateSalesTransaction(this.state.selectedDelivery);
              } else {
                this.setState({ showCancel: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <table className="table table-bordered table-striped delivery-list-table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Product</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {this.state.selectedDelivery.LineItems.map((l, index) => (
                  <tr
                    className={
                      l.Product &&
                      l.Product.Name &&
                      l.Product.Name.toLowerCase() === "delivery charges"
                        ? "deliverylist-tr-noshow"
                        : ""
                    }
                  >
                    <td>{index + 1}</td>
                    <td>{l.Product.Name}</td>
                    <td>
                      <div className="sales-qty-control">
                        <button
                          onClick={(evt) => {
                            let value = Number(l.QtyChange) - 1;
                            this.onQtyChange(value, index);
                          }}
                          type="button"
                          className="btn button-border button-left-rounded small"
                        >
                          <Icon type="minus" />
                        </button>
                        <input
                          type="number"
                          className="sales-qty"
                          onChange={(evt) => {
                            this.onQtyChange(evt.target.value, index);
                          }}
                          value={l.QtyChange}
                        />
                        <button
                          onClick={(evt) => {
                            let value = Number(l.QtyChange) + 1;
                            this.onQtyChange(value, index);
                          }}
                          type="button"
                          className="btn button-border button-right-rounded small"
                        >
                          <Icon type="add" />
                        </button>
                      </div>
                      {/* {l.Product.ProductTypeID===1 &&<Input
                                type="number"
                                value={l.QtyChange}
                                onChange={(value)=>{
                                    if(value<0){
                                        return;
                                    }
                                    let delivery = this.state.selectedDelivery;
                                    if(value >delivery.LineItems[index].Qty ){
                                        return;
                                    }
                                    delivery.LineItems[index].QtyChange = value;
                                    this.setState({selectedDelivery:delivery});
                                }}
                            />} */}
                      {/* {l.Product.ProductTypeID!==1 && <div>{l.QtyChange}</div>} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Window>
        )}
      </React.Fragment>
    );
  }

  returnTotalQty(transaction) {
    let totalQty = 0;
    transaction.LineItems.filter(
      (f) =>
        f.Product &&
        f.Product.hasOwnProperty("Name") &&
        f.Product.Name.toLowerCase() !== "delivery charges"
    ).map((m) => {
      totalQty = totalQty + Number(m.Qty);
    });
    return totalQty;
  }

  showRowCheckBox(delivery) {
    let show = true;
    if (
      delivery.DeliveryBy &&
      delivery.DeliveryBy.toLowerCase() === "customer pickup" &&
      delivery.DeliveryStatus.toLowerCase() === "packed"
    ) {
      show = false;
    }
    if (
      delivery.DeliveryBy &&
      delivery.DeliveryBy.toLowerCase() === "shadowfax" &&
      delivery.DeliveryStatus.toLowerCase() === "packed"
    ) {
      show = false;
    }
    if (
      delivery.DeliveryBy &&
      delivery.DeliveryBy.toLowerCase() === "pincode" &&
      delivery.DeliveryStatus.toLowerCase() === "packed"
    ) {
      show = false;
    }
    // console.log("showRowCheckBox",show)
    return show;
  }

  renderCustomerAddress(delivery) {
    let show = true;
    if (
      delivery.DeliveryBy &&
      delivery.DeliveryBy.toLowerCase() === "customer pickup"
    ) {
      show = false;
    }

    if (show) {
      return (
        <React.Fragment>
          <br></br>
          Delivery Address:
          {delivery.DeliveryCustomerAddress && (
            <div>
              <div>{delivery.DeliveryCustomerAddress.Address1}</div>
              <div>
                {delivery.DeliveryCustomerAddress.Address2}
                {delivery.DeliveryCustomerAddress.City}
              </div>
              <div>
                {delivery.DeliveryCustomerAddress.Country}
                {delivery.DeliveryCustomerAddress.PostalCode}
              </div>
              <div>{delivery.DeliveryCustomerAddress.State}</div>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderCancellationText(sale) {
    let cancellationText = "";
    if (sale.hasOwnProperty("CancellationBy") && sale.CancellationBy !== "") {
      cancellationText = sale.CancellationBy + ": ";
    }
    if (sale.CancellationReason && sale.CancellationReason !== "") {
      cancellationText = cancellationText + sale.CancellationReason;
    }
    return cancellationText;
  }

  renderAuditTrail(delivery) {
    // console.log("delivery.key", delivery.key);
    return (
      <React.Fragment>
        <div>
          Update on:{" "}
          {delivery.ActionOn
            ? new Date(delivery.ActionOn).toString().substring(0, 24)
            : ""}
        </div>
        <AuditTrail
          {...this.props}
          module={constants.MODULE_SALES}
          parentKey={delivery.key}
        />
      </React.Fragment>
    );
  }

  renderMobileOrderDetails() {
    const delivery = this.state.selectedRow;

    return (
      <React.Fragment>
        {this.state.showMobileOrderDetailWindow && (
          <Window
            header={"Order Details"}
            modal={false}
            onClose={() =>
              this.setState({ showMobileOrderDetailWindow: false })
            }
            showBackGroudColor={true}
            buttons={[]}
          >
            <PageSection title="Transaction Details">
              {!delivery.ONDC && delivery.ExternalTransactionNo && (
                <div className="">
                  {" "}
                  <b>Online Order No:</b> {delivery.ExternalTransactionNo}
                </div>
              )}
              {delivery.ONDC &&
                delivery.ExternalTransactionNoPrefix &&
                delivery.ExternalTransactionNoSufix && (
                  <div className="">
                    {" "}
                    <b>Online Order No:</b>{" "}
                    {delivery.ExternalTransactionNoPrefix}
                    <span className="delivery-online-order-sufix">
                      {delivery.ExternalTransactionNoSufix}
                    </span>
                  </div>
                )}
              <div>
                <b>Transaction No: </b>
                {delivery.TransactionNo}
              </div>
              <div>
                <div className="">
                  <b>Online Channel:</b>{" "}
                  {delivery.RequestSource &&
                  delivery.RequestSource.toLowerCase() === "omni web"
                    ? "online"
                    : delivery.RequestSource}
                </div>
              </div>
              <div>
                <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                {delivery.Store.StoreName}
              </div>
              <br></br>
              <div>
                {delivery.DeliveryNotes !== ""
                  ? "Notes: " + delivery.DeliveryNotes
                  : ""}
              </div>
              {delivery.Notes !== "" && <div>Note:{delivery.Notes}</div>}
              <div className="badge">{delivery.DeliveryStatus}</div>
              {/* {delivery.DeliveryBy &&
                delivery.DeliveryBy.toLowerCase() === "customer pickup" && (
                  <React.Fragment>
                    <div className="badge blue uppercase">
                      {delivery.DeliveryBy}
                    </div>
                    {delivery.DeliveryStatus === "Packed" && (
                      <div>
                        Pickup confirmation code: {delivery.Customer.PhoneNo}
                        <b></b>
                      </div>
                    )}
                  </React.Fragment>
                )} */}
              {delivery.DeliveryBy &&
                delivery.DeliveryBy.toLowerCase() === "shadowfax" && (
                  <React.Fragment>
                    <div className="badge orange-red uppercase">
                      Delivery Partner: {delivery.DeliveryBy}
                    </div>
                  </React.Fragment>
                )}
              {delivery.DeliveryBy &&
                delivery.DeliveryBy.toLowerCase() === "pincode" && (
                  <React.Fragment>
                    <div className="badge orange-red uppercase">
                      Delivery by Pincode
                    </div>
                  </React.Fragment>
                )}
              {/* {delivery.DeliveryBy &&
                delivery.DeliveryBy.toLowerCase() === "pincode" &&
                delivery.DeliveryStatus === "Packed" && (
                  <div>
                    Pickup confirmation code:{" "}
                    {delivery.ExternalTransactionNoSufix.slice(-4)}
                    <b></b>
                  </div>
                )} */}
              {delivery.DeliveryPartnerOrderID &&
                delivery.DeliveryPartnerOrderID !== "" && (
                  <div>Delivery ID: {delivery.DeliveryPartnerOrderID}</div>
                )}
              {/* <div className='badge red'>{delivery.CancellationReason}</div> */}
              {this.renderDeliveryOTP(delivery, -1)}
              <div className="badge red">
                {this.renderCancellationText(delivery)}
              </div>
              {delivery.DeliveryPersonName && (
                <React.Fragment>
                  <br />
                  <br />
                  <div>Delivery Person: </div>
                  <div> {delivery.DeliveryPersonName}</div>
                  <div> {delivery.DeliveryPersonMobileNo}</div>
                </React.Fragment>
              )}
            </PageSection>
            <PageSection title="Customer Details">
              {delivery.DeliveryCustomer && (
                <div className="delivery-list-lg-font">
                  {delivery.DeliveryCustomer.FirstName}
                  {delivery.DeliveryCustomer.LastName}
                </div>
              )}
              {delivery.DeliveryCustomer && (
                <div className="delivery-list-lg-font">
                  {delivery.DeliveryCustomer.PhoneNo}
                </div>
              )}
              {this.renderCustomerAddress(delivery)}
              {delivery.DeliveryCustomerAddress &&
                delivery.DeliveryCustomerAddress.Location && (
                  <React.Fragment>
                    <br></br>
                    <div>Customer Lat, Lon</div>
                    <div>
                      {delivery.DeliveryCustomerAddress.Location.lat},
                      {delivery.DeliveryCustomerAddress.Location.lon}
                    </div>
                    <a
                      // style={{
                      //   marginBottom: "10px",
                      //   width: "100%",
                      // }}
                      // type="button"
                      // className="btn btn-md btn-default btn-lg btn-flat"
                      // className="btn btn-secondary"
                      onClick={() =>
                        this.openGoogleMaps(
                          this.props.user.store.Location.lat,
                          this.props.user.store.Location.lon,
                          delivery.DeliveryCustomerAddress.Location.lat,
                          delivery.DeliveryCustomerAddress.Location.lon
                        )
                      }
                    >
                      Click to open maps
                    </a>
                  </React.Fragment>
                )}
            </PageSection>
            <PageSection title="Order Details">
              <table className="table table-bordered table-striped delivery-list-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.hasOwnProperty("Name") &&
                      f.Product.Name.toLowerCase() !== "delivery charges" &&
                      f.Product.Name.toLowerCase() !== "packing charges"
                  ).map((m, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {m.Product.SKU}
                        {m.Product.Name}
                        <div>
                          <i>{m.Notes}</i>
                        </div>
                      </td>
                      <td className="align-right">{m.Qty}</td>
                      <td className="align-right">{m.RetailPrice}</td>
                      <td className="align-right">{m.TotalTax}</td>
                      <td className="align-right">{m.Amount}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr></tr>
                  <tr>
                    <td colSpan={5}>Total Qty</td>
                    <td className="align-right">
                      {this.returnTotalQty(delivery)}
                    </td>
                  </tr>
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.Name &&
                      f.Product.Name.toLowerCase() === "delivery charges"
                  ).map((m) => (
                    <tr>
                      <td colSpan={5}>Delivery Charges</td>
                      <td className="align-right">{m.RetailPrice}</td>
                    </tr>
                  ))}
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.Name &&
                      f.Product.Name.toLowerCase() === "packing charges"
                  ).map((m) => (
                    <tr>
                      <td colSpan={5}>Packing Charges</td>
                      <td className="align-right">{m.RetailPrice}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>Total Tax</td>
                    <td className="align-right">{delivery.BillTaxAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>Total Amount</td>
                    <td className="align-right">
                      <b>{delivery.BillAmount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Paid Amount{" "}
                      {delivery.Payments.map((p) => (
                        <React.Fragment>
                          <br />
                          <i>
                            {p.PaymentMode} ({p.Amount})
                          </i>
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="align-right">
                      <b>{delivery.BillPaidAmount}</b>
                    </td>
                  </tr>
                  {delivery.BalanceAmount > 0 && (
                    <tr>
                      <td colSpan={5}>Balance Amount</td>
                      <td className="align-right delivery-balance-amt">
                        <b>{delivery.BalanceAmount}</b>
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </PageSection>
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderMobileRowOptions() {
    return (
      <React.Fragment>
        {this.state.showMobileRowOptions && (
          <Window
            header={""}
            height={"200px"}
            modal={true}
            onClose={() => this.setState({ showMobileRowOptions: false })}
            buttons={[]}
          >
            {this.renderActionButtons(this.state.selectedRow)}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderLineItems(delivery) {
    // console.log("delivery", delivery);
    let lineItems = [];
    let orderLineItems = [];
    if (delivery.hasOwnProperty("OrderLineItems")) {
      orderLineItems = delivery.OrderLineItems;
    }
    for (let i = 0; i < delivery.LineItems.length; i++) {
      if (
        orderLineItems.filter(
          (f) => f.ProductID == delivery.LineItems[i].Product.key
        ).length > 0
      ) {
        let orderItem = orderLineItems.filter(
          (f) => f.ProductID == delivery.LineItems[i].Product.key
        )[0];
        if (orderItem.Qty !== delivery.LineItems[i].Qty) {
          delivery.LineItems[i].CancelledQty =
            Number(orderItem.Qty) - Number(delivery.LineItems[i].Qty);
        }
      }
      lineItems.push(delivery.LineItems[i]);
    }
    for (let i = 0; i < orderLineItems.length; i++) {
      if (
        delivery.LineItems.filter(
          (f) => orderLineItems[i].ProductID == f.Product.key
        ).length === 0
      ) {
        lineItems.push({
          Product: {
            Name: orderLineItems[i].ProductName,
            SKU: orderLineItems[i].hasOwnProperty("ProductSKU")
              ? orderLineItems[i].ProductSKU
              : "",
            BarCode: orderLineItems[i].hasOwnProperty("ProductBarCode")
              ? orderLineItems[i].ProductBarCode
              : "",
            Images: orderLineItems[i].hasOwnProperty("Images")
              ? orderLineItems[i].Images
              : [],
          },
          Qty: 0,
          Price: orderLineItems[i].RetailPrice,
          Tax: 0,
          Amount: 0,
          CancelledQty: orderLineItems[i].Qty,
        });
      }
    }

    // console.log("delivery", delivery);
    return (
      <table className="table table-bordered table-striped delivery-list-table">
        <thead>
          <tr>
            <th>#</th>
            <th className="delivery-list-image-th-width"></th>
            <th>Product</th>
            <th>Qty</th>
            <th>MRP</th>
            <th>Selling Price</th>
            <th>Tax</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {lineItems
            .filter(
              (f) =>
                f.Product &&
                f.Product.hasOwnProperty("Name") &&
                f.Product.Name.toLowerCase() !== "delivery charges" &&
                f.Product.Name.toLowerCase() !== "packing charges"
            )
            .map((m, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{this.renderProductImage(m.Product)}</td>
                <td>
                  <div>{m.Product.Name}</div>
                  {/* {this.props.registration.registration.EnablePincodeSKU && ( */}
                  <div>{m.Product.SKU}</div>
                  {/* )} */}
                  <div>{m.Product.BarCode}</div>
                  <div>
                    <i>{m.Notes}</i>
                  </div>
                  {m.CancelledQty && m.CancelledQty > 0 && (
                    <div className="badge">Cancelled Qty: {m.CancelledQty}</div>
                  )}
                  {this.props.registration.registration.key ===
                    "idp0AOFiTMEwgLHYnhtA" && (
                    <React.Fragment>
                      <div>Category:{m.Product.Category}</div>
                      <div>Sub Category:{m.Product.SubCategory}</div>
                    </React.Fragment>
                  )}
                </td>
                <td className="align-right">{m.Qty}</td>
                <td className="align-right">{m.Product.MRP}</td>
                <td className="align-right">{m.RetailPrice}</td>
                <td className="align-right">{m.TotalTax}</td>
                <td className="align-right">{m.Amount}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr></tr>
          <tr>
            <td colSpan={7}>Total Qty</td>
            <td className="align-right">{this.returnTotalQty(delivery)}</td>
          </tr>
          {delivery.LineItems.filter(
            (f) =>
              f.Product &&
              f.Product.Name &&
              f.Product.Name.toLowerCase() === "delivery charges"
          ).map((m) => (
            <tr>
              <td colSpan={7}>Delivery Charges</td>
              <td className="align-right">{m.RetailPrice}</td>
            </tr>
          ))}
          {delivery.LineItems.filter(
            (f) =>
              f.Product &&
              f.Product.Name &&
              f.Product.Name.toLowerCase() === "packing charges"
          ).map((m) => (
            <tr>
              <td colSpan={7}>Packing Charges</td>
              <td className="align-right">{m.RetailPrice}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={7}>Total Tax</td>
            <td className="align-right">{delivery.BillTaxAmount}</td>
          </tr>
          <tr>
            <td colSpan={7}>Total Amount</td>
            <td className="align-right">
              <b>{delivery.BillAmount}</b>
            </td>
          </tr>
          <tr>
            <td colSpan={7}>
              Paid Amount{" "}
              {delivery.Payments.map((p) => (
                <React.Fragment>
                  <br />
                  <i>
                    {p.PaymentMode} ({p.Amount})
                  </i>
                </React.Fragment>
              ))}
            </td>
            <td className="align-right">
              <b>{delivery.BillPaidAmount}</b>
            </td>
          </tr>
          {delivery.BalanceAmount > 0 && (
            <tr>
              <td colSpan={7}>Balance Amount</td>
              <td className="align-right delivery-balance-amt">
                <b>{delivery.BalanceAmount}</b>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="list-page-mobile-background"></div>
        <div className="list-page-mobile-header">
          <div className="left-70">
            {/* <ImageButton type={"leftarrow"} label={"back"} onClick={()=>this.props.history.push("/home")}/> */}
            <Menu {...this.props} />
            <div className="list-page-mobile-header-title">Online Order(s)</div>
          </div>
          <div className="right-30">
            <ImageButton
              type="filter"
              label=""
              onClick={() =>
                this.setState({ showFilter: !this.state.showFilter })
              }
            />
          </div>
        </div>
        <div className="delivery-list-mobile-status-filter">
          {this.renderStatusFilters()}
        </div>
        <div className="delivery-list-mobile-page-content">
          {this.state.loading && <Spinner />}
          {this.state.deliveryListDetail.length === 0 && !this.state.loading && (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <RecordNotFound />
            </div>
          )}
          <div
            style={{
              height: window.innerHeight - 155 + "px",
              overflowY: "auto",
            }}
          >
            {this.state.deliveryListDetail.map((delivery, index) => (
              <div className="delivery-list-mobile-row-seperator">
                <div
                  onClick={() =>
                    this.setState({
                      showMobileRowOptions: true,
                      selectedRow: delivery,
                    })
                  }
                >
                  {!delivery.ONDC && delivery.ExternalTransactionNo && (
                    <div className="">
                      {" "}
                      <b>Online Order No:</b> {delivery.ExternalTransactionNo}
                    </div>
                  )}
                  {delivery.ONDC &&
                    delivery.ExternalTransactionNoPrefix &&
                    delivery.ExternalTransactionNoSufix && (
                      <div className="">
                        {" "}
                        <b>Online Order No:</b>{" "}
                        {delivery.ExternalTransactionNoPrefix}
                        <span className="delivery-online-order-sufix">
                          {delivery.ExternalTransactionNoSufix}
                        </span>
                      </div>
                    )}
                  <div>
                    {new Date(delivery.TransactionDate)
                      .toString()
                      .substring(0, 24)}
                  </div>
                  <div>
                    <b>Transaction No: </b>
                    {delivery.TransactionNo}
                  </div>
                  {delivery.DeliveryTime.HH != "" &&
                    delivery.DeliveryTime.MM !== "" && (
                      <span>
                        {delivery.DeliveryTime.HH +
                          ":" +
                          delivery.DeliveryTime.MM}{" "}
                        <br />{" "}
                      </span>
                    )}
                  <br></br>
                  <div>
                    <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                    {delivery.Store.StoreName}
                  </div>
                  <br></br>
                  <div>Customer:</div>
                  {delivery.DeliveryCustomer && (
                    <div>
                      {delivery.DeliveryCustomer.FirstName}
                      {delivery.DeliveryCustomer.LastName}
                    </div>
                  )}
                  {delivery.DeliveryCustomer && (
                    <div>{delivery.DeliveryCustomer.PhoneNo}</div>
                  )}
                  {this.renderCustomerAddress(delivery)}

                  <div className="badge">{delivery.DeliveryStatus}</div>
                  <br />
                  {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "shadowfax" && (
                      <React.Fragment>
                        <div className="badge orange-red uppercase">
                          Delivery Partner: {delivery.DeliveryBy}
                        </div>
                      </React.Fragment>
                    )}
                  {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "pincode" && (
                      <React.Fragment>
                        <div className="badge orange-red uppercase">
                          Delivery by Pincode
                        </div>
                      </React.Fragment>
                    )}
                  {/* {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "pincode" &&
                    delivery.DeliveryStatus === "Packed" && (
                      <div>
                        Pickup confirmation code:{" "}
                        {delivery.ExternalTransactionNoSufix.slice(-4)}
                        <b></b>
                      </div>
                    )} */}
                  {delivery.DeliveryPartnerOrderID &&
                    delivery.DeliveryPartnerOrderID !== "" && (
                      <div>Delivery ID: {delivery.DeliveryPartnerOrderID}</div>
                    )}
                  {this.renderDeliveryOTP(delivery, index)}
                  {/* <div className='badge red'>{delivery.CancellationReason}</div> */}
                  <div className="badge red">
                    {this.renderCancellationText(delivery)}
                  </div>
                  {delivery.DeliveryPersonName && (
                    <React.Fragment>
                      <br />
                      <br />
                      <div>Delivery Person: </div>
                      <div> {delivery.DeliveryPersonName}</div>
                      <div> {delivery.DeliveryPersonMobileNo}</div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  openGoogleMaps(startLat, startLon, endLat, endLon) {
    // Replace these values with your actual start and end coordinates
    // var startLat = 37.7749;  // Example start latitude
    // var startLon = -122.4194;  // Example start longitude
    // var endLat = 34.0522;  // Example end latitude
    // var endLon = -118.2437;  // Example end longitude

    // Create the Google Maps URL with the specified start and end coordinates
    var mapsUrl =
      "https://www.google.com/maps/dir/?api=1&origin=" +
      startLat +
      "," +
      startLon +
      "&destination=" +
      endLat +
      "," +
      endLon;

    // Open the URL in a new browser tab
    window.open(mapsUrl, "_blank");
  }

  renderDeliveryOTP(delivery, index) {
    let otp = "";
    let returnOTP = "";

    if (
      delivery.hasOwnProperty("DeliveryPickUpOTP") &&
      delivery.DeliveryPickUpOTP !== ""
    ) {
      otp = delivery.DeliveryPickUpOTP;
    } else {
      if (
        delivery.DeliveryBy &&
        delivery.DeliveryBy.toLowerCase() === "pincode" &&
        delivery.DeliveryStatus === "Packed"
      ) {
        otp = delivery.ExternalTransactionNoSufix.slice(-4);
      } else if (
        delivery.DeliveryBy &&
        delivery.DeliveryBy.toLowerCase() === "customer pickup" &&
        delivery.DeliveryStatus === "Packed"
      ) {
        otp = delivery.Customer.PhoneNo;
      }
    }
    if (
      delivery.hasOwnProperty("DeliveryReturnOTP") &&
      delivery.DeliveryReturnOTP !== "" &&
      (delivery.DeliveryStatus.toLowerCase() === "out-for-delivery" ||
        delivery.DeliveryStatus.toLowerCase() === "packed" ||
        delivery.DeliveryStatus.toLowerCase() === "cancelled")
    ) {
      returnOTP = delivery.DeliveryReturnOTP;
    }

    return (
      <>
        {otp && otp !== "" && (
          <div className="delivery-otp">
            Pickup confirmation code:<b> {otp}</b>
          </div>
        )}

        {returnOTP && returnOTP !== "" && (
          <>
            {!delivery.ShowReturnOTP && (
              <div className="return-otp-btn">
                <a
                  onClick={() => {
                    let deliveryListDetail = this.state.deliveryListDetail;
                    deliveryListDetail[index].ShowReturnOTP = true;
                    this.setState({ deliveryListDetail: deliveryListDetail });
                  }}
                >
                  Click here to view return confirmation code
                </a>
              </div>
            )}
            {delivery.ShowReturnOTP && (
              <div className="return-opt">
                Return confirmation code: <b>{returnOTP}</b>
              </div>
            )}
          </>
        )}

        {/* Return confirmation code: {returnOTP} */}
      </>
    );
    // {
    //   delivery.DeliveryBy &&
    //     delivery.DeliveryBy.toLowerCase() === "pincode" &&
    //     delivery.DeliveryStatus === "Packed" && (
    //       <div>
    //         Pickup confirmation code:{" "}
    //         {delivery.ExternalTransactionNoSufix.slice(-4)}
    //         <b></b>
    //       </div>
    //     );
    // }
    // {
    //   delivery.DeliveryBy &&
    //     delivery.DeliveryBy.toLowerCase() === "customer pickup" && (
    //       <React.Fragment>
    //         <div className="badge blue uppercase">{delivery.DeliveryBy}</div>
    //         {delivery.DeliveryStatus === "Packed" && (
    //           <div>
    //             Pickup confirmation code: {delivery.Customer.PhoneNo}
    //             <b></b>
    //           </div>
    //         )}
    //       </React.Fragment>
    //     );
    // }
    // DeliveryPickUpOTP;
    // DeliveryReturnOTP;
  }
  renderDesktop() {
    const btnColStyle = { width: "140px" };
    return (
      <React.Fragment>
        {!this.state.print && (
          <div className="header-margin">
            <div className="list-page">
              <div className="list-page-header">
                <div className="left left-20">
                  <div className="list-page-header-title">
                    {/* {this.props.config.WhiteLabel.Code === "PINCODE"
                      ? "Online Order(s)"
                      : "Delivery List"} */}
                    {this.state.enableONDC
                      ? "Online Order(s)"
                      : this.state.nonONDC
                      ? "Delivery List"
                      : ""}
                  </div>
                </div>
                <div className="right right-80">
                  <div
                    className="btn-group pull-right hidden-xs"
                    style={{ paddingRight: "10px" }}
                  >
                    {this.renderStatusFilters()}
                  </div>
                  <ImageButton
                    type="filter"
                    label=""
                    onClick={() =>
                      this.setState({ showFilter: !this.state.showFilter })
                    }
                  />
                </div>
              </div>
              <div
                className="list-page-content"
                style={{
                  height: window.innerHeight - 210 + "px",
                  overflowY: "auto",
                }}
              >
                {/* {this.state.loading && <Loader show={this.state.loading} />} */}
                {this.state.loading && <Spinner hideOverlay={true} />}
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    {this.renderQuickActionButtons()}
                  </div>
                </div>
                <table className="table table-bordered table-striped delivery-list-table">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>
                        <input
                          type="checkbox"
                          checked={this.state.selected}
                          onChange={() => {
                            let selected = !this.state.selected;
                            let deliveryListDetail = this.state
                              .deliveryListDetail;
                            deliveryListDetail.map((m) => {
                              m.selected = selected;
                            });
                            this.setState({
                              selected: selected,
                              deliveryListDetail: deliveryListDetail,
                            });
                          }}
                        />
                      </th>
                      <th style={{ width: "10%" }}>Date</th>
                      <th style={{ width: "20%" }}>Customer Details</th>
                      {/* <th style={{ width: "20%" }}>Delivery Address</th> */}
                      <th style={{ width: "50%" }}>Transaction Details</th>
                      <th style={{ width: "10%" }}>Delivery Status</th>
                      <th style={btnColStyle}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.deliveryListDetail.map((delivery, index) => (
                      <tr>
                        <td>
                          {this.showRowCheckBox(delivery) && (
                            <input
                              type="checkbox"
                              checked={delivery.selected}
                              onChange={() => {
                                let deliveryListDetail = this.state
                                  .deliveryListDetail;
                                deliveryListDetail[
                                  index
                                ].selected = !deliveryListDetail[index]
                                  .selected;
                                this.setState({
                                  deliveryListDetail: deliveryListDetail,
                                });
                              }}
                              disabled={() => {}}
                            />
                          )}
                        </td>
                        <td>
                          <div>
                            {new Date(delivery.TransactionDate)
                              .toString()
                              .substring(0, 24)}
                          </div>
                          {/* <div>{delivery.TransactionDate}</div><br/> */}
                          {/* {delivery.DeliveryDate}<br/> */}
                          {delivery.DeliveryTime.HH != "" &&
                            delivery.DeliveryTime.MM !== "" && (
                              <span>
                                {delivery.DeliveryTime.HH +
                                  ":" +
                                  delivery.DeliveryTime.MM}{" "}
                                <br />{" "}
                              </span>
                            )}
                        </td>
                        <td>
                          {delivery.DeliveryCustomer && (
                            <div className="">
                              {/* delivery-list-lg-font */}
                              <b>
                                {delivery.DeliveryCustomer.FirstName}
                                {delivery.DeliveryCustomer.LastName}
                              </b>
                            </div>
                          )}
                          {delivery.DeliveryCustomer && (
                            <div className="">
                              {/* delivery-list-lg-font */}
                              <b> {delivery.DeliveryCustomer.PhoneNo}</b>
                            </div>
                          )}
                          {this.renderCustomerAddress(delivery)}

                          {delivery.DeliveryCustomerAddress &&
                            delivery.DeliveryCustomerAddress.Location && (
                              <React.Fragment>
                                <br></br>
                                <div>Customer Lat, Lon</div>
                                <div>
                                  {
                                    delivery.DeliveryCustomerAddress.Location
                                      .lat
                                  }
                                  ,
                                  {
                                    delivery.DeliveryCustomerAddress.Location
                                      .lon
                                  }
                                </div>
                                <a
                                  // style={{
                                  //   marginBottom: "10px",
                                  //   width: "100%",
                                  // }}
                                  // type="button"
                                  // // className="btn btn-md btn-default btn-lg btn-flat"
                                  // className="btn btn-secondary"
                                  onClick={() =>
                                    this.openGoogleMaps(
                                      this.props.user.store.Location.lat,
                                      this.props.user.store.Location.lon,
                                      delivery.DeliveryCustomerAddress.Location
                                        .lat,
                                      delivery.DeliveryCustomerAddress.Location
                                        .lon
                                    )
                                  }
                                >
                                  Click to open maps
                                </a>
                              </React.Fragment>
                            )}
                        </td>
                        <td>
                          {!delivery.ONDC && delivery.ExternalTransactionNo && (
                            <div className="">
                              {" "}
                              <b>Online Order No:</b>{" "}
                              {delivery.ExternalTransactionNo}
                            </div>
                          )}
                          {delivery.ONDC &&
                            delivery.ExternalTransactionNoPrefix &&
                            delivery.ExternalTransactionNoSufix && (
                              <div className="">
                                {" "}
                                <b>Online Order No:</b>{" "}
                                {delivery.ExternalTransactionNoPrefix}
                                <span className="delivery-online-order-sufix">
                                  {delivery.ExternalTransactionNoSufix}
                                </span>
                              </div>
                            )}
                          <div>
                            <b>Transaction No: </b>
                            {delivery.TransactionNo}
                          </div>
                          {/* <div>
                            <div className="">
                              <b>Online Channel:</b>{" "}
                              {delivery.RequestSource &&
                              delivery.RequestSource.toLowerCase() ===
                                "omni web"
                                ? "online"
                                : delivery.RequestSource}
                            </div>
                          </div> */}
                          <div>
                            <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                            {delivery.Store.StoreName}
                          </div>
                          <div className="deliverylist-showmore">
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                let deliveryListDetail = this.state
                                  .deliveryListDetail;
                                deliveryListDetail[
                                  index
                                ].showMore = !deliveryListDetail[index]
                                  .showMore;
                                this.setState({
                                  deliveryListDetail: deliveryListDetail,
                                });
                              }}
                            >
                              {delivery.showMore
                                ? "Hide Details"
                                : "Show Details"}
                            </button>
                          </div>
                          {delivery.showMore && this.renderLineItems(delivery)}
                          <div>
                            {delivery.DeliveryNotes !== ""
                              ? "Notes: " + delivery.DeliveryNotes
                              : ""}
                          </div>
                          {delivery.Notes !== "" && (
                            <div>Note:{delivery.Notes}</div>
                          )}
                        </td>
                        <td>
                          <div>{delivery.DeliveryStatus}</div>
                          {/* <br></br> */}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() ===
                              "shadowfax" && (
                              <div className="badge orange-red uppercase">
                                Delivery Partner: {delivery.DeliveryBy}
                              </div>
                            )}
                          {delivery.DeliveryPartnerOrderID &&
                            delivery.DeliveryPartnerOrderID !== "" && (
                              <div>
                                Delivery ID: {delivery.DeliveryPartnerOrderID}
                              </div>
                            )}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() === "pincode" && (
                              <div className="badge orange-red uppercase">
                                Delivery by Pincode
                              </div>
                            )}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() ===
                              "customer pickup" && (
                              <div className="badge blue uppercase">
                                {delivery.DeliveryBy}
                              </div>
                            )}

                          {this.renderDeliveryOTP(delivery, index)}
                          <div className="badge red">
                            {this.renderCancellationText(delivery)}
                          </div>
                          {delivery.DeliveryPersonName && (
                            <React.Fragment>
                              <br />
                              <br />
                              <div>Delivery Person: </div>
                              <div> {delivery.DeliveryPersonName}</div>
                              <div> {delivery.DeliveryPersonMobileNo}</div>
                            </React.Fragment>
                          )}
                          <br />
                          {this.renderAuditTrail(delivery)}
                        </td>
                        <td>{this.renderActionButtons(delivery)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* } */}
              </div>
              <div className="list-page-footer">
                <div className="left">{this.showPaginationStatus()}</div>
                <div className="right">
                  <div className="list-page-footer-pagination">
                    <ImageButton
                      type={"leftarrowdouble"}
                      label={"First"}
                      onClick={() => this.onFirstClick()}
                    />
                    <ImageButton
                      type={"leftarrow"}
                      label={"Previous"}
                      onClick={() => this.onPreviousClick()}
                    />
                    <Input
                      type="select"
                      value={this.state.itemsCountPerPage}
                      onChange={(value) =>
                        this.onRecordsPerPageCountChange(value)
                      }
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </Input>
                    <ImageButton
                      type={"rightarrow"}
                      label={"Next"}
                      onClick={() => this.onNextClick()}
                    />
                    <ImageButton
                      type={"rightarrowdouble"}
                      label={"Last"}
                      onClick={() => this.onLastClick()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderProductImage(product) {
    let image =
      product.Images && product.Images.length > 0 ? product.Images[0] : null;
    if (image) {
      return (
        <div>
          <img className="delivery-list-product-image" src={image.ImagePath} />
        </div>
      );
    }
  }

  renderMerchantInvoiceNo() {
    return (
      <React.Fragment>
        {this.state.showMerchantInvoiceNo && (
          <Window
            header={"Invoice No"}
            width={"400px"}
            processing={this.state.saving}
            onClose={() => this.setState({ showMerchantInvoiceNo: false })}
            onButtonClick={async (btn) => {
              if (btn === "Save") {
                if (this.state.merchantInvoiceNo.trim() == "") {
                  this.setState({
                    merchantInvoiceNoError: "Please provide invoice no",
                  });
                  return;
                }
                if (
                  this.props.registration.registration.hasOwnProperty(
                    "EnablePincodeMerchantInvoiceNoRegExp"
                  ) &&
                  this.props.registration.registration
                    .EnablePincodeMerchantInvoiceNoRegExp !== ""
                ) {
                  console.log(
                    "EnablePincodeMerchantInvoiceNoRegExp",
                    this.props.registration.registration
                      .EnablePincodeMerchantInvoiceNoRegExp
                  );
                  const regex = new RegExp(
                    this.props.registration.registration.EnablePincodeMerchantInvoiceNoRegExp,
                    ""
                  );
                  let result = regex.test(this.state.merchantInvoiceNo.trim());
                  console.log("result", result);
                  if (!result) {
                    this.setState({
                      merchantInvoiceNoError: "Invalid invoice no",
                    });
                    return;
                  }
                }
                // return;
                //now check if invoice no is not duplicate
                this.setState({ saving: true });
                let sale = this.state.selectedDelivery;
                let updateStatus = await this.props.updateONDCDeliveryStatus(
                  sale,
                  "Packed",
                  this.state.merchantInvoiceNo,
                  this.state.merchantInvoiceAmount
                );
                if (updateStatus) {
                  this.props.getToastr("Record saved successfully!!!");
                } else {
                  alert("Error saving data. Please try again");
                }
                setTimeout(
                  function() {
                    //Start the timer
                    this.onSearchClick();
                    this.setState({
                      showMerchantInvoiceNo: false,
                      merchantInvoiceNo: "",
                      merchantInvoiceNoError: "",
                      merchantInvoiceAmount: "",
                      saving: false,
                    });
                  }.bind(this),
                  6000
                );
                // this.props.getToastr("Record saved successfully!!!");
              } else {
                this.setState({
                  showMerchantInvoiceNo: false,
                  merchantInvoiceNo: "",
                  merchantInvoiceNoError: "",
                  merchantInvoiceAmount: "",
                  saving: false,
                });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <Input
              type="text"
              label="Please provide invoice no"
              value={this.state.merchantInvoiceNo}
              onChange={(value) =>
                this.setState({
                  merchantInvoiceNo: value,
                  merchantInvoiceNoError: "",
                })
              }
              error={this.state.merchantInvoiceNoError}
            />
            <Input
              type="number"
              label="Please provide invoice amount"
              value={this.state.merchantInvoiceAmount}
              onChange={(value) =>
                this.setState({
                  merchantInvoiceAmount: value,
                })
              }
            />
          </Window>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFilter()}

        {this.renderONDCEdit()}

        {this.state.print && (
          <Print
            {...this.props}
            printChecked={this.state.printChecked}
            sendEmailChecked={this.state.sendEmailChecked}
            sendSMSChecked={false}
            printData={this.state.printData}
            onClose={() => {
              this.setState({ print: false });
            }}
          />
        )}

        {this.state.showCancel && !this.state.enableONDC && (
          <CancelSales
            {...this.props}
            salesID={this.state.selectedRecord.key}
            onSave={() => {
              this.setState({ loading: true });
              setTimeout(
                function() {
                  //Start the timer
                  this.loadDeliveryDetailReport(
                    this.state.recordFrom,
                    this.state.itemsCountPerPage,
                    this.state.searchPendingReadyForPickupDeliveredAllRecords,
                    this.state.searchTransactionNo,
                    this.state.searchTransactionFromDate,
                    this.state.searchTransactionToDate,
                    this.state.searchCustomerDetail,
                    this.state.searchStore,
                    this.state.searchEmployeeName,
                    this.state.orderNo
                  );
                }.bind(this),
                2000
              );
              this.setState({ showCancel: false });
            }}
            onClose={() => this.setState({ showCancel: false })}
          />
        )}

        {this.state.selectedDelivery && this.state.showUpdateDeliveryDetails && (
          <Delivery
            {...this.props}
            mobile={this.state.mobile}
            deliveryCustomer={this.state.selectedDelivery.DeliveryCustomer}
            deliveryCustomerAddress={
              this.state.selectedDelivery.DeliveryCustomerAddress
            }
            deliveryDate={this.state.selectedDelivery.DeliveryDate}
            deliveryTime={this.state.selectedDelivery.DeliveryTime}
            deliveryNotes={this.state.selectedDelivery.DeliveryNotes}
            onAddCustomer={(newCustomer) => {
              this.setState({
                showAddCustomer: true,
                showInvoice: false,
                newCustomer: newCustomer,
              });
            }}
            onCustomerEdit={(deliveryCustomer) => {
              this.setState({
                tmpDeliveryCustomer: deliveryCustomer,
                showAddCustomer: true,
                showInvoice: false,
                fromDelivery: true,
                showDelivery: false,
              });
            }}
            onClose={() =>
              this.setState({
                selectedDelivery: null,
                showUpdateDeliveryDetails: false,
              })
            }
            onSave={async (
              deliveryCustomer,
              deliveryCustomerAddress,
              deliveryDate,
              deliveryTime,
              deliveryNotes,
              placeOfSupply
            ) => {
              // console.log('selectedDelivery', this.state.selectedDelivery)
              // return
              this.setState({
                selectedDelivery: null,
                showUpdateDeliveryDetails: false,
              });
              let deliveryDetails = {
                PlaceOfSupply: placeOfSupply,
                DeliveryCustomer: deliveryCustomer,
                DeliveryCustomerAddress: deliveryCustomerAddress,
                DeliveryDate: deliveryDate,
                DeliveryTime: deliveryTime,
                DeliveryNotes: deliveryNotes,
              };
              this.props
                .updateDeliveryDetails(
                  deliveryDetails,
                  this.state.selectedDelivery.key
                )
                .then(() => {
                  setTimeout(
                    function() {
                      //Start the timer
                      this.onSearchClick();
                    }.bind(this),
                    2000
                  );
                });
            }}
          />
        )}

        {this.state.showCancel && this.state.enableONDC && (
          <Window
            header={"Cancel"}
            width={"600px"}
            processing={this.state.saving}
            onClose={() => this.setState({ showCancel: false })}
            onButtonClick={async (buttonType) => {
              if (buttonType.toLowerCase() === "ok") {
                if (this.state.ONDCCancellationReasonCode.trim() === "") {
                  this.setState({
                    ONDCCancellationReasonError:
                      "Please select cancellation reason",
                  });
                  return;
                }
                this.setState({ saving: true });

                let cancelReponse = await this.props.cancelSales(
                  this.state.ONDCCancellationReason,
                  this.state.selectedRecord.key,
                  this.state.ONDCCancellationReasonCode.trim(),
                  this.state.selectedRecord
                );
                console.log("cancelReponse", cancelReponse);
                if (!cancelReponse) {
                  alert("Unable to cancel order. Please try again.");
                  this.setState({ saving: false, showCancel: false });
                  return;
                }
                if (
                  this.state.selectedRecord.DeliveryPartnerOrderID &&
                  this.state.selectedRecord.DeliveryPartnerOrderID !== ""
                ) {
                  this.props.shadowfaxCancelOrder(
                    this.state.selectedRecord.DeliveryPartnerOrderID,
                    this.state.ONDCCancellationReason
                  );
                }
                this.props.getToastr("Transaction cancelled successfully");
                setTimeout(
                  function() {
                    //Start the timer
                    this.setState({ saving: false, showCancel: false });
                    this.onSearchClick();
                  }.bind(this),
                  5000
                );
              } else {
                this.setState({ showCancel: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="select"
              label="Please select cancellation reason"
              value={this.state.ONDCCancellationReasonCode}
              error={this.state.ONDCCancellationReasonError}
              onChange={(value) => {
                let ONDCCancellationReasonCode = value;
                let ONDCCancellationReason = "";
                if (
                  this.state.cancellationReasonCodes.filter(
                    (f) => f.code === ONDCCancellationReasonCode
                  ).length > 0
                ) {
                  ONDCCancellationReason = this.state.cancellationReasonCodes.filter(
                    (f) => f.code === ONDCCancellationReasonCode
                  )[0].reason;
                }
                this.setState({
                  ONDCCancellationReasonCode: ONDCCancellationReasonCode,
                  ONDCCancellationReason: ONDCCancellationReason,
                  ONDCCancellationReasonError: "",
                });
              }}
            >
              <option value="">Please select</option>
              {this.state.cancellationReasonCodes.map((c) => (
                <option value={c.code}>{c.reason}</option>
              ))}
            </Input>
          </Window>
        )}

        {this.state.showOutForDelivery && (
          <Window
            header={"Out for delivery"}
            width={"400px"}
            processing={this.state.saving}
            onClose={() =>
              this.setState({
                showOutForDelivery: false,
                multipleRecordsToBeUpdated: false,
              })
            }
            onButtonClick={(buttonType) => {
              this.setState({ saving: true });
              if (buttonType.toLowerCase() === "ok") {
                this.onSaveOutForDelivery();
              } else {
                this.setState({
                  showOutForDelivery: false,
                  multipleRecordsToBeUpdated: false,
                  saving: false,
                  deliveryPersonKey: "",
                  deliveryPersonMobileNo: "",
                  deliveryPersonName: "",
                });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="select"
              label="Please select delivery person"
              onChange={(value) => {
                let deliveryPersonKey = value;
                let deliveryPersonName = "";
                let deliveryPersonMobileNo = "";
                if (
                  this.state.employeeList.filter((f) => f.key === value)
                    .length > 0
                ) {
                  let emp = this.state.employeeList.filter(
                    (f) => f.key === value
                  )[0];
                  deliveryPersonName =
                    emp.EmployeeFirstName + " " + emp.EmployeeLastName;
                  deliveryPersonMobileNo = emp.PhoneNo;
                }
                // console.log('deliveryPersonKey', deliveryPersonKey);
                // console.log('deliveryPersonName',deliveryPersonName);
                // console.log('deliveryPersonMobileNo',deliveryPersonMobileNo);
                this.setState({
                  deliveryPersonKey: deliveryPersonKey,
                  deliveryPersonName: deliveryPersonName,
                  deliveryPersonMobileNo: deliveryPersonMobileNo,
                });
              }}
              value={this.state.selectedEmployeeKey}
            >
              <option value="">Select Employee</option>
              <option value="new">New</option>
              {this.state.employeeList.map((e, index) => (
                <option key={index} value={e.key}>
                  {e.EmployeeFirstName + " " + e.EmployeeLastName}
                </option>
              ))}
            </Input>
            <Input
              type="text"
              label="Delivery person name"
              value={this.state.deliveryPersonName}
              onChange={(value) => this.setState({ deliveryPersonName: value })}
            />
            <Input
              type="mobileno"
              label="Delivery person mobile no"
              value={this.state.deliveryPersonMobileNo}
              onChange={(value) =>
                this.setState({ deliveryPersonMobileNo: value })
              }
            />
            {/* {!this.state.multipleRecordsToBeUpdated && <div className="form-group">
                    <input type="checkbox"
                        onChange={() => this.setState({ sendSMSForOutForDelivery: !this.state.sendSMSForOutForDelivery })}
                        checked={this.state.sendSMSForOutForDelivery}
                    />
                    <label>Send SMS</label>
                    <input type="number"
                        disabled={!this.state.sendSMSForOutForDelivery}
                        onChange={(evt => this.setState({ SMSNo: evt.target.value }))}
                        value={this.state.SMSNo}
                        className="form-control" />
                </div>} */}
          </Window>
        )}

        {this.state.showReadyForPickup && (
          <Window
            header={"Ready For Pickup"}
            width={"600px"}
            processing={this.state.saving}
            onClose={() => this.setState({ showReadyForPickup: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "ok") {
                this.onSaveReadyForPickup();
              } else {
                this.setState({ showReadyForPickup: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <div className="form-group">
              <input
                type="checkbox"
                onChange={() =>
                  this.setState({
                    sendSMSForOutForDelivery: !this.state
                      .sendSMSForOutForDelivery,
                  })
                }
                checked={this.state.sendSMSForOutForDelivery}
              />
              <label>Send SMS</label>
              <input
                type="number"
                disabled={!this.state.sendSMSForOutForDelivery}
                onChange={(evt) => this.setState({ SMSNo: evt.target.value })}
                value={this.state.SMSNo}
                className="form-control"
              />
            </div>
          </Window>
        )}

        {isMobileApp() && this.renderMobile()}

        {!isMobileApp() && this.renderDesktop()}

        {this.renderMobileRowOptions()}

        {this.renderMobileOrderDetails()}

        {this.renderMerchantInvoiceNo()}
      </React.Fragment>
    );
  }
}

export default withRouter(DeliveryList);
