import axios from 'axios'; 

export function getCancellationReasonCodes(){

    return (dispatch) => {
        return axios
        .get('https://ondcposible-gywazye4bq-uc.a.run.app/cancellationreason')
        .then(res => {
            // console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log('getCancellationReasonCodes', err);
        });
    }

}

