import React, { Component } from "react";
import Spinner from "./Common/Spinner";
import "./Common/Common.css";
import ListSearch from "./Common/ListSearch";
import Input from "./2/Controls/Input";
import Window from "./2/Controls/Window";
import DashboardCtrl from "./Reports/DashboardCtrl";
import "./2/style.css";
import { isMobileApp } from "../actions/Util";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      selectedStore: null,
      helpVideos: "",
      stores: [],
      loading: false,
      storeSearchText: "",
      title: "",
      appBannerItems: [],
      // showNewDashboard:false,
      // showOldDashboard:false,
      showDashboard: false,
      menuURL: "",
      menuURLData: null,
      expiryMessage: "",
    };
    this.onStoreSelection = this.onStoreSelection.bind(this);
    this.renderStoreSelection = this.renderStoreSelection.bind(this);
    this.onClickRedirect = this.onClickRedirect.bind(this);
    this.onShareSiteClick = this.onShareSiteClick.bind(this);
    this.getCountryConfig = this.getCountryConfig.bind(this);
    this.renderDesktopStoreSelection = this.renderDesktopStoreSelection.bind(
      this
    );
    this.renderHome = this.renderHome.bind(this);
    this.renderAccountExpiringMsg = this.renderAccountExpiringMsg.bind(this);
  }

  componentDidMount() {
    if (!this.props.user.store) {
      // console.log('get')
      this.props
        .getUserStores(
          this.props.user.user.RegistrationID,
          this.props.user.auth.uid
        )
        .then((stores) => {
          console.log("stores", stores);
          if (stores.length === 1) {
            this.onStoreSelection(stores[0]);
          } else {
            this.props.setHasAccessToMultipleStores(true);
            this.setState({ stores: stores, title: "Store Selection" });
          }
        });
    }

    let showDashboard = false;
    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role !== "Store Cashier" &&
      this.getVisibility("Reports", "read")
    ) {
      if (this.showModule("Sales Dashboard", false)) {
        showDashboard = true;
        // if (this.props.registration.registration.hasOwnProperty('NewDashboard') && this.props.registration.registration.NewDashboard) {
        //     this.setState({showNewDashboard:true, showOldDashboard:false})
        //   }
        //   else {
        //     this.setState({showNewDashboard:false, showOldDashboard:true})
        //   }
      }
    }
    if (this.props.registration.registration.EnableONDC) {
      showDashboard = false;
    }
    this.setState({ showDashboard: showDashboard });
  }

  onShareSiteClick() {
    let message = "";
    message = message + "*" + this.props.user.store.StoreName + "* \n";
    if (this.props.user.store.PhoneNo.trim() != "")
      message = message + "*" + this.props.user.store.PhoneNo + "* \n";
    if (this.props.user.store.EmailID.trim() != "")
      message = message + "*" + this.props.user.store.EmailID + "* \n";

    let url =
      "https://omni.posible.in/" +
      this.props.registration.registration.UniqueName +
      " \n";
    window.plugins.socialsharing.share(
      message,
      this.props.user.store.StoreName,
      "",
      url
    );
  }

  renderStoreSelection() {
    return (
      <React.Fragment>
        <ListSearch
          value={this.state.storeSearchText}
          onChange={(storeSearchText) => {
            this.setState({ storeSearchText: storeSearchText });
          }}
          searchPlaceHolder="Search store"
        />
        <div className="list">
          {this.state.stores
            .filter((f) => {
              let storeSearchText = this.state.storeSearchText;
              if (storeSearchText === 0) {
                return true;
              } else {
                if (
                  f.StoreCode.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.StoreName.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.Address1.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.Address2.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.City.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.State.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  ) ||
                  f.Country.toLowerCase().includes(
                    storeSearchText.toLowerCase()
                  )
                ) {
                  return true;
                }
              }
              return false;
            })
            .map((store) => (
              <div
                className="list-row seperator"
                onClick={() => {
                  this.setState({ title: "" });
                  this.onStoreSelection(store);
                }}
              >
                {/* <Avatar text={store.StoreCode}/> */}
                <div>
                  <div className="row-title">
                    {store.StoreCode + " - " + store.StoreName}
                  </div>
                  <div className="row-sub-text">{store.Address1}</div>
                  <div className="row-sub-text">{store.Address2}</div>
                  <div className="row-sub-text">{store.City}</div>
                  <div className="row-sub-text">{store.State}</div>
                  <div className="row-sub-text">{store.Country}</div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }

  async onStoreSelection(store) {
    if (!store) {
      return;
    }
    this.setState({ loading: true });
    this.setState({ value: "Populating configuration!!!" });
    // if(store.hasOwnProperty('Currency') && store.Currency ===""){
    store.Currency = "₹";
    // }
    await this.props.setStore(store);
    await this.props.getUserACL(this.props.user.auth.uid);
    await this.props.getTransactionTypes(this.props.user.user.RegistrationID);
    this.props
      .getStores(this.props.registration.registration.key, 0, 1000)
      .then((result) => {
        this.props.setStores(result.searchResult);
        // return result.searchResult;
      });

    await this.props.getProductCategories(this.props.user.user.RegistrationID);
    await this.props.getProductSubCategories(
      this.props.user.user.RegistrationID
    );
    this.props.getProductBrands(this.props.user.user.RegistrationID);
    this.props.getProductTaxes(this.props.user.user.RegistrationID);
    this.props.getPaymentTypes(this.props.user.user.RegistrationID);
    this.props.getAllCustomFieldNames(this.props.user.user.RegistrationID);
    this.props.getPrintConfigs(this.props.user.user.RegistrationID, store.key);
    this.props.getKeywords(this.props.user.user.RegistrationID);
    if (this.props.config.WhiteLabel.Code === "WRX") {
      this.props.getSalesChannel(this.props.user.user.RegistrationID);
    }
    if (this.props.config.WhiteLabel.Code !== "PINCODE") {
      this.props.getProductTags(this.props.user.user.RegistrationID);
      this.props.getActiveModifiers(this.props.user.user.RegistrationID); //pawan
      this.props.getApplicableOffers(this.props.user.user.RegistrationID);
      this.props.getActiveEmployees(store.key);
      this.props.getActiveMemberships(this.props.user.user.RegistrationID);
      this.setState({ value: "Populating Products!!!" });
      let result = await this.props.getActiveProducts(
        this.props.user.user.RegistrationID,
        "",
        store.key
      );
      let products = result.searchResult;
      let scrollID = result.scrollID;
      for (let index = 1; index < result.totalItemsCount / 2000; index++) {
        result = await this.props.getActiveProducts(
          this.props.user.user.RegistrationID,
          scrollID,
          store.key
        );
        result.searchResult.forEach((element) => {
          products.push(element);
        });
      }
      // console.log("products", products);
      // if (this.props.registration.registration.PincodeIntegration) {
      //   this.props.getPincodeGlobalCatalogMasterData("category");
      //   this.props.getPincodeGlobalCatalogMasterData("subcategory");
      //   this.props.getPincodeGlobalCatalogMasterData("brand");
      //   this.props.getPincodeGlobalCatalogMasterData("producttype");
      //   this.props.getPincodeGlobalCatalogMasterData("uom");
      //   this.props.getPincodeGlobalCatalogMasterData("model");
      //   this.props.getPincodeGlobalCatalogMasterData("packaging");
      //   this.props.getPincodeGlobalCatalogMasterData("bundling");
      // }
      await this.props.pushProductsToRedux(products);
      await this.props.poupulateSaleCategories();
      await this.props.poupulateSaleSubCategories();
    } else if (this.props.config.WhiteLabel.Code === "PINCODE") {
      this.props.getPincodeGlobalCatalogMasterData("category");
      this.props.getPincodeGlobalCatalogMasterData("subcategory");
      this.props.getPincodeGlobalCatalogMasterData("brand");
      this.props.getPincodeGlobalCatalogMasterData("producttype");
      this.props.getPincodeGlobalCatalogMasterData("uom");
      this.props.getPincodeGlobalCatalogMasterData("model");
      this.props.getPincodeGlobalCatalogMasterData("packaging");
      this.props.getPincodeGlobalCatalogMasterData("bundling");
    }

    // if (this.props.config.WhiteLabel.Code === "WRX") {
    //   console.log(
    //     "this.props.user.user.RegistrationID",
    //     this.props.user.user.RegistrationID
    //   );
    //   let salesChannel = await getSalesChannel(
    //     this.props.user.user.RegistrationID
    //   );
    //   console.log("home salesChannel", salesChannel);
    //   this.props.setSalesChannel(salesChannel);
    // }
    this.getCountryConfig();
    this.setState({ loading: false, title: "" });
    if (
      this.props.user.store &&
      this.props.user.store.hasOwnProperty("NoOfDaysToExpire") &&
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.user.Role !== "Store Cashier" &&
      this.getVisibility("Reports", "read")
    ) {
      if (this.showModule("Sales Dashboard", false)) {
        this.setState({ showDashboard: true });
      }
    }
    this.renderAccountExpiringMsg();
  }

  renderAccountExpiringMsg() {
    let message = "";
    if (this.props.user.store.NoOfDaysToExpire <= 0) {
      if (Math.abs(this.props.user.store.NoOfDaysToExpire) === 0) {
        message =
          "Your " +
          this.props.config.WhiteLabel.Title +
          " subscription has expired. Kindly renew to enjoy uninterrupted services.";
      } else {
        message =
          "Your " +
          this.props.config.WhiteLabel.Title +
          " subscription has expired " +
          Math.abs(this.props.user.store.NoOfDaysToExpire) +
          " day(s) back. Kindly renew to enjoy uninterrupted services.";
      }
    } else if (
      this.props.user.store.NoOfDaysToExpire > 0 &&
      this.props.user.store.NoOfDaysToExpire <= 30
    ) {
      if (Math.abs(this.props.user.store.NoOfDaysToExpire) === 0) {
        message =
          "Your " +
          this.props.config.WhiteLabel.Title +
          " subscription will expire today. Kindly renew to enjoy uninterrupted services.";
      } else {
        message =
          "Your " +
          this.props.config.WhiteLabel.Title +
          " subscription will expire in " +
          Math.abs(this.props.user.store.NoOfDaysToExpire) +
          " day(s). Kindly renew to enjoy uninterrupted services.";
      }
    }
    this.setState({ expiryMessage: message });
    if (message === "" && this.props.config.WhiteLabel.Code === "PINCODE") {
      //redirect to delivery list
      this.props.history.push({ pathname: "/home/sales/deliverylist" });
    }

    // {this.props.user.store.NoOfDaysToExpire <= 0 && <React.Fragment><div className="account-expiring"></div><br/></React.Fragment>}

    //         {this.props.user.store.NoOfDaysToExpire > 0 && this.props.user.store.NoOfDaysToExpire <= 30 &&<div className="account-expiring"></div>}
  }

  getCountryConfig() {
    // console.log('this.props.registration.registration',this.props.registration.registration)
    // return
    let countryCode = "IN";
    let country = "India";
    let currencySymbol = "₹";
    let currency = "INR";
    let currencyDenominations = [];
    if (
      this.props.registration.registration.Country !== "" &&
      this.props.registration.registration.Country !== "India"
    ) {
      let countries = this.props.getCountries();
      let countriesTmp = countries.filter(
        (f) =>
          f.Name.toLowerCase().trim() ===
          this.props.registration.registration.Country.toLowerCase().trim()
      );
      if (countriesTmp.length > 0) {
        countryCode = countriesTmp[0].Code;
        country = countriesTmp[0].Name;
        currency = countriesTmp[0].Currency;
        currencySymbol = countriesTmp[0].CurrencySymbol;
      }
    }
    let currencyDemoTmp = this.props.getCurrencyDenominations();
    if (currencyDemoTmp.filter((f) => f.Currency === currency).length > 0) {
      currencyDenominations = currencyDemoTmp.filter(
        (f) => f.Currency === currency
      )[0].Denominations;
    }
    this.props.updateCountryConfig({
      CountryCode: countryCode,
      Country: country,
      CurrencySymbol: currencySymbol,
      CurrencyDenominations: currencyDenominations,
    });
  }

  onClickRedirect(redirectionLink, state = null) {
    if (state)
      this.props.history.push({ pathname: redirectionLink, state: state });
    else this.props.history.push({ pathname: redirectionLink });
  }

  renderDesktopStoreSelection() {
    return (
      <React.Fragment>
        <Window
          header={"Store selection"}
          height={"500px"}
          modal={false}
          hideClose={true}
          processing={this.state.processing}
          onButtonClick={this.onValidatePinCode}
          buttons={[]}
        >
          <Input
            type="search"
            placeholder="Search store"
            value={this.state.storeSearchText}
            onChange={(value) => this.setState({ storeSearchText: value })}
          />
          <div
            // className="store-selection-list"
            style={{
              height: isMobileApp() ? window.innerHeight - 140 + "px" : "400px",
              overflowY: "auto",
            }}
          >
            {this.state.stores
              .filter((f) => {
                let storeSearchText = this.state.storeSearchText;
                if (storeSearchText === 0) {
                  return true;
                } else {
                  if (
                    f.StoreCode.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.StoreName.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.Address1.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.Address2.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.City.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.State.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    ) ||
                    f.Country.toLowerCase().includes(
                      storeSearchText.toLowerCase()
                    )
                  ) {
                    return true;
                  }
                }
                return false;
              })
              .map((store, index) => (
                <React.Fragment key={"store1" + index}>
                  <div
                    key={"store" + index}
                    className="list-row"
                    onClick={() => {
                      this.setState({ title: "" });
                      this.onStoreSelection(store);
                    }}
                  >
                    <div>
                      <div className="row-title">
                        {store.StoreCode + " - " + store.StoreName}
                      </div>
                      <div className="row-sub-text">{store.Address1}</div>
                      <div className="row-sub-text">{store.Address2}</div>
                      <div className="row-sub-text">{store.City}</div>
                      <div className="row-sub-text">{store.State}</div>
                      <div className="row-sub-text">{store.Country}</div>
                    </div>
                  </div>
                  <div key={"sep" + index} className="seperator"></div>
                </React.Fragment>
              ))}
          </div>
        </Window>
      </React.Fragment>
    );
  }

  showModule(module, forRole) {
    if (forRole) {
      if (
        this.props.user.user.hasOwnProperty("RoleKey") &&
        this.props.user.user.RoleKey !== ""
      ) {
        return this.props.getVisibility(module, "read");
      } else {
        return true;
      }
    } else {
      return this.getVisibility(module, "read");
    }
  }

  getVisibility(module, access) {
    let response = this.props.getVisibility(module, access);
    if (response === "inline") {
      return true;
    } else {
      //: "none"
      return false;
    }
  }

  renderHome() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          {this.props.user.store && (
            <div>
              {/* {this.props.user.store.NoOfDaysToExpire <= 0 && <React.Fragment><div className="account-expiring">Your " + this.props.config.WhiteLabel.Title +  " subscription has expired {Math.abs(this.props.user.store.NoOfDaysToExpire)} days back. Kindly renew to enjoy uninterrupted services.</div><br/></React.Fragment>}
        
                {this.props.user.store.NoOfDaysToExpire > 0 && this.props.user.store.NoOfDaysToExpire <= 30 &&<div className="account-expiring">Your " + this.props.config.WhiteLabel.Title +  " subscription will expire in {Math.abs(this.props.user.store.NoOfDaysToExpire)} days. Kindly renew to enjoy uninterrupted services.</div>} */}
              {this.state.expiryMessage !== "" && (
                <div className="account-expiring">
                  {this.state.expiryMessage}
                </div>
              )}
              <section className="content">
                {this.state.showDashboard && <DashboardCtrl {...this.props} />}

                {!this.state.showDashboard && (
                  <div style={{ fontSize: "20px" }}>
                    Welcome to {this.props.config.WhiteLabel.Title}
                  </div>
                )}
              </section>
            </div>
          )}

          {this.state.loading && <Spinner />}

          {!this.props.user.store &&
            this.state.stores &&
            this.state.stores.length > 0 &&
            this.renderDesktopStoreSelection()}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return <React.Fragment>{this.renderHome()}</React.Fragment>;
  }
}

export default Home;
